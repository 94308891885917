import Konva from "konva";

type Point = { x: number; y: number };

export type Degrees = number & { __unit: "degrees" };
export type Radians = number & { __unit: "radians" };

const Deg2Rad = Math.PI / 180;

export function toRadians(degrees: Degrees) {
  return degrees * Deg2Rad as Radians;
}
export function toDegrees(radians: Radians) {
  return radians / Deg2Rad as Degrees;
}

export type PointAndDirection = Point & { rotation: Degrees };

export type CanvasElementBox = {
  x: number;
  y: number;
  width: number;
  height: number;
  rotate?: Degrees;
}

// function angle(a: number, unit: "degrees" | "radians") {
//   return (unit == "degrees" ? a * Deg2Rad : a) as Radians;
// }

export function konvaTransformForElement(element: any) {
  const { x, y, scaleX = 1, scaleY = 1, rotate = 0 } = element;
  const transform = new Konva.Transform();
  transform.translate(x, y);
  transform.rotate(toRadians(rotate as Degrees));
  transform.scale(scaleX, scaleY);
  return transform;
}

export default class Transform {
  private x: number;
  private y: number;
  private scaleX: number;
  private scaleY: number;
  private rotate: Radians;
  private _matrix?: [number, number, number, number, number, number];
  private invSx: number;
  private invSy: number;

  constructor(x: number, y: number, scaleX = 1, scaleY = 1, rotate = 0 as Degrees) {
    this.x = x;
    this.y = y;
    this.scaleX = scaleX;
    this.scaleY = scaleY;
    this.rotate = toRadians(rotate);
    this.invSx = 1 / scaleX;
    this.invSy = 1 / scaleY;
  }

  static Point(tr: CanvasElementBox, p: Point, out?: Point) {
    let { x, y } = p;
    x *= tr.width;
    y *= tr.height;
    if (tr.rotate) {
      const rad = toRadians(tr.rotate as Degrees);
      const cos = Math.cos(rad), sin = Math.sin(rad);
      let x1 = x * cos - y * sin;
      let y1 = x * sin + y * cos;
      x = x1;
      y = y1;
    }
    x += tr.x;
    y += tr.y;
    if (out) {
      out.x = x;
      out.y = y;
      return out;
    }
    return { x, y };
  }

  static InvPoint(tr: CanvasElementBox, p: Point, out?: Point) {
    let { x, y } = p;
    x -= tr.x;
    y -= tr.y;
    if (tr.rotate) {
      const rad = toRadians(-tr.rotate as Degrees);
      const cos = Math.cos(rad), sin = Math.sin(rad);
      let x1 = x * cos - y * sin;
      let y1 = x * sin + y * cos;
      x = x1, y = y1;
    }
    x /= tr.width;
    y /= tr.height;
    if (out) {
      out.x = x;
      out.y = y;
      return out;
    }
    return { x, y };
  }

  get matrix() {
    return (this._matrix ??= this._calculateMatrix());
  }

  private _calculateMatrix(): [number, number, number, number, number, number] {
    let result = new Array<number>(6);
    let cos = this.rotate ? Math.cos(this.rotate) : 1;
    let sin = this.rotate ? Math.sin(this.rotate) : 0;
    result[0] = this.scaleX * cos;
    result[1] = this.scaleY * -sin;
    result[2] = this.x;
    result[3] = this.scaleX * sin;
    result[4] = this.scaleY * cos;
    result[5] = this.y;
    return result as [number, number, number, number, number, number];
  }

  public transformPoint<T extends Point>(p: T, out?: T) {
    const { x, y } = p;
    const m = this.matrix;
    const x1 = x * m[0] + y * m[1] + m[2];
    const y1 = x * m[3] + y * m[4] + m[5];
    if (out != undefined) {
      out.x = x1;
      out.y = y1;
      return out;
    }
    return { x: x1, y: y1 } as T;
  }

  public transformAngle(angle: Degrees) {
    // when angle is multiple of 90 deg, the normal is aligned to the axes,
    // so non-uniform scaling doesn't affect it. In this case just add the rotation
    const deg = toDegrees(this.rotate);
    if (angle % 90 == 0) {
      return (angle + deg) as Degrees;
    }
    // normals under non-uniform scaling need to be transformed with the inverse of the scale
    let radians = toRadians(angle);
    let x = Math.cos(radians), y = Math.sin(radians);
    x *= this.invSx;
    y *= this.invSy;
    let newRotationDegrees = toDegrees(Math.atan2(y, x) as Radians)
    return (newRotationDegrees + deg) as Degrees;
  }

  public transformPointAndDirection(p: PointAndDirection, out?: PointAndDirection) {
    let result = this.transformPoint(p, out);
    let newAngle = this.transformAngle(p.rotation);
    result.rotation = newAngle;
    return result;
  }
}