import { IGanttSplitColumnController } from "elements/gantt/controllers/split-column-controller";
import BoundingBox from "frontend/geometry/bounding-box";
import { Floater } from "frontend/ui-components/floaters/floater";
import { Html } from "react-konva-utils";
import styles from "./split-column-toolbar.module.css";
import { TrashIcon } from "frontend/icons/trash-icon";
import { GanttAddGroupIcon } from "elements/gantt/icons/gantt-add-group-icon";
import { GanttAddSubGroupIcon } from "elements/gantt/icons/gantt-add-sub-group-icon";
import cn from "classnames";
import { Tooltip } from "frontend/ui-components/floaters/floaters";

type SplitColumnToolbarProps = {
  cellBbox: BoundingBox;
  controller: IGanttSplitColumnController;
};

const Separator = () => <div className={styles.seperator} />;

export default function SplitColumnToolbar({ cellBbox, controller }: SplitColumnToolbarProps) {
  return (
    <Html transform={false}>
      <Floater relativeTo={cellBbox} arrowSize={0} margin={10} boundary="no-controls-area" className={styles.wrapper}>
        <div className={styles.toolbarActions}>
          <Tooltip label={"Add Row"} side={"top"} tooltipClassName={styles.tooltip}>
            <div className={cn(styles.remove, styles.action)} onClick={() => controller.addRowAtBottom()}>
              <GanttAddGroupIcon />
            </div>
          </Tooltip>

          <Separator />

          <Tooltip label={"Add Column"} side={"top"} tooltipClassName={styles.tooltip}>
            <div className={cn(styles.remove, styles.action)} onClick={() => controller.addSubGroup()}>
              <GanttAddSubGroupIcon />
            </div>
          </Tooltip>

          {controller.canDelete() && (
            <>
              <Separator />
              <div className={cn(styles.remove, styles.action)} onClick={() => controller.deleteColumn()}>
                <TrashIcon size={20} color="#FEFEFE" />
              </div>
            </>
          )}
        </div>
      </Floater>
    </Html>
  );
}
