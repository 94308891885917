import { Point } from "frontend/utils/math-utils";
import { FooterButtonGroup, FooterButton } from "./footer";

export function ZoomControls({
  position,
  scale,
  onZoom,
  viewportWidth,
  viewportHeight,
  hideTooltips
}: {
  position: Point;
  scale: number;
  onZoom: (scale: number, position: Point) => void;
  viewportWidth?: number
  viewportHeight?: number
  hideTooltips?: boolean
}) {
  const stops = [0.01, 0.05, 0.1, 0.25, 0.5, 0.75, 0.9, 1, 1.25, 1.5, 1.75];

  function stop(scale: number, stops: number[], comparator: (a: number, b: number) => boolean) {
    const index = stops.findIndex((el) => comparator(scale, el));
    return index > -1 ? stops[index] : scale;
  }

  function nextStop(scale: number) {
    return stop(scale, stops, (a, b) => a < b);
  }
  function prevStop(scale: number) {
    return stop(scale, stops.slice().reverse(), (a, b) => a > b);
  }

  function doScale(scale: number, newScale: number) {
    const centerX = (0.5 * (viewportWidth || window.innerWidth) - position.x) / scale;
    const centerY = (0.5 * (viewportHeight || window.innerHeight) - position.y) / scale;
    const offsetX = centerX * (newScale - scale);
    const offsetY = centerY * (newScale - scale);
    const newPosition = {
      x: position.x - offsetX,
      y: position.y - offsetY,
    };
    onZoom(newScale, newPosition);
  }

  return (
    <FooterButtonGroup>
      <FooterButton tooltip={!hideTooltips ? "Zoom out" : undefined} onClick={() => doScale(scale, prevStop(scale))}>
        <svg width="15" height="2" viewBox="0 0 20 2" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 1H18.3333" stroke="#0B2642" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </FooterButton>
      <div style={{ width: 55, fontSize: 14, lineHeight: "1", display: "grid", placeItems: "center" }}>
        {Math.round(scale * 100)}%
      </div>
      <FooterButton tooltip={!hideTooltips ? "Zoom in" : undefined} onClick={() => doScale(scale, nextStop(scale))}>
        <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.57422 1.33301V18.4816"
            stroke="#0B2642"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path d="M1 9.90723H18.1486" stroke="#0B2642" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </FooterButton>
    </FooterButtonGroup>
  );
}
