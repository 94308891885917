import { StickyNoteMarginX, StickyNoteMarginY } from "frontend/canvas-designer-new/elements/sticky-note-element";
import { lerp, lerp2 } from "frontend/utils/math-utils";
import type { TypeCanvasElement, TypeShape } from "shared/consts";
import consts from "shared/consts";
import { cossin, diamondPoints, distance, hexagonPoints, Transform, trianglePoints } from "./utils";
import type { CanvasElement, Point } from "shared/datamodel/schemas";
import type { Shape } from "shared/datamodel/schemas/shape";
import AllShapes from "frontend/data/shapes/shapes-visuals";
import BoundingBox from "./bounding-box";
import { parsePath } from "./svg-path-utils";
import { CubicCurve, QuadraticCurve } from "frontend/utils/connector-utils";
import { rotated90 } from "frontend/utils/point-utils";
import { getElementGraphicsProvider } from "elements/index";

/**
 * This file contains the pointAt function, which is used to calculate the position of a point on a shape.
 * It's used by the connector to calculate the points where it connects to the elements.
 * pointAt :: (TypeCanvasElement, CanvasElement) -> (number) -> Point
 *
 * number: t, in 0..1 range, where t=0 is top-center of the shape, and it goes clockwise
 * t=0.5 is the bottom center, t=0.25 is the right-center, t=0.75 is the left-center (this is for rectangles, other
 * shapes can define it differently, but t=0 is always top-center and then clockwise)
 */

const TAU = Math.PI * 2;

type PointAtBuilder = (element: any) => (t: number) => Point;

// get the fractional part of t (also known as "t mod 1")
const fractional = (t: number) => t - Math.floor(t);

// separate t into integer and fraction parts
// name is consistent with stdlib of c++, opengl, and others
const modf = (t: number) => {
  const integer = Math.floor(t);
  t -= integer;
  return [integer, t];
};

// note: we're converting t from 0..1 to 0..2pi, and offseting it by -pi/2, so that t=0 is top-center of the circle
const pointOnCircle: PointAtBuilder =
  ({ radius = 1 }) =>
  (t) =>
    cossin(t * TAU - Math.PI / 2, radius);

// note: we're offseting t=0 from top-center to top-left of rectangle.
const pointOnRect: PointAtBuilder =
  ({ width = 1, height = 1 }) =>
  (t) => {
    t = (t + 0.125) % 1;
    let [side, fraction] = modf(t * 4);
    let x = 0,
      y = 0;
    switch (side) {
      case 0:
        (x = lerp(0, width, fraction)), (y = 0);
        break;
      case 2:
        (x = lerp(width, 0, fraction)), (y = height);
        break;
      case 1:
        (x = width), (y = lerp(0, height, fraction));
        break;
      case 3:
        (x = 0), (y = lerp(height, 0, fraction));
        break;
    }
    return { x, y };
  };

// note: points is array of type [x1,y1,x2,y2,x3,y3,...]
const pointOnRegularPolygon =
  (points: number[]): PointAtBuilder =>
  ({ radius = 1 }) =>
  (t) => {
    const numPoints = points.length / 2;
    let [i, f] = modf(t * numPoints);
    const x1 = points[i * 2],
      y1 = points[i * 2 + 1];
    const x2 = points[((i + 1) % numPoints) * 2],
      y2 = points[((i + 1) % numPoints) * 2 + 1];
    return { x: lerp(x1, x2, f) * radius, y: lerp(y1, y2, f) * radius };
  };

/**
 * Get the width,height of element as it's drawn on screen, without scaling.
 * Only used for elements that are connectable, so most elements are not handled here.
 * @param type element type
 * @param element element data
 * @returns width and height of the element as drawn on screen
 */
const getWidthHeight = (type: TypeCanvasElement, element: any): { width: number; height: number } => {
  switch (type) {
    // some elements have width, height and we don't need to change anything
    case consts.CANVAS_ELEMENTS.SHAPE:
      if (element.type === consts.CANVAS_ELEMENTS.SHAPE) {
        return { width: 200, height: 200 };
      } else {
        return element;
      }

    case consts.CANVAS_ELEMENTS.TEXT_BLOCK:
    case consts.CANVAS_ELEMENTS.FILE:
    case consts.CANVAS_ELEMENTS.FRAME:
      return element;

    // some elements need some work
    case consts.CANVAS_ELEMENTS.STICKY_NOTE:
      return { width: element.width + StickyNoteMarginX, height: element.height + StickyNoteMarginY };

    case consts.CANVAS_ELEMENTS.TASK_CARD:
      // TODO task card's exact size can be had from the konva.node if it's not in the element.
      return { width: consts.DEFAULTS.CARD_WIDTH, height: element.height ?? consts.DEFAULTS.CARD_HEIGHT };

    case consts.CANVAS_ELEMENTS.DRAWING:
      return BoundingBox.fromCoords(element.points);

    // with these I don't bother for now, because they're not connectable
    case consts.CANVAS_ELEMENTS.INTEGRATION:
    case consts.CANVAS_ELEMENTS.LIVE_INTEGRATION:
    case consts.CANVAS_ELEMENTS.CARD_STACK:
    case consts.CANVAS_ELEMENTS.CONNECTOR:
    case consts.CANVAS_ELEMENTS.COMMENT:
    case consts.CANVAS_ELEMENTS.MINDMAP:
    case consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART:
    case consts.CANVAS_ELEMENTS.ORG_CHART:
    case consts.CANVAS_ELEMENTS.ORG_CHART_NODE:
    case consts.CANVAS_ELEMENTS.TIMELINE:
    case consts.CANVAS_ELEMENTS.TABLE:
    case consts.CANVAS_ELEMENTS.TABLE_CELL:
      return { width: 0, height: 0 };
    default:
      console.warn("Unknown element type", type, element);
      return { width: 0, height: 0 };
  }
};

const pointAtXShape: PointAtBuilder = (element) => {
  const xshape = element as Shape;

  if (xshape.subtype && xshape.subtype in AllShapes) {
    // TODO: can cache be shared with closestT ?
    let parsedOutlineOfShape: ReturnType<typeof parsePath> | null = null;
    parsedOutlineOfShape = parsePath(AllShapes[element.subtype as keyof typeof AllShapes].outline);

    // check if parsePath failed
    if (!parsedOutlineOfShape) return () => ({ x: 0, y: 0 });

    const bbox = AllShapes[element.subtype as keyof typeof AllShapes].viewbox;
    const ofsx = bbox[0] + bbox[2] / 2,
      ofsy = bbox[1] + bbox[3] / 2;

    // TODO: do I really need to offset the points of the paths here?
    // why not offset the result of the pointAt function?
    const data = parsedOutlineOfShape.map(({ cmd, points }) => {
      const N = points.length;
      let points2 = new Array<number>(N);
      for (let i = 0; i < N; i += 2) {
        points2[i] = points[i] - ofsx;
        points2[i + 1] = points[i + 1] - ofsy;
      }
      return { cmd, points: points2 };
    });

    let lengths = data.map(({ cmd, points }) => {
      if (cmd == "L") return distance(points[0], points[1], points[2], points[3]);
      if (cmd == "C")
        return new CubicCurve(
          points[0],
          points[1],
          points[2],
          points[3],
          points[4],
          points[5],
          points[6],
          points[7]
        ).length();
      if (cmd == "Q")
        return new QuadraticCurve(points[0], points[1], points[2], points[3], points[4], points[5]).length();
      return 0;
    });
    let totalLength = lengths.reduce((a, b) => a + b, 0);

    /*********************************/
    type FnToFindBest = (p: Point, best: null | any) => any;

    let closestForSegment: Array<FnToFindBest> = new Array(data.length);

    function closestToSegment(x: number, y: number, x1: number, y1: number, x2: number, y2: number) {
      function closestTToSegment(x: number, y: number, x1: number, y1: number, x2: number, y2: number) {
        const segmentSqrLen = (x2 - x1) ** 2 + (y2 - y1) ** 2;
        if (segmentSqrLen == 0) return 0;
        var t = ((x - x1) * (x2 - x1) + (y - y1) * (y2 - y1)) / segmentSqrLen;
        t = Math.max(0, Math.min(1, t));
        return t;
      }

      const t = closestTToSegment(x, y, x1, y1, x2, y2);
      return {
        t,
        x: x1 + t * (x2 - x1),
        y: y1 + t * (y2 - y1),
      };
    }

    for (let index = 0; index < data.length; index++) {
      // create a function to evaluate the closest point on the segment
      let fn: FnToFindBest;
      const { cmd, points } = data[index];
      switch (cmd) {
        case "L":
          fn = (m: Point, best: null | any) => {
            const p = closestToSegment(m.x, m.y, points[0], points[1], points[2], points[3]);
            const d = distance(m.x, m.y, p.x, p.y);
            if (best == null || best.distance > d) {
              // normal for line with slope dy/dx, has slope -dx/dy
              let normal;
              {
                const x1 = points[0];
                const y1 = points[1];
                const x2 = points[2];
                const y2 = points[3];
                const dx = x2 - x1;
                const dy = y2 - y1;
                normal = { x: dy, y: -dx };
              }
              best = { element, index, distance: d, point: p, normal };
            }
            return best;
          };
          break;
        case "C":
        case "Q":
          let b =
            cmd == "C"
              ? new CubicCurve(points[0], points[1], points[2], points[3], points[4], points[5], points[6], points[7])
              : new QuadraticCurve(points[0], points[1], points[2], points[3], points[4], points[5]);
          let curvePoints = Array.from({ length: 101 }, (_, i) => b.point(i / 100));
          let closestPoint = (m: Point) => {
            let d = Number.MAX_SAFE_INTEGER;
            let index = 0;
            for (let i = 0; i < curvePoints.length - 1; i++) {
              let dnow = distance(m.x, m.y, curvePoints[i][0], curvePoints[i][1]);
              if (dnow < d) {
                d = dnow;
                index = i;
              }
            }
            return { d, t: index / 101, index, x: curvePoints[index][0], y: curvePoints[index][1] };
          };
          fn = (m, best) => {
            const closest = closestPoint(m);
            if (best == null || best.distance > (closest.d ?? Infinity)) {
              const point = { x: closest.x, y: closest.y, t: closest.t! };
              let tangent = { x: 0, y: 0 };
              let dt = 0;
              if (index > 0) {
                tangent.x += curvePoints[index][0] - curvePoints[index - 1][0];
                tangent.y += curvePoints[index][1] - curvePoints[index - 1][1];
                dt += 1 / curvePoints.length;
              }
              if (index < curvePoints.length) {
                tangent.x += curvePoints[index + 1][0] - curvePoints[index][0];
                tangent.y += curvePoints[index + 1][1] - curvePoints[index][1];
                dt += 1 / curvePoints.length;
              }
              const normal = rotated90(tangent);
              // normal.x = -normal.x;
              // normal.y = -normal.y;
              best = { element, index, distance: closest.d!, point, normal };
            }
            return best;
          };
          break;
        default:
          const unhandled_case: never = cmd;
          throw new Error(unhandled_case);
      }
      closestForSegment[index] = fn;
    }
    /*********************************/

    return (t: number) => {
      let t0 = closestForSegment.reduce<any>((acc, fn) => fn({ x: 0, y: -9999 }, acc), null);
      let l0 = 0;
      for (let index = 0; index < t0.index; index++) l0 += lengths[index];
      l0 += t0.point.t * lengths[t0.index];
      l0 /= totalLength;
      t += l0;
      t %= 1;

      let l = t * totalLength;
      let segmentIndex = 0,
        segmentFraction = 0;
      while (segmentIndex < lengths.length && l >= lengths[segmentIndex]) {
        l -= lengths[segmentIndex];
        segmentIndex++;
      }
      if (segmentIndex >= lengths.length) {
        segmentIndex = lengths.length - 1;
        segmentFraction = 1;
      } else {
        segmentFraction = l / lengths[segmentIndex];
      }

      const { cmd: code, points } = data[segmentIndex];
      let point: Point;
      if (code == "L") {
        let [x0, y0, x1, y1] = points;
        const [x, y] = lerp2(x0, y0, x1, y1, segmentFraction);
        point = { x, y };
      } else if (code == "C") {
        let b = new CubicCurve(points[0], points[1], points[2], points[3], points[4], points[5], points[6], points[7]);
        let point1 = b.point(segmentFraction);
        point = { x: point1[0], y: point1[1] };
      } else if (code == "Q") {
        let b = new QuadraticCurve(points[0], points[1], points[2], points[3], points[4], points[5]);
        let point1 = b.point(segmentFraction);
        point = { x: point1[0], y: point1[1] };
      } else {
        console.warn("unknown segment code", code);
        point = { x: 0, y: 0 };
      }
      return point;
    };
  } else {
    console.error("unknown shape subtype", xshape.subtype, xshape);
    return (t: number) => {
      return { x: 0, y: 0 };
    };
  }
};

/**
 * Mapping from shape type to function that calculates the point at t.
 */
const pointAtByShapeType: Record<TypeShape, PointAtBuilder> = {
  [consts.SHAPES.RECT]: pointOnRect,
  [consts.SHAPES.RECT_ROUNDED]: pointOnRect,
  [consts.SHAPES.CIRCLE]: pointOnCircle,
  [consts.SHAPES.DIAMOND]: pointOnRegularPolygon(diamondPoints),
  [consts.SHAPES.TRIANGLE]: pointOnRegularPolygon(trianglePoints),
  [consts.SHAPES.HEXAGON]: pointOnRegularPolygon(hexagonPoints),
};

function fnToCalculatePointAt(type: TypeCanvasElement, element: CanvasElement) {
  const provider = getElementGraphicsProvider(type);
  if (provider) {
    return provider.calculatePointAt(element);
  }
  switch (type) {
    case consts.CANVAS_ELEMENTS.SHAPE: {
      if ((element as Shape).type == consts.CANVAS_ELEMENTS.SHAPE) {
        return pointAtXShape(element);
      } else {
        const legacyShape = (element as Shape).type as TypeShape;
        if (legacyShape in pointAtByShapeType) {
          return pointAtByShapeType[legacyShape](element);
        }
        console.error("Unknown shape type", legacyShape, element);
        return () => ({ x: 0, y: 0 });
      }
    }

    case consts.CANVAS_ELEMENTS.TEXT_BLOCK:
    case consts.CANVAS_ELEMENTS.STICKY_NOTE:
    case consts.CANVAS_ELEMENTS.FILE:
    case consts.CANVAS_ELEMENTS.TASK_CARD:
    case consts.CANVAS_ELEMENTS.FRAME:
      return pointOnRect(getWidthHeight(type, element));
    case consts.CANVAS_ELEMENTS.DRAWING: //TODO ofirc

    // non-connectable elements
    case consts.CANVAS_ELEMENTS.INTEGRATION:
    case consts.CANVAS_ELEMENTS.LIVE_INTEGRATION:
    case consts.CANVAS_ELEMENTS.CARD_STACK:
    case consts.CANVAS_ELEMENTS.CONNECTOR:
    case consts.CANVAS_ELEMENTS.COMMENT:
    case consts.CANVAS_ELEMENTS.MINDMAP:
    case consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART:
    case consts.CANVAS_ELEMENTS.ORG_CHART:
    case consts.CANVAS_ELEMENTS.ORG_CHART_NODE:
    case consts.CANVAS_ELEMENTS.TIMELINE:
    case consts.CANVAS_ELEMENTS.TABLE:
    case consts.CANVAS_ELEMENTS.TABLE_CELL:
      return () => ({ x: 0, y: 0 });
    default:
      console.warn("Unknown element type", type, element);
      return () => ({ x: 0, y: 0 });
  }
}

const pointAt = (type: TypeCanvasElement, element: CanvasElement) => {
  const tr = Transform.of(element).pointTransformer;
  const calcPointAt = fnToCalculatePointAt(type, element);
  return (t: number) => tr(calcPointAt(fractional(t))); // compose(tr, calcPointAt, fractional)
};

export default pointAt;
