import * as utils from "utils/connector-utils";
import { computeArrowPoints } from "utils/connector-utils";
import { IRect } from "utils/math-utils";
import { parseStrokeWidth } from "shared/util/utils";
import React, { forwardRef, Ref, useMemo } from "react";
import * as PointUtils from "utils/point-utils";
import { Point } from "shared/datamodel/schemas";
import { Group, Line, Shape } from "react-konva";
import { calcDashProperties } from "utils/node-utils";
import type { Degrees } from "utils/transform";
import Konva from "konva";
import { clipHole, ConnectorEndpoint, fixMode } from "./connector-component-utils";

export default forwardRef(function ConnectorLine(
  {
    p1,
    p2,
    element,
    data,
    clipRect,
    id,
  }: {
    p1: ConnectorEndpoint;
    p2: ConnectorEndpoint;
    data: utils.SimpleConnectorData;
    clipRect?: IRect;
    id?: string;
    element: {
      scaleX?: number;
      scaleY?: number;
      rotate?: number;
      stroke: string;
      strokeWidth: number | string;
      pointerStyles: ("arrow" | "none")[];
      anchorsMode?: null | (null | string)[];
      anchorIndexes: number[];
      activeAnchorIndex?: number | null;
      dash?: number;
      lineType: "line" | "curve" | "elbow";
      groupId?: string;
      hitStrokeWidth?: number;
    };
  },
  ref: Ref<Konva.Shape>
) {
  const scaleX = element.scaleX ?? 1;
  const scaleY = element.scaleY ?? 1;
  const strokeWidth = parseStrokeWidth(element.strokeWidth);
  const strokeColor = element.stroke;
  const pointerStyles = element.pointerStyles;
  const selfRect = useMemo(() => data.getSelfRect(), [data]);
  // TODO: visual style (width,color,dash,arrows) should be extracted by caller and passed as props
  // so ghost element can supply them
  const distBetweenArrowHeads = PointUtils.distance(p1, p2);
  const maxArrowHeadSize = distBetweenArrowHeads / 2.5;

  function scale(p: Point) {
    p.x *= scaleX;
    p.y *= scaleY;
    return p;
  }
  return (
    <>
      <Shape
        id={id}
        ref={ref}
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeEnabled={true}
        hitStrokeWidth={element.hitStrokeWidth ?? 0}
        activeAnchorIndex={element.activeAnchorIndex}
        lineJoin="round"
        points={[p1, p2]} // TODO: needed for elbow code, which should be depracted
        connectorData={data}
        {...calcDashProperties(strokeWidth, element.dash)}
        sceneFunc={(context, shape) => {
          // Clip the area where the text label is
          if (clipRect) {
            if ((context as any).isPdfContext) {
              (context as any).cliphole(clipRect, element);
            } else {
              clipHole(context, clipRect, scaleX, scaleY, element.rotate as Degrees);
            }
          }

          // Elbow lines are drawn here, because they stil use the old algorithm
          if (element.lineType == "elbow") {
            let renderer = utils.getElBowForBackCompat({
              start: p1,
              end: p2,
              ...element,
            });
            const isConnected = !!element.anchorsMode && element.anchorsMode.every((x: any) => fixMode(x) != "n/a");

            // first render to an internal object to record the commands so we can calculate stuff.
            let pathSegments = new utils.RecordCanvasCmds();
            renderer(pathSegments, shape, [p1, p2], isConnected, element.anchorsMode! as any);
            // now render and stroke without the scale
            renderer(context, shape, [p1, p2], isConnected, element.anchorsMode! as any);
            context.scale(1 / scaleX, 1 / scaleY);
            context.strokeShape(shape);

            // === Drawing arrows ===
            if (pointerStyles && (pointerStyles[0] != "none" || pointerStyles[1] != "none")) {
              context.beginPath();
              if (pointerStyles[0] === "arrow") {
                const position = scale(utils.lastPoint(pathSegments.segments));
                const dir = utils.endTangent(pathSegments.segments);
                const [p1, p2] = utils.computeArrowPoints(position, dir, strokeWidth, maxArrowHeadSize);
                context.moveTo(p1.x, p1.y);
                context.lineTo(position.x, position.y);
                context.lineTo(p2.x, p2.y);
              }
              if (pointerStyles[1] === "arrow") {
                const position = scale(utils.firstPoint(pathSegments.segments));
                const dir = utils.startTangent(pathSegments.segments);
                const [p1, p2] = utils.computeArrowPoints(position, dir, strokeWidth, maxArrowHeadSize);
                context.moveTo(p1.x, p1.y);
                context.lineTo(position.x, position.y);
                context.lineTo(p2.x, p2.y);
              }
              let arrowShape: Konva.Shape = new Konva.Shape({
                dash: [],
                dashEnabled: true,
                lineCap: "round",
                lineJoin: "round",
                stroke: element.stroke,
                strokeWidth: strokeWidth,
                strokeEnabled: true,
              });
              context.strokeShape(arrowShape);
            }

            let box = utils.calcBbox(pathSegments.segments);

            let x = box.x;
            let y = box.y;
            let width = box.width;
            let height = box.height;
            shape.getSelfRect = () => ({ x, y, width, height });
            box = box.addPadding(strokeWidth / 2);
            shape.attrs.bbox = box.asRect();
          } else {
            // Line and bezier curves are drawn here using the new class
            context.beginPath();
            data.drawOnCanvas(context);
            // remove scale so stroke isn't scaled
            context.scale(1 / scaleX, 1 / scaleY);
            context.strokeShape(shape);
            // make sure selfRect is correct for this shape
            shape.getSelfRect = () => selfRect;
          }
        }}
      />
      {pointerStyles && element.lineType != "elbow" && (
        // We undo the scale because for the purposes of computing arrows they interfere
        // The arrow size should be the same regardless of connector scale,
        // and arrow
        <Group scaleX={1 / scaleX} scaleY={1 / scaleY}>
          <ArrowHead
            position={scale(data.finalPoint())}
            comingFrom={scale(data.preFinalPoint())}
            arrowHead={pointerStyles[0]}
            strokeWidth={strokeWidth}
            strokeColor={strokeColor}
            allowedSize={maxArrowHeadSize}
          />
          <ArrowHead
            position={scale(data.firstPoint())}
            comingFrom={scale(data.secondPoint())}
            arrowHead={pointerStyles[1]}
            strokeWidth={strokeWidth}
            strokeColor={strokeColor}
            allowedSize={maxArrowHeadSize}
          />
        </Group>
      )}
    </>
  );
});

function ArrowHead({
  arrowHead,
  position,
  comingFrom,
  strokeWidth,
  strokeColor,
  allowedSize,
}: {
  arrowHead: string;
  position: Point;
  comingFrom: Point;
  strokeWidth: number;
  strokeColor: string;
  allowedSize: number;
}) {
  if (arrowHead == "none") return null;
  if (PointUtils.distance(comingFrom, position) < 1) return null;
  let direction = PointUtils.vectorFromTo(comingFrom, position);
  PointUtils.normalize(direction, direction);
  const [p1, p2] = computeArrowPoints(position, direction, strokeWidth, allowedSize);
  return (
    <Line
      points={[p1.x, p1.y, position.x, position.y, p2.x, p2.y]}
      lineCap="round"
      lineJoin="round"
      listening={false}
      strokeWidth={strokeWidth}
      stroke={strokeColor}
    />
  );
}
