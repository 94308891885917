import { useEffect, useRef, useState } from "react";
import { useHover } from "usehooks-ts";
import { useOutsideRefs } from "frontend/utils/click-outside-handler";
import { Tooltip } from "frontend/ui-components/floaters/tooltip";
import { Floater, FloaterProps } from "frontend/ui-components/floaters/floater";
import style from "./toolbar-buttons.module.css";
import React from "react";
import { mergeRefs } from "react-merge-refs";
import ReactDOM from "react-dom";
import { PortalID, usePortal } from "frontend/modal/usePortal";
import { useUnmount } from "react-use";

export function ButtonGroup({ children }: React.PropsWithChildren<{}>) {
  return <div className={style.buttonGroup}>{children}</div>;
}

interface ButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  tooltip?: string;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { children, ...rest } = props;
  const { tooltip, ...buttonProps } = rest;
  const myref = useRef<any>(null);
  const hover = useHover(myref);
  const mergedRef = mergeRefs([ref, myref]); // can I memoize this?
  return (
    <>
      <button ref={mergedRef} className={style.button} {...buttonProps}>
        {props.children}
      </button>
      {hover && props.tooltip && <Tooltip label={props.tooltip} relativeTo={myref}></Tooltip>}
    </>
  );
});

interface DropdownUniqueProps extends Omit<FloaterProps, "relativeTo"> {
  renderPopup: (closePopup: () => void) => React.ReactNode;
  tooltip?: string;
  hideArrow?: boolean;
}

type DropdownButtonProps = React.PropsWithChildren<DropdownUniqueProps & React.ComponentPropsWithoutRef<"div">>;

export const DropdownButton = (props: DropdownButtonProps) => {
  let { children, ...myProps } = props;
  let {
    renderPopup,
    className,
    side,
    boundary = "no-controls-area",
    arrowSize = 12,
    margin,
    tooltip,
    hideArrow,
    ...rest
  } = myProps;
  const [popupOpen, setPopupOpen] = useState(false);
  const ref = useRef<any>(null);
  const popupRef = useRef<any>(null);
  const hover = useHover(ref);

  const floaterLayer = document.getElementById(PortalID.Floaters)!;

  function togglePopup(e: any) {
    let pressInside = false;
    let target = e.target;
    while (target) {
      // TODO: this is a hack to prevent the popup from closing when the color picker is clicked
      if (typeof target.className === "string" && target.className.includes("custom-colors-picker")) {
        break;
      }
      if (target === ref.current) {
        pressInside = true;
        break;
      }
      target = target.parentNode;
    }
    pressInside && setPopupOpen((o) => !o);
  }
  function closePopup() {
    setPopupOpen(false);
  }
  useOutsideRefs([ref, popupRef], closePopup);

  const renderedPopup = renderPopup(closePopup);

  return (
    <>
      <div ref={ref} onClick={togglePopup} className={hideArrow ? style.button : style.dropdownButton} {...rest}>
        {children}
      </div>
      {hover && !popupOpen && !!tooltip && <Tooltip label={tooltip} relativeTo={ref}></Tooltip>}
      {popupOpen &&
        renderedPopup !== null &&
        <Floater
          ref={popupRef}
          relativeTo={ref}
          className={className}
          side={side}
          arrowSize={arrowSize}
          margin={margin}
          boundary={boundary}
        >
          {renderedPopup}
        </Floater>
      }
    </>
  );
};
