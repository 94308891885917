import Konva from "konva";
import { CursorType } from "frontend/canvas-designer-new/cursor-type";
import type { IRect, Point, PointInViewport } from "./math-utils";
import * as MathUtils from "./math-utils";
import { lerp } from "./math-utils";
import BoundingBox from "frontend/geometry/bounding-box";
import { NewElementData } from "shared/datamodel/canvas-element";
import { getOpacity } from "./color-utils";
import consts, { TypeCanvasElement } from "shared/consts";
import * as bezierIntersect from "bezier-intersect";
import { distance as distanceP, normalized, rotated90, vectorFromTo } from "./point-utils";
import { NewConnectorData } from "frontend/canvas-designer-new/utility-elements/connector-builder";
import type {
  CanvasElement,
  Connector,
  Drawing,
  File as FileSchema,
  Frame,
  IntegrationItem,
  Shape,
  StickyNote,
  TaskCard,
  TypeTableElement,
} from "shared/datamodel/schemas";
import { parseStrokeWidth } from "shared/util/utils";
import Transform, { CanvasElementBox, Degrees, PointAndDirection } from "./transform";
import { getTransformParams } from "frontend/utils/node-utils";
import { SyncService } from "frontend/services/syncService";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { distance, length } from "../geometry/utils";
import { segmentIntersectSegmentFast as intersectSegments } from "../geometry/intersections";
import AllShapes from "frontend/data/shapes/shapes-visuals";
import { getElementGraphicsProvider } from "elements/index";
import { isMac } from "./keyboard-shortcuts";

type ShapeAnchorPointsList = ReturnType<typeof findNearbyAnchorPoints>;
type AnchorsModeList = [string | null, string | null];
type Point2 = readonly [number, number];
type ReturnPoint = (t: number) => Point2;

export const DistanceToShowAnchors_px = 20;
export const DistanceToSnapToAnchorFromOutside_px = 40;
export const DistanceToSnapToAnchorFromInside_px = 10;
export const ConnectorTransformPointRadius = 6; // in pixels - actual size = 2*(radius+stroke)
export const ConnectorSnapPointRadius = 8; // pixels

export function shouldDisableConnectorSnapping(e: MouseEvent | KeyboardEvent) {
  return (isMac() ? e.metaKey : e.ctrlKey) || e.shiftKey;
}

function detachConnector(
  syncService: SyncService<RW>,
  id: string,
  elementType: string,
  connectorId: string,
  attachedConnector: any,
  anchorIndex: number
) {
  syncService.mutate.removeAttachedConnector({
    id,
    elementType,
    connectorId,
    anchorIndex,
  });
  return () => attachConnector(syncService, id, elementType, connectorId, attachedConnector, anchorIndex);
}

export function attachConnector(
  syncService: SyncService<RW>,
  id: string,
  elementType: any,
  connectorId: string,
  attachedConnector: any,
  anchorIndex: number
) {
  syncService.mutate.addAttachedConnector({
    id,
    elementType,
    connectorId,
    attachedConnector,
  });
  return () => detachConnector(syncService, id, elementType, connectorId, attachedConnector, anchorIndex);
}

export function areNodesAttached(node1: Konva.Node, node2: Konva.Node, filterFn?: (id: string) => boolean) {
  let commonConnections = Object.keys(node1.attrs.attachedConnectors ?? {}).filter((key) =>
    node2.attrs.attachedConnectors?.hasOwnProperty(key)
  );
  if (filterFn) {
    commonConnections = commonConnections.filter(filterFn);
  }
  return commonConnections.length > 0;
}

function getZIndex(node: Konva.Node) {
  return node.attrs.element.zIndexLastChangeTime ?? node.attrs.element.lastModifiedTimestamp;
}

function getSize(rect: IRect) {
  return rect.width * rect.height;
}

function isFilled(node: Konva.Node) {
  let color = node.attrs.type == consts.CANVAS_ELEMENTS.DRAWING ? node.attrs.element.stroke : node.attrs.element.fill;
  const alpha = color ? getOpacity(color) : 1;
  return alpha > 0.001;
}

// TODO: remove once I'm sure the new functions work
function getAnchorPointsData(node: Konva.Node) {
  const anchorPoints = Object.entries(getAnchorPointsFromNode(node));
  const stageTransform = node.getStage()!.getTransform();
  return anchorPoints.map(([side, position]) => {
    const inViewport = stageTransform.point(position);
    return {
      position: {
        x: inViewport.x,
        y: inViewport.y,
        rotation: position.rotation,
        onBoundingBox: position.onBoundingBox,
      },
      side,
      node,
      anchorPoints,
    };
  });
}

// gets distance of point to the outline of rect
// TODO: remove once I'm sure the new functions work
export function distPointToRect(samplePosition: Point, rect: IRect) {
  const halfWidth = rect.width / 2,
    halfHeight = rect.height / 2,
    // calculate the center of the rect
    centerRectX = rect.x + halfWidth,
    centerRectY = rect.y + halfHeight,
    // change the coordinates of point, as if rect is centered on (0,0)
    posX = samplePosition.x - centerRectX,
    posY = samplePosition.y - centerRectY,
    dx = Math.abs(posX) - halfWidth,
    dy = Math.abs(posY) - halfHeight;

  const outsideDistance = length(Math.max(dx, 0), Math.max(dy, 0));
  const insideDistance = Math.min(Math.max(dx, dy), 0);

  return outsideDistance + insideDistance;
}

// TODO: remove once I'm sure the new functions work
export function findNearbyAnchorPoints(pos: PointInViewport, elementsLayer: Konva.Layer) {
  const margin = DistanceToShowAnchors_px / elementsLayer.scaleX(); // 20 canvas units (converted to viewport units)
  const triggerArea = {
    x: pos.x - margin,
    y: pos.y - margin,
    width: margin * 2,
    height: margin * 2,
  };

  let candidates = elementsLayer
    .find((node: any) => node.attrs.isConnectable && Konva.Util.haveIntersection(node.getClientRect(), triggerArea))
    .toArray();

  if (candidates.length == 0) return [];

  // we're going to calculate some data we'll be using a lot up ahead.
  const nodesData = new Map(
    candidates.map((node) => {
      const rect = node.getClientRect(),
        size = getSize(rect),
        filled = isFilled(node),
        zIndex = getZIndex(node),
        mouseOverlapsNode = MathUtils.isPointInRect(pos, rect);
      return [node, { rect, size, filled, zIndex, mouseOverlapsNode }];
    })
  );

  // Find the best shape to connect to
  // - if mouse is over filled shapes, choose the one with highest z-index
  //   if there are hollow shapes under mouse with higher z-index than (1), choose the smallest one.
  // - if mouse is over stage or over hollow shape, choose closest shape

  let topmostNode;
  for (const node of candidates) {
    const nodeData = nodesData.get(node)!;
    if (nodeData.mouseOverlapsNode) {
      if (!topmostNode) {
        topmostNode = node;
      } else {
        const topmostData = nodesData.get(topmostNode)!;
        if (nodeData.filled) {
          if (nodeData.zIndex > topmostData.zIndex) {
            topmostNode = node;
          }
        } else {
          if (nodeData.zIndex > topmostData.zIndex && nodeData.size < topmostData.size) {
            topmostNode = node;
          }
        }
      }
    }
  }

  if (topmostNode) {
    return getAnchorPointsData(topmostNode);
  }

  let closestNode: Konva.Node | undefined,
    minDistance = Number.MAX_SAFE_INTEGER;
  for (const node of candidates) {
    const curNode = nodesData.get(node)!;
    const distance = distPointToRect(pos, curNode.rect);
    if (Math.abs(distance) > margin) continue;
    if (distance < minDistance) {
      minDistance = distance;
      closestNode = node;
    }
  }

  return closestNode ? getAnchorPointsData(closestNode!) : [];
}

// TODO: remove once I'm sure the new functions work
export function findClosestAnchorPoint(list: ShapeAnchorPointsList, pos: PointInViewport) {
  let min = Number.MAX_SAFE_INTEGER,
    best: null | ShapeAnchorPointsList[0] = null;
  for (const anchor of list) {
    let d = distanceP(anchor.position, pos);
    if (d < min) {
      min = d;
      best = anchor;
    }
  }
  return { best, distance: min };
}

export function computeArrowPoints(
  endPoint: Point,
  direction: Point,
  lineWidth: number,
  allowedSize = Number.MAX_SAFE_INTEGER,
  arrowType?: string
): [Point, Point] {
  let v = normalized(direction),
    w = rotated90(v);

  const f = 1 + lineWidth / 10;
  let len = 15,
    width = 15;
  len *= f;
  width *= f;
  if (len > allowedSize) {
    const scaleDown = allowedSize / len;
    len = allowedSize;
    width *= scaleDown;
  }

  let x = endPoint.x - len * v.x,
    y = endPoint.y - len * v.y,
    p1 = {
      x: x + w.x * width,
      y: y + w.y * width,
    },
    p2 = {
      x: x - w.x * width,
      y: y - w.y * width,
    };
  return [p1, p2];
}

export function getNewConnector(
  start: { pos: Point; shapeId?: string; side?: string; t?: number },
  end: { pos: Point; shapeId?: string; side?: string; t?: number },
  createElements: any,
  newElementData: NewConnectorData
) {
  const newElements = createElements(start.pos, CursorType.connector);
  if (!newElements || newElements.length !== 1) return null;
  const newElement = newElements[0];
  const connector = newElement.element as Connector;
  connector.anchorMode = start.side;
  connector.anchorsMode = [start.side ?? "n/a", end.side ?? "n/a"];
  if (start.side == "outline" && start.t != undefined) connector.point1_t = start.t;
  if (end.side == "outline" && end.t != undefined) connector.point2_t = end.t;

  connector.connectedShapes = [
    { id: start.shapeId ?? "", type: "" },
    { id: end.shapeId ?? "", type: "" },
  ];
  connector.anchorOrientation = "buttom";
  connector.activeAnchorIndex = 1;
  connector.lineType = newElementData.lineType;
  connector.pointerStyles = [newElementData.endArrow, newElementData.startArrow];
  connector.dash = newElementData.dash;
  connector.stroke = newElementData.stroke;
  connector.strokeWidth = newElementData.strokeWidth;
  connector.textLocation = 0.5;

  connector.points = [
    { x: 0, y: 0 },
    { x: end.pos.x - start.pos.x, y: end.pos.y - start.pos.y },
  ];

  return newElement;
}

export function createConnector(
  start: { pos: Point; shapeId?: string; side?: string; t?: number },
  end: { pos: Point; shapeId?: string; side?: string; t?: number },
  syncService: any,
  createElements: any,
  onAddElements: (elements: NewElementData[]) => Promise<string[]>,
  newElementData: NewConnectorData
) {
  const newElement = getNewConnector(start, end, createElements, newElementData);

  // Note to future maintainers:
  // I originally tried creating the element in position {0,0}, and have points = [start,end]
  // That did not go well. Scaling 2 connectors or more would mess them up.
  // The connector's origin has to be start/end, and the points adjusted accordingly.

  return onAddElements([newElement]).then((newIds: string[]) => {
    if (start.shapeId) {
      const attachedConnector = {
        lineId: newIds[0],
      };
      attachConnector(syncService, start.shapeId, "", newIds[0], attachedConnector, 0);
    }
    if (end.shapeId) {
      const attachedConnector = {
        lineId: newIds[0],
      };
      attachConnector(syncService, end.shapeId, "", newIds[0], attachedConnector, 1);
    }
    return newIds;
  });
}

const RADIUS = 20;
const ARROW_LEN = 50;
const ARROW_WIDTH = 20;
const LINE_MARGIN = ARROW_LEN + RADIUS;

interface IConnectorDrawingContext extends Omit<CanvasPath, "arcTo" | "arc" | "ellipse" | "rect" | "roundRect"> {
  beginPath: () => void;
}

type LimitedCanvasPath = Omit<CanvasPath, "roundRect">;

function areAnchorsInPosition(anchorsMode: AnchorsModeList, posArray: AnchorsModeList): boolean {
  const test1 = anchorsMode[0] == posArray[0] && anchorsMode[1] == posArray[1];
  const test2 = anchorsMode[0] == posArray[1] && anchorsMode[1] == posArray[0];
  return test1 || test2;
}

function drawElbowLine(context: IConnectorDrawingContext, line: Point[]) {
  function sign(p: Point) {
    return { x: Math.sign(p.x), y: Math.sign(p.y) };
  }

  function bigSideLen(p: Point) {
    return Math.max(Math.abs(p.x), Math.abs(p.y));
  }

  context.beginPath();
  context.moveTo(line[0].x, line[0].y);
  let prev = line[0];
  for (let i = 1; i < line.length - 1; i++) {
    let fromPrev = vectorFromTo(prev, line[i]);
    let toNext = vectorFromTo(line[i], line[i + 1]);
    let lengthPrev = bigSideLen(fromPrev),
      lengthNext = bigSideLen(toNext);
    fromPrev = sign(fromPrev);
    toNext = sign(toNext);
    let radius = Math.min(RADIUS, lengthPrev / 2, lengthNext / 2);
    const nextPoint = {
      x: line[i].x + radius * toNext.x,
      y: line[i].y + radius * toNext.y,
    };
    context.lineTo(line[i].x - radius * fromPrev.x, line[i].y - radius * fromPrev.y);
    //TODO: use canvas's arcTo command which is intended just for this
    context.quadraticCurveTo(line[i].x, line[i].y, nextPoint.x, nextPoint.y);
    prev = nextPoint;
  }
  context.lineTo(line[line.length - 1].x, line[line.length - 1].y);
}

function axisAlignedElbowLine(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  const last = points.length - 1;
  context.beginPath();
  context.moveTo(points[0].x, points[0].y);
  context.lineTo(points[last].x, points[last].y);
}

export function createTopAnchoredLineLeftAnchor(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  const width = points[1].x - points[0].x;
  const height = points[1].y - points[0].y;
  const radius = Math.min(RADIUS, Math.abs(height / 2), Math.abs(width / 2));
  if (isConnected) {
    if (anchorsMode[1] == "right" && points[0].x > points[1].x) {
      drawElbowLine(context, [points[0], { x: points[0].x, y: points[1].y }, points[1]]);
      return;
    }
    if (anchorsMode[1] == "left" && points[0].x < points[1].x) {
      drawElbowLine(context, [points[0], { x: points[0].x, y: points[1].y }, points[1]]);
      return;
    }
    if (anchorsMode[1] == "right" || anchorsMode[1] == "left") {
      let xSign = anchorsMode[1] == "right" ? 1 : -1;
      drawElbowLine(context, [
        points[0],
        { x: points[0].x, y: points[0].y - LINE_MARGIN },
        { x: points[1].x + xSign * LINE_MARGIN, y: points[0].y - LINE_MARGIN },
        { x: points[1].x + xSign * LINE_MARGIN, y: points[1].y },
        points[1],
      ]);
      return;
    }
    drawElbowLine(context, [
      points[0],
      { x: points[0].x, y: points[0].y - LINE_MARGIN },
      { x: points[1].x, y: points[0].y - LINE_MARGIN },
      points[1],
    ]);
  } else {
    drawElbowLine(context, [points[0], { x: points[0].x, y: points[1].y }, points[1]]);
  }
}

export function createTopAnchoredLineBottomOrientationLeftAnchor(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  if (isConnected) {
    const isTopBottom = areAnchorsInPosition(anchorsMode, ["top", "buttom"]);
    const isRightTop = areAnchorsInPosition(anchorsMode, ["right", "top"]);
    if (isTopBottom) {
      const xmid = (points[0].x + points[1].x) / 2;
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: points[0].y - LINE_MARGIN,
        },
        {
          x: xmid,
          y: points[0].y - LINE_MARGIN,
        },
        {
          x: xmid,
          y: points[1].y + LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: points[1].y + LINE_MARGIN,
        },
        points[1],
      ]);
    } else {
      let xSign = isRightTop ? 1 : -1;
      let linePoints = [
        points[0],
        {
          x: points[0].x,
          y: points[0].y - LINE_MARGIN,
        },
        {
          x: points[1].x + xSign * LINE_MARGIN,
          y: points[0].y - LINE_MARGIN,
        },
        {
          x: points[1].x + xSign * LINE_MARGIN,
          y: points[1].y,
        },
        points[1],
      ];
      drawElbowLine(context, linePoints);
    }
  } else {
    drawElbowLine(context, [
      points[0],
      {
        x: points[0].x,
        y: points[0].y - LINE_MARGIN,
      },
      {
        x: points[1].x,
        y: points[0].y - LINE_MARGIN,
      },
      points[1],
    ]);
  }
}

export function createTopAnchoredLineBottomOrientation(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  if (isConnected) {
    const isLeft = areAnchorsInPosition(anchorsMode, ["left", "top"]);
    let xSign = isLeft ? 1 : -1;
    if (anchorsMode[0] == "buttom" && anchorsMode[1] == "top") {
      const xmid = (points[0].x + points[1].x) / 2;
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: points[0].y + LINE_MARGIN,
        },
        {
          x: xmid,
          y: points[0].y + LINE_MARGIN,
        },
        {
          x: xmid,
          y: points[1].y - LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: points[1].y - LINE_MARGIN,
        },
        points[1],
      ]);
    } else if (anchorsMode[0] == "top" && anchorsMode[1] == "top") {
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: points[1].y - LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: points[1].y - LINE_MARGIN,
        },
        points[1],
      ]);
    } else {
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x - xSign * LINE_MARGIN,
          y: points[0].y,
        },
        {
          x: points[0].x - xSign * LINE_MARGIN,
          y: points[1].y - LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: points[1].y - LINE_MARGIN,
        },
        points[1],
      ]);
    }
  } else {
    let linePoints = [
      points[0],
      {
        x: points[0].x,
        y: points[0].y - LINE_MARGIN,
      },
      {
        x: points[0].x,
        y: points[1].y - LINE_MARGIN,
      },
      {
        x: points[1].x,
        y: points[1].y - LINE_MARGIN,
      },
      points[1],
    ];
    drawElbowLine(context, linePoints);
  }
}

export function createTopAnchoredLine(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  if (isConnected) {
    const isTopLeft = areAnchorsInPosition(anchorsMode, ["left", "top"]);
    const isTopTop = areAnchorsInPosition(anchorsMode, ["top", "top"]);
    const isBottomTop = areAnchorsInPosition(anchorsMode, ["buttom", "top"]);
    if (isTopTop) {
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: points[0].y - LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: points[0].y - LINE_MARGIN,
        },
        points[1],
      ]);
    } else if (isBottomTop) {
      const midY = points[0].y / 2 + points[1].y / 2;
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: midY,
        },
        {
          x: points[1].x,
          y: midY,
        },
        points[1],
      ]);
    } else {
      let xSign = isTopLeft ? -1 : 1;
      let linePoints = [
        points[0],
        {
          x: points[0].x + xSign * LINE_MARGIN,
          y: points[0].y,
        },
        {
          x: points[0].x + xSign * LINE_MARGIN,
          y: points[1].y - LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: points[1].y - LINE_MARGIN,
        },
        points[1],
      ];
      drawElbowLine(context, linePoints);
    }
  } else {
    drawElbowLine(context, [
      points[0],
      { x: points[0].x, y: points[1].y - LINE_MARGIN },
      { x: points[1].x, y: points[1].y - LINE_MARGIN },
      points[1],
    ]);
  }
}

export function createBottomAnchoredLineBottomOrientationLeftAnchor(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  if (isConnected) {
    const isBottomLeft = areAnchorsInPosition(anchorsMode, ["buttom", "left"]);
    let xSign = isBottomLeft ? -1 : -1;

    if (anchorsMode[0] == "buttom" && anchorsMode[1] == "top") {
      const xmid = (points[0].x + points[1].x) / 2;
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: points[0].y + LINE_MARGIN,
        },
        {
          x: xmid,
          y: points[0].y + LINE_MARGIN,
        },
        {
          x: xmid,
          y: points[1].y - LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: points[1].y - LINE_MARGIN,
        },
        points[1],
      ]);
      return;
    }

    if (isBottomLeft) xSign = -xSign;
    let linePoints = [
      points[0],
      { x: points[0].x, y: points[0].y + LINE_MARGIN },
      {
        x: points[1].x - xSign * LINE_MARGIN,
        y: points[0].y + LINE_MARGIN,
      },
      {
        x: points[1].x - xSign * LINE_MARGIN,
        y: points[1].y,
      },
      points[1],
    ];
    drawElbowLine(context, linePoints);
  } else {
    let linePoints = [
      points[0],
      { x: points[0].x, y: points[0].y + LINE_MARGIN },
      { x: points[1].x, y: points[0].y + LINE_MARGIN },
      points[1],
    ];
    drawElbowLine(context, linePoints);
  }
}

export function createBottomAnchoredLineLeftAnchor(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  if (isConnected) {
    if (anchorsMode[0] == "buttom" && anchorsMode[1] == "top") {
      const midY = points[0].y / 2 + points[1].y / 2;
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: midY,
        },
        {
          x: points[1].x,
          y: midY,
        },
        points[1],
      ]);
      return;
    }
    drawElbowLine(context, [points[0], { x: points[0].x, y: points[1].y }, points[1]]);
  } else {
    drawElbowLine(context, [points[0], { x: points[0].x, y: points[1].y }, points[1]]);
  }
}

export function createBottomAnchoredLineBottomOrientation(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  if (isConnected) {
    if (anchorsMode[0] == "buttom") {
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: points[0].y + LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: points[0].y + LINE_MARGIN,
        },
        points[1],
      ]);
    } else if (anchorsMode[0] == "left" || anchorsMode[0] == "right") {
      const isRightBottom = anchorsMode[0] == "right";
      let xSign = isRightBottom ? +1 : -1;
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x + xSign * (ARROW_LEN + RADIUS),
          y: points[0].y,
        },
        {
          x: points[0].x + xSign * (ARROW_LEN + RADIUS),
          y: points[1].y + (ARROW_LEN + RADIUS),
        },
        {
          x: points[1].x,
          y: points[1].y + (ARROW_LEN + RADIUS),
        },
        points[1],
      ]);
    } else {
      const midY = points[0].y / 2 + points[1].y / 2;
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: midY, //points[0].y - LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: midY, //points[0].y - LINE_MARGIN,
        },
        points[1],
      ]);
    }
    return;
  } else {
    drawElbowLine(context, [points[0], { x: points[1].x, y: points[0].y }, points[1]]);
  }
}

export function createBottomAnchoredLine(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  if (isConnected) {
    const isRightBottom = areAnchorsInPosition(anchorsMode, ["right", "buttom"]);
    const isLeftBottom = areAnchorsInPosition(anchorsMode, ["left", "buttom"]);
    const isBottomBottom = !isRightBottom && !isLeftBottom;
    if (isBottomBottom) {
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x,
          y: points[1].y + LINE_MARGIN,
        },
        {
          x: points[1].x,
          y: points[1].y + LINE_MARGIN,
        },
        points[1],
      ]);
    } else {
      let xSign = isRightBottom ? +1 : isLeftBottom ? -1 : 0;
      drawElbowLine(context, [
        points[0],
        {
          x: points[0].x + xSign * LINE_MARGIN,
          y: points[0].y,
        },
        {
          x: points[0].x + xSign * LINE_MARGIN,
          y: points[1].y + (ARROW_LEN + RADIUS),
        },
        {
          x: points[1].x,
          y: points[1].y + (ARROW_LEN + RADIUS),
        },
        points[1],
      ]);
    }
  } else {
    drawElbowLine(context, [
      points[0],
      {
        x: points[0].x,
        y: points[1].y + (ARROW_LEN + RADIUS),
      },
      {
        x: points[1].x,
        y: points[1].y + (ARROW_LEN + RADIUS),
      },
      points[1],
    ]);
  }
}

// lines where abs(dx) > abs(dy)
export function createLine(
  context: IConnectorDrawingContext,
  shape: any,
  points: { x: number; y: number }[],
  isConnected: boolean,
  anchorsMode: AnchorsModeList
) {
  const width = points[1].x - points[0].x;
  const height = points[1].y - points[0].y;
  const dir = Math.sign(height);
  const radius = Math.min(RADIUS, Math.abs(height / 2));
  let flipX = Math.sign(width);
  let yRadius = 0;

  context.beginPath();
  context.moveTo(points[0].x, points[0].y);
  if (isConnected) {
    const topLeft: AnchorsModeList = ["top", "left"];
    const topRight: AnchorsModeList = ["top", "right"];
    const bottomLeft: AnchorsModeList = ["buttom", "left"];
    const bottomRight: AnchorsModeList = ["buttom", "right"];

    if (areAnchorsInPosition(anchorsMode, topLeft) || areAnchorsInPosition(anchorsMode, topRight)) {
      yRadius = 20;
      context.quadraticCurveTo(points[0].x, points[0].y - yRadius, points[0].x, points[0].y - yRadius);
    } else if (areAnchorsInPosition(anchorsMode, bottomLeft) || areAnchorsInPosition(anchorsMode, bottomRight)) {
      context.quadraticCurveTo(points[0].x, points[0].y - radius, points[0].x, points[0].y);
    }
  }
  context.lineTo(points[0].x + width / 2 - RADIUS * flipX, points[0].y - yRadius);
  context.quadraticCurveTo(
    points[0].x + width / 2,
    points[0].y - yRadius,
    points[0].x + width / 2,
    points[0].y - yRadius + dir * radius
  );
  context.lineTo(points[0].x + width / 2, points[1].y - dir * radius);
  context.quadraticCurveTo(points[0].x + width / 2, points[1].y, points[0].x + width / 2 + radius * flipX, points[1].y);
  context.lineTo(points[1].x, points[1].y);
}

// lines where abs(dy) > abs(dx_)
export function createFlippedLine(context: IConnectorDrawingContext, shape: any, points: { x: number; y: number }[]) {
  const width = points[1].x - points[0].x;
  const height = points[1].y - points[0].y;
  const radius = Math.min(RADIUS, Math.abs(width / 2));
  const signY = height > 0 ? 1 : -1;
  const signX = width > 0 ? 1 : -1;
  const midpointY = (points[0].y + points[1].y) / 2;

  context.beginPath();
  context.moveTo(points[0].x, points[0].y);
  context.lineTo(points[0].x, midpointY - radius * signY);
  context.quadraticCurveTo(points[0].x, midpointY, points[0].x + radius * signX, midpointY);
  context.lineTo(points[1].x - radius * signX, midpointY);
  context.quadraticCurveTo(points[1].x, midpointY, points[1].x, midpointY + radius * signY);
  context.lineTo(points[1].x, points[1].y);
}

// This function returns the direction of the line the elbow connector is attached to.
// The angle is the angle of the normal of the line (angle between normal and x-axis, clockwise)
function getCardinalDirection(angle: Degrees) {
  angle = ((angle + 360) % 360) as Degrees; // make sure it's in range 0..360
  if (angle <= 45) return "right";
  else if (angle <= 135) return "buttom";
  else if (angle <= 225) return "left";
  else if (angle <= 315) return "top";
  else return "right";
}

type ConnectorEndpoint = Point & {
  rotation?: number;
};

export function getElBowForBackCompat({
  start,
  end,
  anchorMode,
  anchorsMode,
  anchorIndexes,
  activeAnchorIndex,
}: {
  start: ConnectorEndpoint;
  end: ConnectorEndpoint;
  anchorMode?: string | null;
  activeAnchorIndex?: number | null;
  anchorIndexes: number[];
  anchorsMode?: (string | null)[] | null;
}) {
  let flip = end.x > end.x ? 1 : -1;
  const width = end.x - start.x;
  const height = end.y - start.y;
  const dir = Math.sign(height);
  const radius = Math.min(RADIUS, Math.abs(height / 2), Math.abs(width / 2));
  const thirdLineHeight = Math.abs(end.y - dir * radius - (start.y + dir * radius));
  const lastLineWidth = Math.abs(start.x + width / 2 + radius * flip - end.x);
  const shouldFlip = lastLineWidth / thirdLineHeight < 0.5 && !anchorMode;

  // horizontal or vertical lines are special cases
  if (Math.abs(height) < 1e-3 || Math.abs(width) < 1e-3) {
    return axisAlignedElbowLine;
  }

  if (anchorsMode) {
    anchorsMode = [...anchorsMode];
    if (start.rotation != undefined) {
      anchorsMode[0] = getCardinalDirection(start.rotation as Degrees);
    }
    if (end.rotation != undefined) {
      anchorsMode[1] = getCardinalDirection(end.rotation as Degrees);
    }
    const otherAnchorIndex = 1 - activeAnchorIndex!;
    const activeAnchorMode = anchorsMode![activeAnchorIndex!];
    const otherAnchorMode = anchorsMode![otherAnchorIndex!];
    const specialModes = ["top", "buttom"];
    let index = activeAnchorIndex;
    let anchorMode = activeAnchorMode!;
    if (activeAnchorMode === otherAnchorMode) {
      index = 1; //give left anchor precedence
    } else if (!specialModes.includes(activeAnchorMode!) && specialModes.includes(otherAnchorMode!)) {
      index = otherAnchorIndex;
      anchorMode = otherAnchorMode!;
    }
    const isLeftAnchor = index === 0;
    switch (anchorMode) {
      case "top":
        if (isLeftAnchor) {
          return height < 0 ? createTopAnchoredLineLeftAnchor : createTopAnchoredLineBottomOrientationLeftAnchor;
        } else {
          return height < 0 ? createTopAnchoredLineBottomOrientation : createTopAnchoredLine;
        }
      case "buttom":
        if (isLeftAnchor) {
          return height < 0 ? createBottomAnchoredLineBottomOrientationLeftAnchor : createBottomAnchoredLineLeftAnchor;
        } else {
          return height < 0 ? createBottomAnchoredLineBottomOrientation : createBottomAnchoredLine;
        }
      default:
        return shouldFlip ? createFlippedLine : createLine;
    }
  } else {
    return shouldFlip ? createFlippedLine : createLine;
  }
}

export class LinesRectIntersector implements IConnectorDrawingContext {
  public intersected = false;
  private x = 0;
  private y = 0;
  constructor(private boxCorners: [Point, Point, Point, Point, Point]) {}
  beginPath() {}
  closePath(): void {}
  quadraticCurveTo(cpx: number, cpy: number, x: number, y: number) {
    this.lineTo(x, y);
  }

  moveTo(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  lineTo(x: number, y: number) {
    if (this.intersected) return; // no need to check - a line already intersected
    for (let i = 0; i < this.boxCorners.length - 1; i++) {
      const p1 = this.boxCorners[i],
        p2 = this.boxCorners[i + 1],
        intersects = intersectSegments(this.x, this.y, x, y, p1.x, p1.y, p2.x, p2.y);
      if (intersects) {
        this.intersected = true;
        return;
      }
    }
    this.x = x;
    this.y = y;
  }

  bezierCurveTo(a1: number, a2: number, a3: number, a4: number, a5: number, a6: number) {} //TODO
}

interface ICurveSegment {
  bbox: () => BoundingBox;
  preFinalPoint: () => Point;
  secondPoint: () => Point;
  point: (f: number) => Point2;
  doesIntersectBox: (corners: [Point, Point, Point, Point, Point]) => boolean;
  drawOnCanvas: (context: LimitedCanvasPath) => void;
  length: () => number;
  getPointAlongSegment: (distance: number) => Point2;
}

class Line implements ICurveSegment {
  constructor(private x0: number, private y0: number, private x1: number, private y1: number) {}

  bbox() {
    let bbox = new BoundingBox();
    return bbox.expandPoint(this.x0, this.y0).expandPoint(this.x1, this.y1);
  }

  preFinalPoint() {
    return { x: this.x0, y: this.y0 };
  }

  secondPoint() {
    return { x: this.x1, y: this.y1 };
  }

  point(f: number) {
    if (f <= 0) return [this.x0, this.y0] as const;
    if (f >= 1) return [this.x1, this.y1] as const;
    return [lerp(this.x0, this.x1, f), lerp(this.y0, this.y1, f)] as const;
  }

  doesIntersectBox(corners: [Point, Point, Point, Point, Point]) {
    for (let i = 0; i < corners.length - 1; i++) {
      const p1 = corners[i],
        p2 = corners[i + 1];
      if (intersectSegments(this.x0, this.y0, this.x1, this.y1, p1.x, p1.y, p2.x, p2.y)) {
        return true;
      }
    }
    return false;
  }

  drawOnCanvas(context: LimitedCanvasPath) {
    context.lineTo(this.x1, this.y1);
  }

  length() {
    return distance(this.x0, this.y0, this.x1, this.y1);
  }

  getPointAlongSegment(distance: number) {
    let len = this.length();
    if (distance <= 0) return [this.x0, this.y0] as const;
    if (distance >= len) return [this.x1, this.y1] as const;
    return [lerp(this.x0, this.x1, distance / len), lerp(this.y0, this.y1, distance / len)] as const;
  }
}

export class QuadraticCurve implements ICurveSegment {
  private evaluator: (t: number) => readonly [number, number];

  constructor(
    private x0: number,
    private y0: number,
    private cpx: number,
    private cpy: number,
    private x1: number,
    private y1: number
  ) {
    this.evaluator = getQuadraticCurveEvaluator(this.x0, this.y0, this.cpx, this.cpy, this.x1, this.y1);
  }

  bbox() {
    return bboxOfQuadraticBezierCurve(this.x0, this.y0, this.cpx, this.cpy, this.x1, this.y1);
  }

  preFinalPoint() {
    return { x: this.cpx, y: this.cpy };
  }

  secondPoint() {
    return { x: this.cpx, y: this.cpy };
  }

  point(f: number) {
    if (f <= 0) return [this.x0, this.y0] as const;
    if (f >= 1) return [this.x1, this.y1] as const;
    return this.evaluator(f);
  }

  doesIntersectBox(corners: [Point, Point, Point, Point, Point]) {
    for (let i = 0; i < corners.length - 1; i++) {
      const p1 = corners[i],
        p2 = corners[i + 1];
      if (
        bezierIntersect.quadBezierLine(this.x0, this.y0, this.cpx, this.cpy, this.x1, this.y1, p1.x, p1.y, p2.x, p2.y)
      )
        return true;
    }
    return false;
  }

  drawOnCanvas(context: LimitedCanvasPath) {
    context.quadraticCurveTo(this.cpx, this.cpy, this.x1, this.y1);
  }

  length() {
    let prev: readonly [number, number] = [this.x0, this.y0];
    let len = 0;
    for (let i = 0.01; i <= 1; i += 0.01) {
      let cur = this.evaluator(i);
      len += distance(prev[0], prev[1], cur[0], cur[1]);
      prev = cur;
    }
    return len;
  }

  getPointAlongSegment(dist: number) {
    if (dist <= 0) return [this.x0, this.y0] as const;
    let prev;
    for (let i = 0; i < 1; i += 0.01) {
      let cur = this.evaluator(i);
      if (prev) {
        let segmentLength = distance(prev[0], prev[1], cur[0], cur[1]);
        if (dist <= segmentLength) {
          return [lerp(prev[0], cur[0], dist / segmentLength), lerp(prev[1], cur[1], dist / segmentLength)] as const;
        }
        dist -= segmentLength;
      }
      prev = cur;
    }
    return [this.x1, this.y1] as const;
  }
}

export class CubicCurve implements ICurveSegment {
  private evaluator: (f: number) => readonly [number, number];

  constructor(
    private x0: number,
    private y0: number,
    private cp1x: number,
    private cp1y: number,
    private cp2x: number,
    private cp2y: number,
    private x1: number,
    private y1: number
  ) {
    this.evaluator = getCubicCurveEvaluator(
      this.x0,
      this.y0,
      this.cp1x,
      this.cp1y,
      this.cp2x,
      this.cp2y,
      this.x1,
      this.y1
    );
  }

  bbox() {
    return bboxOfCubicBezierCurve(this.x0, this.y0, this.cp1x, this.cp1y, this.cp2x, this.cp2y, this.x1, this.y1);
  }

  preFinalPoint() {
    return { x: this.cp2x, y: this.cp2y };
  }

  secondPoint() {
    return { x: this.cp1x, y: this.cp1y };
  }

  point(f: number) {
    if (f <= 0) return [this.x0, this.y0] as const;
    if (f >= 1) return [this.x1, this.y1] as const;
    return this.evaluator(f);
  }

  doesIntersectBox(corners: [Point, Point, Point, Point, Point]) {
    for (let i = 0; i < corners.length - 1; i++) {
      const p1 = corners[i],
        p2 = corners[i + 1];
      if (
        bezierIntersect.cubicBezierLine(
          this.x0,
          this.y0,
          this.cp1x,
          this.cp1y,
          this.cp2x,
          this.cp2y,
          this.x1,
          this.y1,
          p1.x,
          p1.y,
          p2.x,
          p2.y
        )
      )
        return true;
    }
    return false;
  }

  drawOnCanvas(context: LimitedCanvasPath) {
    context.bezierCurveTo(this.cp1x, this.cp1y, this.cp2x, this.cp2y, this.x1, this.y1);
  }

  length() {
    let prev = this.evaluator(0.01);
    let len = 0;
    for (let i = 0.01; i <= 1; i += 0.01) {
      let cur = this.evaluator(i);
      len += distance(prev[0], prev[1], cur[0], cur[1]);
      prev = cur;
    }
    return len;
  }

  getPointAlongSegment(dist: number) {
    if (dist <= 0) return [this.x0, this.y0] as const;
    let prev;
    for (let i = 0; i < 1; i += 0.01) {
      let cur = this.evaluator(i);
      if (prev) {
        let segmentLength = distance(prev[0], prev[1], cur[0], cur[1]);
        if (dist <= segmentLength) {
          return [lerp(prev[0], cur[0], dist / segmentLength), lerp(prev[1], cur[1], dist / segmentLength)] as const;
        }
        dist -= segmentLength;
      }
      prev = cur;
    }
    return [this.x1, this.y1] as const;
  }
}

// TODO deprecate this once elbow connector uses SimpleConnectorData
export class RecordCanvasCmds implements IConnectorDrawingContext {
  public segments: ICurveSegment[] = [];
  private x = 0;
  private y = 0;

  beginPath() {}

  quadraticCurveTo(cpx: number, cpy: number, x: number, y: number) {
    this.segments.push(new QuadraticCurve(this.x, this.y, cpx, cpy, x, y));
    this.x = x;
    this.y = y;
  }

  moveTo(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  lineTo(x: number, y: number) {
    this.segments.push(new Line(this.x, this.y, x, y));
    this.x = x;
    this.y = y;
  }

  bezierCurveTo(cp1x: number, cp1y: number, cp2x: number, cp2y: number, x: number, y: number) {
    this.segments.push(new CubicCurve(this.x, this.y, cp1x, cp1y, cp2x, cp2y, x, y));
    this.x = x;
    this.y = y;
  }

  closePath(): void {}
}

export function calcBbox(drawCmds: ICurveSegment[]) {
  return drawCmds.reduce((acc, cur) => acc.expandRect(cur.bbox()), new BoundingBox());
}

export function startTangent(drawCmds: ICurveSegment[]) {
  let first = firstPoint(drawCmds);
  let second = drawCmds[0].secondPoint();
  return normalized(vectorFromTo(second, first));
}

export function firstPoint(drawCmds: ICurveSegment[]): Point {
  const p = drawCmds[0].point(0);
  return { x: p[0], y: p[1] };
}

export function endTangent(drawCmds: ICurveSegment[]) {
  let last = lastPoint(drawCmds);
  let before = drawCmds[drawCmds.length - 1].preFinalPoint();
  return normalized(vectorFromTo(before, last));
}

export function lastPoint(drawCmds: ICurveSegment[]): Point {
  const p = drawCmds[drawCmds.length - 1].point(1);
  return { x: p[0], y: p[1] };
}

function evalInLine(f: number, x0: number, y0: number, x1: number, y1: number) {
  return [lerp(x0, x1, f), lerp(y0, y1, f)] as const;
}

// for fast evaluation of a quadratic curve at t
// p(t) = (p0-2p1+p2)t^2 + 2t(p1-p2) + p0

function getQuadraticCurveEvaluator(x0: number, y0: number, cpx: number, cpy: number, x1: number, y1: number) {
  function getCoefficients(p0: number, p1: number, p2: number) {
    const a = p0 - 2 * p1 + p2;
    const b = 2 * (p1 - p2);
    const c = p0;
    return { a, b, c };
  }

  let x = getCoefficients(x0, cpx, x1);
  let y = getCoefficients(y0, cpy, y1);
  return (t: number) => {
    let xx = x.c + t * (x.b + t * x.a);
    let yy = y.c + t * (y.b + t * y.a);
    return [xx, yy] as const;
  };
}

function evalInQuadraticCurve(f: number, x0: number, y0: number, cpx: number, cpy: number, x1: number, y1: number) {
  const x = lerp(lerp(x0, cpx, f), lerp(cpx, x1, f), f);
  const y = lerp(lerp(y0, cpy, f), lerp(cpy, y1, f), f);
  return [x, y] as const;
}

// for fast evaluation of bezier curve at t (0 <= t <= 1)
// P = at^3 + bt^2 + ct + d  ==  d + t(c+t(b+ta))
// a=(-p0+3p1-3p2+p3)
// b=(3p0-6p1+3p2)
// c=(-3p0+3p1)
// d=(p0)
function getCubicCurveEvaluator(
  x0: number,
  y0: number,
  cp1x: number,
  cp1y: number,
  cp2x: number,
  cp2y: number,
  x1: number,
  y1: number
) {
  function getCoefficients(p0: number, p1: number, p2: number, p3: number) {
    const a = -p0 + 3 * p1 - 3 * p2 + p3;
    const b = 3 * p0 - 6 * p1 + 3 * p2;
    const c = -3 * p0 + 3 * p1;
    const d = p0;
    return { a, b, c, d };
  }

  let x = getCoefficients(x0, cp1x, cp2x, x1);
  let y = getCoefficients(y0, cp1y, cp2y, y1);
  return (t: number) => {
    let xx = x.d + t * (x.c + t * (x.b + t * x.a));
    let yy = y.d + t * (y.c + t * (y.b + t * y.a));
    return [xx, yy] as const;
  };
}

function evalInCubicCurve(
  f: number,
  x0: number,
  y0: number,
  cp1x: number,
  cp1y: number,
  cp2x: number,
  cp2y: number,
  x1: number,
  y1: number
) {
  function lerpCubic(p0: number, p1: number, p2: number, p3: number, f: number) {
    const q1 = lerp(p0, p1, f),
      q2 = lerp(p1, p2, f),
      q3 = lerp(p2, p3, f),
      r1 = lerp(q1, q2, f),
      r2 = lerp(q2, q3, f),
      s = lerp(r1, r2, f);
    return s;
  }

  return [lerpCubic(x0, cp1x, cp2x, x1, f), lerpCubic(y0, cp1y, cp2y, y1, f)] as const;
}

export function evaluatePointInCurve(drawCmds: ICurveSegment[]): (f: number) => Point2;
export function evaluatePointInCurve(drawCmds: ICurveSegment[], f?: number): Point2;
export function evaluatePointInCurve(drawCmds: ICurveSegment[], f?: number) {
  if (f == undefined) {
    return evaluatePointInCurve.bind(null, drawCmds) as ReturnPoint;
  }
  const innerPointsPerCurve = drawCmds.map((s) => evalManyPointsOnCurve(s));
  let curvesLen = [],
    total = 0;
  for (let i = 0; i < innerPointsPerCurve.length; i++) {
    let subtotal = 0;
    for (let j = 0; j < innerPointsPerCurve[i].length - 1; j++) {
      const cur = innerPointsPerCurve[i][j];
      const next = innerPointsPerCurve[i][j + 1];
      subtotal += distance(cur[0], cur[1], next[0], next[1]);
    }
    curvesLen.push(subtotal);
    total += subtotal;
  }

  f = MathUtils.clamp(f, 0, 1);
  let distOnCurve = f * total;
  let curveIndex = 0;

  for (curveIndex = 0; curveIndex < curvesLen.length; curveIndex++) {
    if (curvesLen[curveIndex] - distOnCurve > 0) break;
    distOnCurve -= curvesLen[curveIndex];
  }
  // due to floating point errors we can finish the loop with distOnCurve = 1e-14, meaning
  // it's still positive but 0 for all practical purposes
  if (curveIndex == curvesLen.length) curveIndex = curvesLen.length - 1;
  const curve = innerPointsPerCurve[curveIndex];
  for (let j = 0; j < curve.length - 1; j++) {
    const l = distance(curve[j][0], curve[j][1], curve[j + 1][0], curve[j + 1][1]);
    if (distOnCurve >= l) {
      distOnCurve -= l;
    } else {
      return evalInLine(distOnCurve / l, curve[j][0], curve[j][1], curve[j + 1][0], curve[j + 1][1]);
    }
  }
  return curve[curve.length - 1];
}

function evalManyPointsOnCurve(curve: ICurveSegment, N = 100): (readonly [number, number])[] {
  let result = [];
  const step = 1 / N;
  for (let i = 0; i <= 1; i += step) {
    result.push(curve.point(i));
  }
  return result;
}

// We're finding a tight bounding box for quadratic curve here.
// This is done by looking at extremum points of the curve, which can be found where first derivate = 0
// For lots of good explanations:
// https://floris.briolas.nl/floris/2009/10/bounding-box-of-cubic-bezier/
// https://pomax.github.io/bezierinfo/
function bboxOfCubicBezierCurve(
  x0: number,
  y0: number,
  cp1x: number,
  cp1y: number,
  cp2x: number,
  cp2y: number,
  x1: number,
  y1: number
) {
  function extremumPoints(p0: number, p1: number, p2: number, p3: number) {
    const a = 3 * (-p0 + 3 * p1 - 3 * p2 + p3);
    const b = 6 * (p0 - 2 * p1 + p2);
    const c = 3 * (p1 - p0);
    if (a == 0) return [];
    const discriminant = b * b - 4 * a * c;
    if (discriminant < 0) {
      return [];
    } else if (discriminant == 0) {
      return [-b / (2 * a)];
    } else {
      const d = Math.sqrt(discriminant);
      return [(-b + d) / (2 * a), (-b - d) / (2 * a)];
    }
  }

  const x_solutions = extremumPoints(x0, cp1x, cp2x, x1);
  const y_solutions = extremumPoints(y0, cp1y, cp2y, y1);
  let box = new BoundingBox();
  box.expandPoint(x0, y0);
  box.expandPoint(x1, y1);
  for (let i = 0; i < x_solutions.length; i++) {
    if (x_solutions[i] <= 0 || x_solutions[i] >= 1) {
      continue;
    }
    let p = evalInCubicCurve(x_solutions[i], x0, y0, cp1x, cp1y, cp2x, cp2y, x1, y1);
    box.expandPoint(p[0], p[1]);
  }
  for (let i = 0; i < y_solutions.length; i++) {
    if (y_solutions[i] <= 0 || y_solutions[i] >= 1) {
      continue;
    }
    let p = evalInCubicCurve(y_solutions[i], x0, y0, cp1x, cp1y, cp2x, cp2y, x1, y1);
    box.expandPoint(p[0], p[1]);
  }
  return box;
}

function bboxOfQuadraticBezierCurve(x0: number, y0: number, cp1x: number, cp1y: number, x1: number, y1: number) {
  const extremumX = (cp1x - x0) / (2 * cp1x - x0 - x1);
  const extremumY = (cp1y - y0) / (2 * cp1y - y0 - y1);
  let box = new BoundingBox();
  box.expandPoint(x0, y0).expandPoint(x1, y1);
  if (!Number.isNaN(extremumX) && extremumX > 0 && extremumX < 1) {
    let p = evalInQuadraticCurve(extremumX, x0, y0, cp1x, cp1y, x1, y1);
    box.expandPoint(p[0], p[1]);
  }
  if (!Number.isNaN(extremumY) && extremumY > 0 && extremumY < 1) {
    let p = evalInQuadraticCurve(extremumY, x0, y0, cp1x, cp1y, x1, y1);
    box.expandPoint(p[0], p[1]);
  }

  return box;
}

interface IConnectorBuilder {
  moveTo: (x: number, y: number) => void;
  lineTo: (x: number, y: number) => void;
  bezierCurve: (cp1x: number, cp1y: number, cp2x: number, cp2y: number, x2: number, y2: number) => void;
  // quadraticCurve: (cp1x: number, cp1y: number, x2: number, y2: number) => void;
  // arcTo: (x1: number, y1: number, x2: number, y2: number, radius: number) => void;
}

// this class can hold a list of drawing commands, and draw to a canvas context
// we can also compute some basic stuff like the direction at start and end and bounding box
export class SimpleConnectorData implements IConnectorBuilder {
  public segments: ICurveSegment[] = [];
  private x = 0;
  private y = 0;
  constructor() {}
  moveTo(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  lineTo(x: number, y: number) {
    this.segments.push(new Line(this.x, this.y, x, y));
    this.x = x;
    this.y = y;
  }

  bezierCurve(cp1x: number, cp1y: number, cp2x: number, cp2y: number, x2: number, y2: number) {
    this.segments.push(new CubicCurve(this.x, this.y, cp1x, cp1y, cp2x, cp2y, x2, y2));
    this.x = x2;
    this.y = y2;
  }

  drawOnCanvas(ctx: LimitedCanvasPath) {
    let first = true;
    for (const segment of this.segments) {
      if (first) {
        let start = segment.point(0);
        ctx.moveTo(start[0], start[1]);
        first = false;
      }
      segment.drawOnCanvas(ctx);
    }
  }

  firstPoint() {
    let [x, y] = this.segments[0].point(0);
    return { x, y };
  }

  finalPoint() {
    let [x, y] = this.segments[this.segments.length - 1].point(1);
    return { x, y };
  }

  preFinalPoint() {
    return this.segments[this.segments.length - 1].preFinalPoint();
  }

  secondPoint() {
    return this.segments[0].secondPoint();
  }

  getSelfRect() {
    return this.segments.reduce((acc, cur) => acc.expandRect(cur.bbox()), new BoundingBox());
  }

  doesIntersectBox(corners: [Point, Point, Point, Point, Point]) {
    return this.segments.length > 0 && this.segments.some((x) => x.doesIntersectBox(corners));
  }
}

// metrics:
// given a list of segments for the curve (bezier, linear or elbow)
// compute N points along each segment (N is fixed at start, can be dynamic)
// allow callers to:
//  find point closest to another point (reduce over points allows it)
//    this is per segment really, not necessarily reduce over points
//  get the T value for that point in global terms (not segment terms)
//  compute the point given the T value
//
// that means converting T := [0..1] to T in segments and vice versa
// best way is to linearize the entire curve into 1 segment in [0..1] range
// or to calculate the transformation between [0..total_length] and [0..1]
//
// linear segments can answer messages like getLength and getPoint and getT exactly
// curves needs approximate solutions

export class PathMetrics {
  private readonly segmentLengths: number[] = [];
  private readonly totalLength: number;

  constructor(private readonly path: SimpleConnectorData) {
    if (path.segments.length == 0) {
      console.warn("empty path given");
    }
    this.segmentLengths = path.segments.map((x) => x.length());
    this.totalLength = this.segmentLengths.reduce((a, b) => a + b, 0);
  }

  pathLength() {
    return this.totalLength;
  }

  *pathPointsAndPossibleBreakpoints() {
    let first = true;
    for (const segment of this.path.segments) {
      if (!first) {
        const [x, y] = segment.point(0);
        yield [x, y, true] as const;
      }
      const [x, y] = segment.point(0.5);
      yield [x, y, false] as const;
      first = false;
    }
  }

  //TODO: this function was good for getting a single point, not so good for getting multitude
  // t is normalized distance: 0..1 along the path
  getPointAlongPath(t: number): readonly [number, number] {
    if (this.totalLength == 0) return [0, 0]; // wrong answer, but at least we don't throw exception
    if (t <= 0) return this.path.segments[0].point(0);
    if (t >= 1) return this.path.segments[this.path.segments.length - 1].point(1);

    let targetLen = t * this.totalLength;
    for (let i = 0; i < this.segmentLengths.length; i++) {
      if (targetLen <= this.segmentLengths[i]) {
        return this.path.segments[i].getPointAlongSegment(targetLen);
      }
      targetLen -= this.segmentLengths[i];
    }
    console.warn("should not reach here");
    return this.path.segments[0].point(0);
  }
}

//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////

type AnchorPoint = PointAndDirection & {
  onBoundingBox: boolean;
};

export type StandardAnchorPoints = {
  top: AnchorPoint;
  right: AnchorPoint;
  buttom: AnchorPoint;
  left: AnchorPoint;
};

function mkPoint(x: number, y: number, rotation: number, onOutline: boolean = true): AnchorPoint {
  return { x, y, rotation: rotation as Degrees, onBoundingBox: onOutline };
}

const standardRectAnchorPoints = () => ({
  top: mkPoint(0.5, 0, 270),
  right: mkPoint(1, 0.5, 0),
  buttom: mkPoint(0.5, 1, 90),
  left: mkPoint(0, 0.5, 180),
});

export const shapeStandardAnchorPoints = (shapeType: string) => {
  switch (shapeType) {
    case consts.SHAPES.RECT:
    case consts.SHAPES.RECT_ROUNDED:
      return {
        top: mkPoint(0.5, 0, 270),
        right: mkPoint(1, 0.5, 0),
        buttom: mkPoint(0.5, 1, 90),
        left: mkPoint(0, 0.5, 180),
      };
    case consts.SHAPES.CIRCLE:
    case consts.SHAPES.DIAMOND:
      return {
        top: mkPoint(0, -1, 270),
        right: mkPoint(1, 0, 0),
        buttom: mkPoint(0, 1, 90),
        left: mkPoint(-1, 0, 180),
      };
    case consts.SHAPES.TRIANGLE:
      return {
        top: mkPoint(0, -1, 270),
        right: mkPoint(0.8660254038 / 2, -0.25, -30, false),
        buttom: mkPoint(0, 0.5, 90),
        left: mkPoint(-0.8660254038 / 2, -0.25, 210, false),
      };
    case consts.SHAPES.HEXAGON:
      return {
        top: mkPoint(0, -1, 270),
        right: mkPoint(0.8660254038, 0, 0),
        buttom: mkPoint(0, 1, 90),
        left: mkPoint(-0.8660254038, 0, 180),
      };
    default:
      return {
        top: mkPoint(0, -0.5, 270),
        right: mkPoint(0.5, 0, 0),
        buttom: mkPoint(0, 0.5, 90),
        left: mkPoint(-0.5, 0, 180),
      };
  }
};

export function transformAnchorPointsInPlace(
  tr: CanvasElementBox | Transform,
  points: StandardAnchorPoints
): StandardAnchorPoints {
  if (!(tr instanceof Transform)) {
    tr = new Transform(tr.x, tr.y, tr.width, tr.height, tr.rotate);
  }
  tr.transformPointAndDirection(points.top, points.top);
  tr.transformPointAndDirection(points.buttom, points.buttom);
  tr.transformPointAndDirection(points.left, points.left);
  tr.transformPointAndDirection(points.right, points.right);
  return points;
}

// note: not adding strokeWidth on purpose, since I have a branch that will remove the need for that
export function getShapeAnchorPoints(element: Shape) {
  const transform = getTransformParams("shape", element);
  const defaults = shapeStandardAnchorPoints(element.type);
  let points = defaults;
  if (element.type == consts.CANVAS_ELEMENTS.SHAPE && element.subtype) {
    const anchors = (AllShapes as any)[element.subtype]?.anchors;
    if (anchors) {
      points = {
        top: anchors.top ?? defaults.top,
        left: anchors.left ?? defaults.left,
        right: anchors.right ?? defaults.right,
        buttom: anchors.buttom ?? defaults.buttom,
      };
    }
  }
  points = structuredClone(points); // don't mutate originals
  return transformAnchorPointsInPlace(transform, points);
}

export function getTextAnchorPoints(element: any) {
  const transform = getTransformParams("textBlock", element);
  const points = standardRectAnchorPoints();
  return transformAnchorPointsInPlace(transform, points);
}

export function getStickyNoteAnchorPoints(element: StickyNote) {
  const transform = getTransformParams("stickyNote", element);
  const points = standardRectAnchorPoints();
  return transformAnchorPointsInPlace(transform, points);
}

export function getTableAnchorPoints(element: TypeTableElement) {
  const transform = getTransformParams("table", element);
  const points = standardRectAnchorPoints();
  return transformAnchorPointsInPlace(transform, points);
}

export function getDrawingAnchorPoints(element: Drawing) {
  const transform = getTransformParams("drawing", element);
  // TODO: this is already calculated in getTransformParams, so we can optimize this
  // maybe cache on the element...
  const drawing = element as Drawing;
  const bbox = BoundingBox.fromCoords(drawing.points).addPadding(
    (drawing.scaleX * parseStrokeWidth(drawing.strokeWidth)) / 2
  );
  const points = {
    top: mkPoint((bbox.left + bbox.right) / 2, bbox.top, 270, true),
    right: mkPoint(bbox.right, (bbox.top + bbox.bottom) / 2, 0, true),
    buttom: mkPoint((bbox.left + bbox.right) / 2, bbox.bottom, 90, true),
    left: mkPoint(bbox.left, (bbox.top + bbox.bottom) / 2, 180, true),
  };
  return transformAnchorPointsInPlace(transform, points);
}

export function getFileAnchorPoints(file: FileSchema) {
  const transform = getTransformParams("file", file);
  const points = standardRectAnchorPoints();
  return transformAnchorPointsInPlace(transform, points);
}

// this function calculates the anchor points just from the node, without the element.
// in some canvases we had a bug that element.width=0 and element.height=0, so we need to calculate the size from the node
export function getFileAnchorPointsFromNode(node: Konva.Node) {
  const clientRect = node.getClientRect({ skipTransform: true });
  const points = {
    top: mkPoint(clientRect.x + clientRect.width / 2, clientRect.y, 270, true),
    right: mkPoint(clientRect.x + clientRect.width, clientRect.y + clientRect.height / 2, 0, true),
    buttom: mkPoint(clientRect.x + clientRect.width / 2, clientRect.y + clientRect.height, 90, true),
    left: mkPoint(clientRect.x, clientRect.y + clientRect.height / 2, 180, true),
  };
  let transform = new Transform(
    node.x(),
    node.y(),
    node.scaleX(),
    node.scaleY(),
    (node.attrs.element.rotate || 0) as Degrees
  );
  return transformAnchorPointsInPlace(transform, points);
}

export function getTaskCardAnchorPoints(element: TaskCard) {
  const transform = getTransformParams("taskCard", element);
  const points = standardRectAnchorPoints();
  return transformAnchorPointsInPlace(transform, points);
}

export function getFrameAnchorPoints(element: Frame) {
  const transform = getTransformParams("frame", element);
  const points = standardRectAnchorPoints();
  return transformAnchorPointsInPlace(transform, points);
}

export function getIntegrationItemAnchorPoints(element: IntegrationItem) {
  const transform = getTransformParams("integrationItem", element);
  const points = standardRectAnchorPoints();
  let points2: any = transformAnchorPointsInPlace(transform, points);
  delete points2["buttom"];
  return points2;
}

export function getAnchorPoints(type: TypeCanvasElement, element: CanvasElement): StandardAnchorPoints | null {
  const provider = getElementGraphicsProvider(type);
  if (provider) {
    return provider.getConnectorAnchorPoints(element);
  }
  switch (type) {
    case "shape":
      return getShapeAnchorPoints(element as Shape);
    case "textBlock":
      return getTextAnchorPoints(element);
    case "drawing":
      return getDrawingAnchorPoints(element as Drawing);
    case "stickyNote":
      return getStickyNoteAnchorPoints(element as StickyNote);
    case "file":
      return getFileAnchorPoints(element as FileSchema);
    case "taskCard":
      return getTaskCardAnchorPoints(element as TaskCard);
    case "frame":
      return getFrameAnchorPoints(element as Frame);
    case "integrationItem":
      return getIntegrationItemAnchorPoints(element as IntegrationItem);
    case "table":
      return getTableAnchorPoints(element as TypeTableElement);
    // Cases that were originally mapped to null
    case "connector":
    case "comment":
    case "mindmap":
    case "mindmapOrgChart":
    case "cardStack":
    case "orgChartNode":
    case "orgChartRoot":
    case "liveIntegration":
    case "timeline":
    case "tableCell":
      return null;
    default:
      return null;
  }
}

export function getAnchorPointsFromNode(node: Konva.Node) {
  let anchors = getAnchorPoints(node.attrs.type, node.attrs.element)!;
  if (node.attrs.type == consts.CANVAS_ELEMENTS.FILE) {
    // this is a fix to a temporary bug we had that left bad data in some canvases
    const width = anchors.right.x - anchors.left.x;
    const height = anchors.buttom.y - anchors.top.y;
    if (width == 0 || height == 0) {
      anchors = getFileAnchorPointsFromNode(node);
    }
  }
  return anchors;
}

//--------------------------------------------------------------------------------

export function snapConnectorRotation(movingPoint: Point, fixedPoint: Point) {
  let dx = Math.abs(movingPoint.x - fixedPoint.x),
    dy = Math.abs(movingPoint.y - fixedPoint.y);
  if (dx == 0) {
    // user has created a vertical line without our help.
    // well done user !
  } else {
    let a = dy / dx;
    if (a < 0.5) {
      movingPoint.y = fixedPoint.y;
    } else if (a > 2) {
      movingPoint.x = fixedPoint.x;
    } else {
      let d = (dx + dy) / 2;
      movingPoint.x = fixedPoint.x + d * Math.sign(movingPoint.x - fixedPoint.x);
      movingPoint.y = fixedPoint.y + d * Math.sign(movingPoint.y - fixedPoint.y);
    }
  }
  return movingPoint;
}
