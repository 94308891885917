import style from "./logo.module.css";
import classNames from "classnames";

export default function AppLogo({ logoClicked }: {
    logoClicked?: () => void;
}) {
    const clickable = logoClicked !== undefined;
    return <div className={ classNames(style.logo, { [style.clickable]: clickable }) } onClick={ logoClicked }>
        work<b>canvas</b><small>.com</small>
    </div>
}