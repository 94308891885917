import { Connector, InnerPointSchema } from "shared/datamodel/schemas";
import * as utils from "utils/connector-utils";
import { Degrees, konvaTransformForElement, toRadians } from "utils/transform";
import Konva from "konva";
import * as PointUtils from "utils/point-utils";
import { IRect, smoothMaxUnit } from "utils/math-utils";

function prepNormal(inverseTransform: Konva.Transform, rotation: Degrees) {
  const rad = toRadians(rotation);
  const dir = inverseTransform.point({
    x: Math.cos(rad),
    y: Math.sin(rad),
  });
  return PointUtils.normalize(dir, dir);
}

function computeCurve(
  start: ConnectorEndpoint,
  end: ConnectorEndpoint,
  elementScaleAndRotation: Konva.Transform,
  innerPoints: InnerPointSchema[] | undefined,
  curveStrength?: number
) {
  // we invert the scale-rotation matrix so we can apply it to normal vectors
  // The point is that we want to compute normals in the connector reference frame,
  // but they will be scaled and rotated and deformed !
  // so we apply the inverse transform, and then the real transform will bring them
  // back to their good shape.
  elementScaleAndRotation.invert();

  if (!innerPoints?.length) {
    return computeCurveWithoutInnerPoints(start, end, elementScaleAndRotation, curveStrength);
  }

  let points = computeCurveWithInnerPoints(start, end, innerPoints);
  if (start.rotation != undefined) {
    const dir = prepNormal(elementScaleAndRotation, start.rotation);
    const availableLen = Math.max(Math.abs(innerPoints[0].x - start.x), Math.abs(innerPoints[0].y - start.y));
    const controlPointDist = availableLen / 2;
    const x = points[0] + dir.x * controlPointDist;
    const y = points[1] + dir.y * controlPointDist;
    points[2] = x;
    points[3] = y;
  }
  if (end.rotation != undefined) {
    const dir = prepNormal(elementScaleAndRotation, end.rotation);
    const N = innerPoints.length - 1;
    const availableLen = Math.max(Math.abs(innerPoints[N].x - end.x), Math.abs(innerPoints[N].y - end.y));
    const controlPointDist = availableLen / 2;
    const x = points[points.length - 2] + dir.x * controlPointDist;
    const y = points[points.length - 1] + dir.y * controlPointDist;
    points[points.length - 4] = x;
    points[points.length - 3] = y;
  }
  return points;
}

function computeCurveWithoutInnerPoints(
  start: ConnectorEndpoint,
  end: ConnectorEndpoint,
  invTr: Konva.Transform,
  curveStrength?: number
) {
  if (start.rotation == undefined && end.rotation == undefined) {
    let f = 0.5 * (end.x - start.x);
    let u = 0.5 * (end.y - start.y);
    // note: the -0.0001 improves stability for cases when dx==dy
    if (Math.abs(f) > Math.abs(u) - 0.0001) {
      u = 0;
    } else {
      f = 0;
    }
    return [start.x, start.y, start.x + f, start.y + u, end.x - f, end.y - u, end.x, end.y];
  } else if (start.rotation != undefined && end.rotation != undefined) {
    const dx = Math.abs(end.x - start.x);
    const dy = Math.abs(end.y - start.y);
    const dist = curveStrength ?? smoothMaxUnit(10, Math.max(dx, dy) * 0.25);

    // these are the directions I want start and end points to have
    // I use the inverse element transform first, and when drawn I'll get the correct vectors
    let startDir = prepNormal(invTr, start.rotation);
    let endDir = prepNormal(invTr, end.rotation);

    const cp1x = start.x + startDir.x * dist;
    const cp1y = start.y + startDir.y * dist;
    const cp2x = end.x + endDir.x * dist;
    const cp2y = end.y + endDir.y * dist;
    return [start.x, start.y, cp1x, cp1y, cp2x, cp2y, end.x, end.y];
  } else {
    const dx = Math.abs(end.x - start.x);
    const dy = Math.abs(end.y - start.y);
    let dist = smoothMaxUnit(50, Math.max(dx, dy) * 0.25);
    // let len = Math.sqrt(Math.pow(start.x - end.x, 2) + Math.pow(start.y - end.y, 2));
    // let dist = len / 2;

    let cp1x, cp1y;
    if (start.rotation != undefined) {
      let normal = prepNormal(invTr, start.rotation);
      cp1x = start.x + normal.x * dist;
      cp1y = start.y + normal.y * dist;
    } else {
      let normal = prepNormal(invTr, end.rotation!);
      cp1x = end.x + normal.x * dist;
      cp1y = end.y + normal.y * dist;
    }

    return [
      start.x,
      start.y,
      cp1x,
      cp1y,

      // TODO: remove these points. this should be a quadratic curve, not cubic
      // but I need to support quadratic curves elsewhere
      cp1x,
      cp1y,

      end.x,
      end.y,
    ];
  }
}

function computeCurveWithInnerPoints(
  start: ConnectorEndpoint,
  end: ConnectorEndpoint,
  innerPoints: InnerPointSchema[]
) {
  let result = [start.x, start.y, 0, 0]; // the tangent of the start-point to be filled later
  for (let i = 0; i < innerPoints.length; i++) {
    const prev = i == 0 ? start : innerPoints[i - 1];
    const cur = innerPoints[i];
    const next = i == innerPoints.length - 1 ? end : innerPoints[i + 1];
    let toPrev = { x: cur.x - prev.x, y: cur.y - prev.y };
    let toNext = { x: cur.x - next.x, y: cur.y - next.y };
    const len1 = Math.sqrt(toPrev.x * toPrev.x + toPrev.y * toPrev.y);
    const len2 = Math.sqrt(toNext.x * toNext.x + toNext.y * toNext.y);
    // the bisector for this point = (toPrev/len1 + toNext/len2)

    let bisector = { x: toPrev.x / len1 + toNext.x / len2, y: toPrev.y / len1 + toNext.y / len2 };
    PointUtils.normalize(bisector);
    // tangents are rotate(bisector, 90) and it's opposite
    // bisector can be(0,0), if cur is in the middle of [prev,next]
    // that will insert NaN into the calculations, so we check it and
    const tangent = PointUtils.invalid(bisector) ? PointUtils.normalized(toPrev) : PointUtils.rotated90(bisector);

    // tangents length should be some multiple of min(len1,len2)
    const tlen = 0.4 * Math.min(len1, len2);
    // compute control points:  cur ± tangent*tlen
    let cp1 = PointUtils.pAdd(cur, PointUtils.pMul(tangent, tlen));
    let cp2 = PointUtils.pSub(cur, PointUtils.pMul(tangent, tlen));
    // put control points in the right order; by looking at their angle with the tangent
    // angle is checked with dot product (if angle of 2 vectors < 90 deg, dot > 0)
    if (tangent.x * toPrev.x + tangent.y * toPrev.y > 0) {
      const temp = cp1;
      cp1 = cp2;
      cp2 = temp;
    }
    result.push(cp1.x, cp1.y, cur.x, cur.y, cp2.x, cp2.y);
  }
  result.push(0, 0, end.x, end.y);
  // fill tangents for start and end; default is towards next and prev points
  // next point for start is at indexes 6,7
  let px = result[6],
    py = result[7];
  let cp = PointUtils.lerp(start, { x: px, y: py }, 0.3);
  result[2] = cp.x;
  result[3] = cp.y;
  // prev point for end is at indexes -8,-7
  px = result[result.length - 8];
  py = result[result.length - 7];
  cp = PointUtils.lerp(end, { x: px, y: py }, 0.3);
  result[result.length - 4] = cp.x;
  result[result.length - 3] = cp.y;
  // caller can override the normals if she wishes
  return result;
}

export type ConnectorEndpoint = {
  x: number;
  y: number;
  rotation?: Degrees;
};

export function fixMode(mode: string | null | undefined) {
  // we had a bug that inserted numbers to some elements :-(
  if (!mode == null || mode == undefined) return "n/a";
  if (typeof mode == "number") {
    mode = ["left", "right", "top", "buttom"][+mode] ?? "n/a";
  }
  return mode;
}

export function computeConnectorDrawingData(
  p1: ConnectorEndpoint,
  p2: ConnectorEndpoint,
  element: Partial<Connector>,
  curveStrength?: number
) {
  let data = new utils.SimpleConnectorData();

  switch (element.lineType) {
    case undefined:
      console.warn("lineType must be supplied");
      break;

    case "line": {
      data.moveTo(p1.x, p1.y);
      if (element.innerPoints) {
        for (const p of element.innerPoints) {
          data.lineTo(p.x, p.y);
        }
      }
      data.lineTo(p2.x, p2.y);
      break;
    }
    case "curve": {
      // Bezier curve extend from shapes perpendicularly.
      // Our connector might be rotated and scaled non-uniformly, and that means directions will be twisted.
      // I pass the rotation+scale transform to offset that
      let points = computeCurve(
        p1,
        p2,
        konvaTransformForElement({
          x: 0,
          y: 0,
          scaleX: element.scaleX ?? 1,
          scaleY: element.scaleY ?? 1,
          rotate: element.rotate ?? 0,
        }),
        element.innerPoints,
        curveStrength
      );
      data.moveTo(points[0], points[1]);
      let i = 2;
      while (i < points.length) {
        // if (i == points.length - 6) {
        //   const cp1x = points[i++],
        //     cp1y = points[i++],
        //     x2 = points[i++],
        //     y2 = points[i++];
        //   data.quadraticCurve(cp1x, cp1y, x2, y2);
        // } else
        {
          const cp1x = points[i++],
            cp1y = points[i++],
            cp2x = points[i++],
            cp2y = points[i++],
            x2 = points[i++],
            y2 = points[i++];
          data.bezierCurve(cp1x, cp1y, cp2x, cp2y, x2, y2);
        }
      }

      break;
    }
    case "elbow":
      //TODO
      break;

    default:
      const _unhandled: never = element.lineType;
  }
  return data;
}

export function clipHole(context: Konva.Context, clipRect: IRect, scaleX: number, scaleY: number, rotate?: Degrees) {
  const halfW = clipRect.width / 2,
    halfH = clipRect.height / 2,
    x = clipRect.x + halfW,
    y = clipRect.y + halfH;
  const MAX_CANVAS_SIZE = 32766; // it's 32767 but I'm paranoid

  let path = new Path2D();
  path.rect(-MAX_CANVAS_SIZE / 2, -MAX_CANVAS_SIZE / 2, MAX_CANVAS_SIZE, MAX_CANVAS_SIZE);
  path.rect(scaleX * halfW, -scaleY * halfH, -scaleX * clipRect.width, scaleY * clipRect.height);

  const saved = context._context.getTransform();
  context.translate(x, y); // move to center of the rect, for the rotation
  context.scale(1 / scaleX, 1 / scaleY);
  rotate && context.rotate(-toRadians(rotate));
  context._context.clip(path, "evenodd");
  context._context.setTransform(saved);
}