import {
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  isSaturday,
  isSunday,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import * as locales from "date-fns/locale";
import React, { useMemo, useState } from "react";
import lightStyles from "./light-date-picker-range.module.css";
import darkStyles from "./dark-date-picker-range.module.css";
import cn from "classnames";
import { ChevronLeft, ChevronRight } from "frontend/icons/chevron";
import { on } from "events";

function getLocale() {
  const locale = navigator.language || "en-US";
  const normalizedLocale = locale.split("-").join("");

  if (locales.hasOwnProperty(normalizedLocale)) {
    return (locales as any)[normalizedLocale];
  }
  return locales.enUS;
}

function getCalendarGridDates(month: Date) {
  const start = startOfWeek(startOfMonth(month), { weekStartsOn: 0 });
  const end = endOfWeek(endOfMonth(month), { weekStartsOn: 0 });

  // Get all days between the start and end date
  const days = eachDayOfInterval({ start, end });

  return days;
}

export function DatePickerRange({
  fromDate,
  toDate,
  onSelected,
  enableRangeSelection = false,
  theme = "light",
  showActions = "none",
  onClear,
  onApply,
}: {
  fromDate?: Date;
  toDate?: Date;
  enableRangeSelection?: boolean;
  onSelected?: (fromDate: Date, toDate?: Date) => void;
  theme?: "light" | "dark";
  showActions?: "clear" | "apply" | "both" | "none";
  onClear?: () => void;
  onApply?: (fromDate?: Date, toDate?: Date) => void;
}) {
  const styles = theme === "light" ? lightStyles : darkStyles;
  const [start, setStart] = useState<Date | undefined>(fromDate);
  const [end, setEnd] = useState<Date | undefined>(toDate);
  const [month, setMonth] = useState(start ? startOfMonth(start) : startOfMonth(new Date()));
  const locale = getLocale();

  const [isSelectingStartDate, setIsSelectingStartDate] = useState(true);

  const weekdays = useMemo(() => {
    //todo: localize
    // todo: week days width should be responsive, because some language have long names
    return [0, 1, 2, 3, 4, 5, 6].map((i) => locale.localize!.day(i, { width: "narrow" }));
  }, [locale]);

  const monthDays = useMemo(() => getCalendarGridDates(month), [month]);

  function prevMonth() {
    setMonth((month) => addMonths(month, -1));
  }
  function nextMonth() {
    setMonth((month) => addMonths(month, 1));
  }
  function onDateClicked(e: React.MouseEvent<HTMLSpanElement>) {
    const dateAsStr = (e.target as HTMLElement).dataset.date;
    const date = new Date(dateAsStr!);
    if (enableRangeSelection) {
      // when choosing end date
      if (!isSelectingStartDate && start) {
        if (date < start) {
          // if chose a date before the start date, swap them
          setEnd(start);
          setStart(date);
          setIsSelectingStartDate(true);
          onSelected?.(date, start);
        } else {
          // if chose a date after the start date, set it as end date
          setEnd(date);
          setStart(start);
          setIsSelectingStartDate(true);
          onSelected?.(start, date);
        }
      } else {
        setStart(date);
        setIsSelectingStartDate(false);
        onSelected?.(date);
      }
    } else {
      setStart(date);
      onSelected?.(date);
    }
  }

  const showClear = showActions === "clear" || showActions === "both";
  const showApply = showActions === "apply" || showActions === "both";

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button onClick={prevMonth}>
          <ChevronLeft color={theme === "light" ? "#1973FF" : "#599AFF"} />
        </button>
        {format(month, "LLLL yyyy", { locale })}
        <button onClick={nextMonth}>
          <ChevronRight color={theme === "light" ? "#1973FF" : "#599AFF"} />
        </button>
      </div>

      <div className={styles.hr} />

      <div className={styles.calendar}>
        {weekdays.map((name, index) => (
          <div key={index} className={styles.dayName}>
            {name}
          </div>
        ))}
        {monthDays.map((day: Date) => (
          <button
            key={day.getTime()}
            className={cn(styles.day, {
              [styles.isSunday]: isSunday(day),
              [styles.isSaturday]: isSaturday(day),

              [styles.isOutOfCurrentMonth]: !isSameMonth(day, month),
              [styles.inRange]: start && end && day > start && day < end,
              [styles.selected]: (start && isSameDay(day, start)) || (end && isSameDay(day, end)),
              [styles.rangeStart]: start && isSameDay(day, start),
              [styles.rangeEnd]: end && isSameDay(day, end),
              [styles.startAndEndDate]: start && isSameDay(day, start) && end && isSameDay(day, end),
            })}
            data-date={day.toISOString()}
            onClick={onDateClicked}
          >
            {format(day, "d", { locale })}
          </button>
        ))}
      </div>
      <div className={styles.calendarActions}>
        {showClear && (
          <button
            className={styles.calendarAction}
            onClick={() => {
              setStart(undefined);
              setEnd(undefined);
              onClear?.();
            }}
          >
            Clear
          </button>
        )}
        {showApply && (
          <button
            className={styles.calendarAction}
            onClick={() => {
              onApply?.(start, end);
            }}
          >
            Apply
          </button>
        )}
      </div>
    </div>
  );
}

// shamelessly copied from stack-overflow. doesn't have all countries
const WeekStartsOn: { [key: string]: number } = {
  // fri:1, sat:2, sun:3
  MV: 1,
  AE: 2,
  AF: 2,
  BH: 2,
  DJ: 2,
  DZ: 2,
  EG: 2,
  IQ: 2,
  IR: 2,
  JO: 2,
  KW: 2,
  LY: 2,
  OM: 2,
  QA: 2,
  SD: 2,
  SY: 2,
  AG: 3,
  AS: 3,
  AU: 3,
  BD: 3,
  BR: 3,
  BS: 3,
  BT: 3,
  BW: 3,
  BZ: 3,
  CA: 3,
  CN: 3,
  CO: 3,
  DM: 3,
  DO: 3,
  ET: 3,
  GT: 3,
  GU: 3,
  HK: 3,
  HN: 3,
  ID: 3,
  IL: 3,
  IN: 3,
  JM: 3,
  JP: 3,
  KE: 3,
  KH: 3,
  KR: 3,
  LA: 3,
  MH: 3,
  MM: 3,
  MO: 3,
  MT: 3,
  MX: 3,
  MZ: 3,
  NI: 3,
  NP: 3,
  PA: 3,
  PE: 3,
  PH: 3,
  PK: 3,
  PR: 3,
  PT: 3,
  PY: 3,
  SA: 3,
  SG: 3,
  SV: 3,
  TH: 3,
  TT: 3,
  TW: 3,
  UM: 3,
  US: 3,
  VE: 3,
  VI: 3,
  WS: 3,
  YE: 3,
  ZA: 3,
  ZW: 3,
};
