import React, { useEffect, useMemo, useRef, useState } from "react";
import { atom, useAtom, useAtomValue } from "jotai";
import { ITraits, Trait } from "frontend/canvas-designer-new/elements-toolbar/elements-toolbar-types";
import { viewportToStage } from "frontend/utils/math-utils";
import { Group, Line } from "react-konva";
import consts from "shared/consts";
import {
  DRAW_PROPS_ID,
  OrgChartElement,
  OrgChartOrientation,
  OrgChartViewType,
  PHOTO_ID,
} from "shared/datamodel/schemas/org-chart";
import type { Patch } from "immer";
import { enablePatches, produceWithPatches } from "immer";
import {
  boardAtom,
  isAnyKindOfExportAtom,
  orgChartNodeDataForSidePanel,
  selectedElementIdsAtom,
  SidepanelType,
  sidePanelType,
  transformerRefAtom,
  userAtom,
  utilsAtom,
} from "state-atoms";
import * as utils from "../orgchart-utils";
import * as treeUtils from "./tree-layout-utils";
import { hierarchy, tree } from "d3-hierarchy";
import { MindmapNewNodeButton } from "frontend/canvas-designer-new/utility-elements/mindmap-sockets";
import { selectAtom } from "jotai/utils";
import { filter, keys, prop } from "rambda";
import { minIndexBy, noop } from "frontend/utils/fn-utils";
import { Degrees } from "frontend/utils/transform";
import { OrgChartNode, OrgChartViewOnlyNode } from "./orgchart-node";
import tracking from "frontend/tracking";
import {
  OrgchartCollapseButtonHorizontal as CollapseButtonH,
  OrgchartCollapseButtonVertical as CollapseButtonV,
} from "./orgchart-collapse-subtree-button";
import type Konva from "konva";
import { useOrgchartFileUploader } from "frontend/hooks/use-orgchart-uploader";
import { UploadedUppyFile } from "@uppy/core";
import { useEventListener } from "usehooks-ts";
import { parseColor } from "frontend/utils/color-utils";
import { CollapsedButtonData, computeTreeData, computeTreeLayout, EdgeData, NodeData } from "./org-chart-skeleton";
import { Shape } from "konva/types/Shape";
import { Stage } from "konva/types/Stage";
import { isIdOfType } from "../../canvas-elements-utils";
import GhostConnector from "../../connectors/ghost-connector";

enablePatches();

type SliceParameters<T> = T extends (first: any, ...args: infer U) => any ? U : never;
type Mutator = (element: Readonly<OrgChartElement>, ...args: any[]) => readonly [OrgChartElement, Patch[], Patch[]];

interface IKonvaDragEvent {
  target: Shape | Stage;
  evt: MouseEvent;
  currentTarget: Konva.Node;
  cancelBubble: boolean;
  child?: Konva.Node;
}

export interface NodeDrawProps {
  lineWidth: number;
  dash: number;
  color: string;
}

interface EdgeDrawProps {
  strokeWidth: number;
  stroke: string;
  dash: number;
}

const CurrentAnchorMode: "center" | "topleft" = "topleft";
export const TextFieldHeight = 15;
export const PictureRadius = 20;
export const LineWidthFactorIfSelected = 3;
const AddNodeButtonDist = PictureRadius + 12;
const SeparationLayers = 100;
const NodeWidth = 150;
const DragDropThreshold = 200 * 200;
const SeparationSiblings = 1.5;
const DefaultNodeStyle: NodeDrawProps = { lineWidth: 1, dash: 0, color: consts.ORG_CHART_COLOR_PALETTE[5] };

const getNodeDrawProps = (element: any, nodeId: string) =>
  Object.assign({}, DefaultNodeStyle, element.data[nodeId][DRAW_PROPS_ID]);

const arrayEq = <T,>(a: T[], b: T[]) => a.length == b.length && a.every((v) => b.includes(v));

const isOrgChartRootSelectedAtom = atom((get) =>
  get(selectedElementIdsAtom).some((id: string) => isIdOfType(id, consts.CANVAS_ELEMENTS.ORG_CHART))
);

export function OrgChartCanvasElement({
  uniqueId,
  element,
  patchCanvasElement,
  editable,
}: {
  uniqueId: string;
  element: OrgChartElement;
  patchCanvasElement: (args: { id: string; patch: any; inversePatch?: any; details?: any }) => void;
  editable: boolean;
}) {
  // State
  const board = useAtomValue(boardAtom);
  const user = useAtomValue(userAtom);
  const isExporting = useAtomValue(isAnyKindOfExportAtom);
  const [nodeForSidePanel, setNodeForSidePanel] = useAtom(orgChartNodeDataForSidePanel);
  const [openedSidePanel, setOpenedSidePanel] = useAtom(sidePanelType);
  const rootid = uniqueId.split("-")[1];
  const [editingFieldId, setEditingFieldId] = useState<string>("");

  const photoUploader = useOrgchartFileUploader();
  const canvasUtils = useAtomValue(utilsAtom(board?.documentId));
  const transformerRef = useAtomValue(transformerRefAtom);
  const dragRef = useRef<any>(null);
  const dropMarkRef = useRef<any>(null);

  const dragging = dragRef.current != null;
  // Create an atom that only contains our orgchart nodes from the selected-element-ids atom
  const myselectedAtom = useMemo(
    () =>
      selectAtom(
        selectedElementIdsAtom,
        filter((id: string) => id.includes(rootid)),
        arrayEq
      ),
    []
  );
  const mySelectedNodes = useAtomValue(myselectedAtom);
  // Now only when our nodes are selected, we'll re-render :-)

  // this is an atom that tells us if the root of _any_ orgchart is selected
  const isOrgChartRootSelected = useAtomValue(isOrgChartRootSelectedAtom);

  const visibleFields = element.orderOfFields.filter((id) => element.extraFields[id].hidden == false);
  const itemView = utils.getItemView(element);
  const withPicture = itemView == OrgChartViewType.Default;
  const height = (visibleFields.length + 3) * TextFieldHeight + 5 + 5 + (withPicture ? PictureRadius : 0); // 5 is for padding

  function track(action: string) {
    tracking.trackEvent(consts.TRACKING_CATEGORY.ORG_CHART, action, board?.documentId, board?.accountId, user?.id);
  }

  // a small auxiliary function to call a mutator and send the patch onwards
  function patch<T extends Mutator>(fn: T, ...args: SliceParameters<T>) {
    const mutation = fn(element, ...args);
    if (mutation[1].length) {
      patchCanvasElement({ id: uniqueId, patch: mutation[1], inversePatch: mutation[2] });
    }
  }

  const openUploadModal = photoUploader ? (nodeId: string) => photoUploader(uniqueId, nodeId, onUploadComplete) : null;

  function onUploadComplete(file: UploadedUppyFile<any, any>) {
    track("orgchart_picture_upload_done");
    patch(utils.updatePhotoInNode, file.meta.nodeId, file.meta.url);
  }

  const setEditFieldWithTracking = (fieldId: string) => {
    if (!editable) return;
    if (fieldId) {
      track("orgchart_edit_field");
    }
    setEditingFieldId(fieldId);
  };

  function isEditorOpenForNode(nodeId: string) {
    return (
      openedSidePanel == SidepanelType.orgChartNodeInfo &&
      Boolean(nodeForSidePanel && nodeForSidePanel.rootid == uniqueId && nodeForSidePanel.selectedNode == nodeId)
    );
  }

  function toggleEditor(nodeId: string) {
    if (isEditorOpenForNode(nodeId)) {
      setOpenedSidePanel((prev) =>
        prev == SidepanelType.orgChartNodeInfo ? SidepanelType.none : SidepanelType.orgChartNodeInfo
      );
    } else {
      setNodeForSidePanel({ rootid: uniqueId, selectedNode: nodeId, editable });
      setOpenedSidePanel(SidepanelType.orgChartNodeInfo);
    }
  }

  // Cut/Copy/Paste operations
  interface OrgChartClipboard {
    miniclipboard: null | (ReturnType<typeof utils.copyNodes> & { originalSelected: string[] });
    mySelectedNodes: string[];
    element: OrgChartElement;
  }
  const clipboardData = useRef<OrgChartClipboard>({
    miniclipboard: null,
    mySelectedNodes,
    element,
  });
  clipboardData.current.mySelectedNodes = mySelectedNodes;
  clipboardData.current.element = element;

  function copySelected(e: any) {
    // if any html element is focused, don't copy
    if (document.activeElement != document.body) return null;
    // reset the internal clipboard
    clipboardData.current.miniclipboard = null;
    let { mySelectedNodes, element } = clipboardData.current;
    // we don't copy if the root is selected - canvas-stage will copy the entire chart
    if (mySelectedNodes.some(utils.isIdOfRoot)) return null;
    // from this point on we handle the event
    e.preventDefault();
    e.stopPropagation();
    // get the short ids of the selected nodes
    const nodeIds = mySelectedNodes.map((id) => utils.parseNodeId(id)!.nodeId);
    // just one final check...
    if (nodeIds.length == 0 || nodeIds.includes("root")) {
      // I've seen this case happen, though it should not.
      // should investigate
      return null;
    }
    // copy the information we'll need, and return the ids we copied
    clipboardData.current.miniclipboard = { ...utils.copyNodes(element, nodeIds), originalSelected: mySelectedNodes };
    return nodeIds;
  }

  // @ts-ignore
  useEventListener("copy", !editable ? noop : copySelected, window);
  useEventListener(
    // @ts-ignore
    "cut",
    !editable
      ? noop
      : (e) => {
          const copy = copySelected(e);
          if (copy) {
            patch(utils.deleteNodes, copy);
            canvasUtils?.clearSelectedElementIds();
          }
        }
  );
  useEventListener(
    // @ts-ignore
    "paste",
    !editable
      ? noop
      : (e) => {
          if (document.activeElement != document.body) return;
          if (!clipboardData.current.miniclipboard) return;

          e.preventDefault();
          e.stopPropagation();
          const { data, layout, roots, originalSelected } = clipboardData.current.miniclipboard;

          if (mySelectedNodes.length && originalSelected && arrayEq(mySelectedNodes, originalSelected)) {
            // paste the copied data as siblings
            let newIds: any = {};
            const getNewId = (id: string) => (newIds[id] ??= utils.createNodeId());
            const mutation = produceWithPatches(element, (draft) => {
              for (const id in data) {
                const newid = getNewId(id);
                draft.data[newid] = { ...data[id], id: newid };
                draft.layout[newid] = {
                  id: newid,
                  collapsed: draft.layout[id].collapsed,
                  childrenIds: draft.layout[id].childrenIds.map(getNewId),
                };
              }
              roots.forEach((root: string) => {
                draft.layout[utils.findParent(draft, root)!].childrenIds.push(getNewId(root));
              });
            });
            patchCanvasElement({
              id: uniqueId,
              patch: mutation[1],
              inversePatch: mutation[2],
              details: { action: "orgchart-add-node", count: keys(newIds).length },
            });
          } else if (mySelectedNodes.length == 1) {
            // put all the copied data as child of this single node
            const newParentForPaste = utils.parseNodeId(mySelectedNodes[0])!.nodeId;
            const newIds = utils.createNewIds(data) as Record<string, string>;
            const mutation = produceWithPatches(element, (draft) => {
              for (const id in newIds) {
                draft.data[newIds[id]] = { ...data[id], id: newIds[id] };
                draft.layout[newIds[id]] = {
                  id: newIds[id],
                  collapsed: layout[id].collapsed,
                  childrenIds: layout[id].childrenIds.map((c: string) => newIds[c]),
                };
              }
              const newRoots = roots.map((id: string) => newIds[id]);
              draft.layout[newParentForPaste].childrenIds.push(...newRoots);
            });
            patchCanvasElement({
              id: uniqueId,
              patch: mutation[1],
              inversePatch: mutation[2],
              details: { action: "orgchart-add-node", count: keys(newIds).length },
            });
          }
        }
  );

  let { nodesData, rootNode, collapseButtonOffset } = useMemo(
    () => computeTreeData(element.layout, "root", element.orientation, height),
    [element.layout, element.orientation, height]
  );

  function computeOffsetToTopLeftAnchor() {
    let offsetToLeft = 0,
      offsetToBottom = 0;
    if (nodesData.length) {
      if (element.orientation == OrgChartOrientation.TopToBottom) {
        const leftmost = minIndexBy(prop("x"), nodesData);
        offsetToLeft = -leftmost[0].x;
      } else {
        const topmost = minIndexBy(prop("y"), nodesData);
        offsetToBottom = -topmost[0].y;
      }
    }
    return { x: offsetToLeft, y: offsetToBottom };
  }

  let anchor = computeOffsetToTopLeftAnchor();

  // this component renders the org chart in 'topleft' mode, where the element's (x,y) is the top left
  // of the bounding box of the org-chart tree. If we've just read a 'root' mode element, we need to
  // adjust the x,y so it doesn't move on the canvas
  useEffect(() => {
    if (element.anchor != "topleft" && CurrentAnchorMode == "topleft") {
      let [, patch, inversePatch] = produceWithPatches(element, (draft) => {
        draft.x -= anchor.x;
        draft.y -= anchor.y;
        draft.anchor = "topleft";
      });
      patchCanvasElement({ id: uniqueId, patch, inversePatch });
    }
  }, []);

  function createMutationForAddingNode(baseNodeId: { isRoot: boolean; nodeId: string }, relation: utils.Relationship) {
    if (baseNodeId.isRoot && relation != "child") {
      throw new Error("root node can only have children");
    }
    let [, patch, inversePatch] = produceWithPatches(element, (draft) => {
      utils.createAndInsertNode(draft, baseNodeId.nodeId, relation);
      if (relation == "child") {
        draft.layout[baseNodeId.nodeId].collapsed = false;
      }
    });
    return [patch, inversePatch];
  }

  function createNewNode(baseNodeId: { isRoot: boolean; nodeId: string }, relation: utils.Relationship) {
    try {
      let [patch, inversePatch] = createMutationForAddingNode(baseNodeId, relation);
      // execute the mutation
      patchCanvasElement({
        id: uniqueId,
        patch,
        inversePatch,
        details: { action: "orgchart-add-node" },
      });
    } catch (error) {
      console.error(error);
    }
  }

  function createNewNodeFromButton(nodeId: string, relation: utils.Relationship) {
    track("orgchart_add_element_clicked");
    createNewNode({ isRoot: nodeId == "root", nodeId }, relation);
  }

  // @ts-ignore
  useEventListener(
    "keydown",
    !editable
      ? noop
      : (e: KeyboardEvent) => {
          if (e.key == "Enter" && (e.target as HTMLElement).nodeName == "BODY") {
            if (mySelectedNodes.length == 1) {
              const nodeId = utils.parseNodeId(mySelectedNodes[0]);
              if (!nodeId) {
                console.error("failed to parse selected node id");
                return;
              }
              createNewNode(nodeId, "child"); // sibling-after
            }
          }
        }
  );

  const layoutUtil = treeUtils.TreeLayoutHelper.from(element.orientation, NodeWidth, height, SeparationLayers);

  // We want to highlight the path from selected nodes to the root
  // so we mark the selected nodes and their ancestors
  // TODO: we don't really need to save nodesToHighlight..
  // we just want it for getDrawProps
  let nodesToHighlight: Record<string, boolean> = {};
  if (mySelectedNodes.length && !isExporting) {
    nodesToHighlight = utils.getAncestors(
      nodesData,
      mySelectedNodes.map((id) => utils.parseNodeId(id)!.nodeId)
    );
  }

  function onDragStart(ev: IKonvaDragEvent) {
    const node = ev.target;
    const nodeId: string = node.attrs.nodeId;
    if (!nodeId) {
      console.warn("Can't find node-id on drag target; won't drag");
      return;
    }
    const x0 = ev.evt.clientX,
      y0 = ev.evt.clientY,
      masterContainer = node.getParent() as Konva.Group,
      descendants = utils.collectDescendants(element.layout, nodeId, false),
      subtree = masterContainer.getChildren((x) => descendants.includes(x.attrs.nodeId)).toArray(),
      edges = masterContainer
        .getChildren((x) => x.attrs.type == "edge" && descendants.includes(x.attrs.childId))
        .toArray();

    // move the dragged subtree to top, so you can see it above the others
    node.moveToTop();
    subtree.forEach((x) => x.moveToTop());

    dragRef.current ||= {
      draggedByUser: new Set(),
      dragEnded: 0,
      targets: utils.calculateDragDropTargets(
        computeTreeLayout(element.layout, "root", layoutUtil.getNodeSizeForD3tree()),
        element.orientation,
        NodeWidth,
        height
      ),
      invalidDrops: new Set(),
    };

    const edgeToParent = node
      .getParent()
      .getChildren((x: any) => x.attrs.type == "edge" && x.attrs.childId == nodeId)
      .toArray()[0];
    edgeToParent.visible(false);

    const markerPointsPerpendicular = layoutUtil.nodeEdgePerpMainAxis();
    const markerPointsCrossAxis = layoutUtil.nodeEdgePerpCrossAxis();

    dragRef.current.draggedByUser.add(node);
    dragRef.current.invalidDrops.add(node.attrs.nodeId);
    subtree.forEach((x) => dragRef.current.invalidDrops.add(x.attrs.nodeId));
    node.on("dragmove", onDragMove);
    node.on("dragend", onDragEnd);

    function onDragMove(ev: IKonvaDragEvent) {
      let dx = ev.evt.clientX - x0,
        dy = ev.evt.clientY - y0;
      const stage = ev.target.getStage()!;
      dx /= stage!.scale().x;
      dy /= stage!.scale().x;
      // TODO: when starting to drag a new node, filter out from subtree the node, that way I don't have to check here
      subtree.forEach((node: Konva.Node) =>
        dragRef.current.draggedByUser.has(node) ? void 0 : node.offset({ x: -dx, y: -dy })
      );
      edges.forEach((node: Konva.Node) => node.offset({ x: -dx, y: -dy }));

      // this part can be throttled and done async, no need to do it here
      const dropTargets = dragRef.current.targets;
      const canvasPos = viewportToStage(
        { x: stage.position().x, y: stage.position().y, scale: stage.scale().x },
        { x: ev.evt.clientX, y: ev.evt.clientY }
      );
      // make it relative to org chart element
      canvasPos.x -= element.x + anchor.x;
      canvasPos.y -= element.y + anchor.y;
      // find closest node that isn't part of the dragged subtree
      let minDist = Number.MAX_SAFE_INTEGER,
        best: any = null;
      for (const drop of dropTargets) {
        const { x, y, node: dropNode } = drop;
        if (dragRef.current.invalidDrops.has(dropNode.data.id)) continue;
        if (descendants.includes(dropNode.data.id)) continue;
        const dist = (canvasPos.x - x) ** 2 + (canvasPos.y - y) ** 2;
        if (dist < minDist) {
          minDist = dist;
          best = drop;
        }
      }
      if (minDist < DragDropThreshold && best != null) {
        dropMarkRef.current.visible(true);
        dropMarkRef.current.attrs.data = best;
        dropMarkRef.current.position({ x: best.x, y: best.y });
        if (best.dir == "child") {
          dropMarkRef.current.points(markerPointsPerpendicular);
        } else {
          dropMarkRef.current.points(markerPointsCrossAxis);
        }
      } else {
        dropMarkRef.current.visible(false);
        dropMarkRef.current.attrs.data = null;
      }
    }

    function onDragEnd(ev: IKonvaDragEvent) {
      // reset everything. if we've dragged to a new location in the tree, this whole component will be rendered anew.
      // and if not, we need to reset to restore the state of the tree
      ev.target.position({ x: 0, y: 0 });
      subtree.forEach((node: Konva.Node) => node.offset({ x: 0, y: 0 }));
      edges.forEach((node: Konva.Node) => node.offset({ x: 0, y: 0 }));
      ev.target.off("dragmove", onDragMove);
      ev.target.off("dragend", onDragEnd);
      edgeToParent.visible(true);

      // count number of end-drag; when it matches number of start-drags we can create a single mutation to change the orgchart
      dragRef.current.dragEnded++;
      if (dragRef.current.dragEnded == dragRef.current.draggedByUser.size) {
        const dragged = dragRef.current.draggedByUser;
        const data = dropMarkRef.current.attrs.data;
        if (data?.dir) {
          let draggedIds = [...dragged].map((x) => x.attrs.nodeId);
          // The following 2 lines filter out the nodes that are descendants of other dragged nodes,
          // by moving only the root nodes of the dragged subtrees, I keep structure.
          // TODO: this calculation is (probably) made somewhen before here, so it can be cached.
          let d2 = draggedIds.flatMap((id) => utils.collectDescendants(element.layout, id, false));
          draggedIds = draggedIds.filter((id) => !d2.includes(id));

          // nodes should be inserted in the order they were in.
          // if A is left of B in the tree, after dragging A should still be left of B.
          // To sort the dragged-nodes in the order they were in, I traverse the tree in-order
          // and pick the dragged nodes from this ordered traversal.
          function* traverseInOrder(root = "root"): Generator<string, void, any> {
            const node = element.layout[root];
            const children = node.childrenIds?.length ?? 0;
            const mid = Math.ceil(children / 2);
            // in our trees, children are positioned on both sides of the root, symmetrically,
            // so half are left of the root, half to the right
            // yield the left half first, then the root, then the right half
            for (let i = 0; i < mid; i++) {
              const child = node.childrenIds[i];
              yield* traverseInOrder(child);
            }
            yield root;
            for (let i = mid; i < node.childrenIds.length; i++) {
              const child = node.childrenIds[i];
              yield* traverseInOrder(child);
            }
          }
          let inorder = [];
          for (const node of traverseInOrder()) {
            if (draggedIds.includes(node)) {
              inorder.push(node);
            }
          }
          draggedIds = inorder;

          if (data.dir == "child") {
            patch(utils.moveNodesUnderAnother, draggedIds, data.node.data.id);
          } else if (data.dir == "sibling-after") {
            patch(utils.moveNodesToRightOfAnother, draggedIds, data.node.data.id, data.node.parent.data.id);
          } else if (data.dir == "sibling-before") {
            patch(utils.moveNodesToLeftOfAnother, draggedIds, data.node.data.id, data.node.parent.data.id);
          } else {
            console.warn("unknown relationship in drop-target-marker", data);
          }
        }
        dragRef.current = null;
        dropMarkRef.current.visible(false);
        //TODO: the transformer stays in the previous location of the dragged nodes.
        // updating it doesn't help so I turn it off to hide this problem
        setTimeout(() => {
          transformerRef?.current?.nodes([]);
        }, 50);
      }
    }
  }

  const Btn = element.orientation == OrgChartOrientation.TopToBottom ? CollapseButtonH : CollapseButtonV;
  const Node = editable ? OrgChartNode : OrgChartViewOnlyNode;

  const dragStart = !isOrgChartRootSelected && editable ? onDragStart : undefined;

  function renderNode(nodedata: NodeData) {
    const nodeId = nodedata.id;
    let id = utils.makeNodeId(rootid, nodedata.id);
    const toggleButton =
      nodedata.directChildren > 0
        ? renderCollapseButton({
            nodeId,
            x: collapseButtonOffset.x,
            y: collapseButtonOffset.y,
            direct: nodedata.directChildren,
            total: nodedata.descendants,
            collapsed: nodedata.collapsed,
          })
        : null;
    const edgeToParent = nodedata.parentId
      ? renderEdge({
          childId: nodeId,
          parentId: nodedata.parentId,
          childAnchor: nodedata.edgeToParent.childAnchor,
          parentAnchor: nodedata.edgeToParent.parentAnchor,
        })
      : null;

    return (
      <React.Fragment key={nodeId}>
        {edgeToParent}
        <Node
          id={id}
          type={consts.CANVAS_ELEMENTS.ORG_CHART}
          isRoot={false}
          position={nodedata}
          width={150}
          height={height}
          fieldsToShow={visibleFields}
          fieldsTitle={element.extraFields}
          showImage={withPicture}
          data={element.data[nodeId]}
          nodeDrawProps={getNodeDrawProps(element, nodeId)}
          // extra props
          nodeId={nodeId}
          element={element}
          isSelected={mySelectedNodes.includes(id)}
          editingFieldId={editingFieldId}
          setEditingFieldId={setEditFieldWithTracking}
          onFieldUpdate={(fieldId: string, final: string, initial: string) =>
            patch(utils.updateFieldInNode, nodeId, fieldId, final)
          }
          isSidePanelOpenForMe={isEditorOpenForNode(nodeId)}
          onOpenPanel={() => toggleEditor(nodeId)}
          onClick={() => setNodeForSidePanel({ rootid: uniqueId, selectedNode: nodeId, editable })}
          onDragStart={dragStart}
          onPhotoClick={openUploadModal && editable ? () => openUploadModal(nodeId) : undefined}
          isExporting={isExporting}
        >
          {toggleButton}
        </Node>
      </React.Fragment>
    );
  }

  function renderCollapseButton({ nodeId, x, y, direct, total, collapsed }: CollapsedButtonData) {
    const { color } = getNodeDrawProps(element, nodeId);
    const btncolor = collapsed ? color : utils.lightenColor(color);
    return (
      <Btn
        key={"collapse" + nodeId}
        belongsTo={nodeId}
        x={x}
        y={y}
        color={btncolor}
        collapsed={collapsed}
        direct={direct}
        indirect={total}
        onClick={!editable ? undefined : () => patch(utils.toggleCollapseNode, nodeId)}
      />
    );
  }

  function renderEdge({ childId, childAnchor, parentAnchor }: EdgeData) {
    return (
      <Group key={childId} type="edge" childId={childId}>
        <GhostConnector
          id={childId}
          p1={parentAnchor}
          p2={childAnchor}
          lineType={"curve"}
          element={{
            strokeWidth: nodesToHighlight[childId] ? 2 : 1,
            stroke: getNodeDrawProps(element, childId).color,
            dash: 0,
          }}
          curveStrength={50}
        />
      </Group>
    );
  }

  function renderAddNodeButtons(nodesData: NodeData[]) {
    if (!dragging && mySelectedNodes.length == 1 && editable) {
      let id = utils.parseNodeId(mySelectedNodes[0]);
      if (!id) return null;
      let nodeId = "",
        x = 0,
        y = 0;
      if (id.isRoot) {
        (x = 0), (y = 0);
        nodeId = "root";
      } else {
        const node = nodesData.find((node) => node.id == id!.nodeId);
        if (!node) return null;
        nodeId = node.id;
        x = node.x;
        y = node.y;
      }

      let leaves = layoutUtil.getNodeSocket("toLeaves", AddNodeButtonDist);
      let after = layoutUtil.getNodeSocket("siblingAfter", AddNodeButtonDist);
      let before = layoutUtil.getNodeSocket("siblingBefore", AddNodeButtonDist);

      const addNodebuttons: React.ReactNode[] = [];

      function addButton(ofs: typeof leaves, relation: utils.Relationship) {
        addNodebuttons.push(
          <MindmapNewNodeButton
            key={"anchor add_button_" + nodeId + relation.toString()}
            position={{ x: x + ofs.x, y: y + ofs.y }}
            onClick={() => createNewNodeFromButton(id!.nodeId, relation)}
          />
        );
      }

      addButton(leaves, "child");
      nodeId != "root" && addButton(after, "sibling-after");
      nodeId != "root" && addButton(before, "sibling-before");
      return addNodebuttons;
    }
    return null;
  }

  return (
    <>
      <Group x={element.x + anchor.x} y={element.y + anchor.y}>
        {nodesData.map(renderNode)}
        {!isExporting && (
          <>
            {editable && renderAddNodeButtons(nodesData)}
            <Line ref={dropMarkRef} visible={false} points={[]} stroke="blue" strokeWidth={5} />
          </>
        )}
      </Group>
      <Node
        key={uniqueId}
        id={uniqueId}
        type={consts.CANVAS_ELEMENTS.ORG_CHART}
        isRoot={true}
        position={anchor}
        width={150}
        height={height}
        fieldsToShow={visibleFields}
        fieldsTitle={element.extraFields}
        showImage={withPicture}
        data={element.data.root}
        nodeDrawProps={getNodeDrawProps(element, "root")}
        // extra props
        nodeId={"root"}
        element={element}
        isSelected={mySelectedNodes.includes(uniqueId)}
        editingFieldId={editingFieldId}
        setEditingFieldId={setEditFieldWithTracking}
        onFieldUpdate={(fieldId: string, final: string, initial: string) =>
          patch(utils.updateFieldInNode, "root", fieldId, final)
        }
        isSidePanelOpenForMe={isEditorOpenForNode("root")}
        onOpenPanel={() => toggleEditor("root")}
        onClick={() => setNodeForSidePanel({ rootid: uniqueId, selectedNode: "root", editable })}
        onPhotoClick={openUploadModal && editable ? () => openUploadModal("root") : undefined}
        isExporting={isExporting}
      />
      {rootNode.directChildren > 0 &&
        renderCollapseButton({
          nodeId: "root",
          x: element.x + anchor.x + collapseButtonOffset.x,
          y: element.y + anchor.y + collapseButtonOffset.y,
          direct: rootNode.directChildren,
          total: rootNode.descendants,
          collapsed: rootNode.collapsed,
        })}
    </>
  );
}

export function OrgChart1({
  x,
  y,
  rootId,
  layout,
  data,
  orientation,
  nodeHeight,
  dragState,
  getEdgeDrawProps,
  renderNode,
  renderCollapseButton,
  renderAddNodeButtons,
}: {
  x: number;
  y: number;
  rootId: string;
  layout: any;
  data: any;
  orientation: OrgChartOrientation;
  nodeHeight: number;
  dragState?: any;
  getEdgeDrawProps: (childNodeId: string) => EdgeDrawProps;
  renderNode: (
    nodeId: string,
    x: number,
    y: number,
    height: number,
    data: any,
    isRoot: boolean,
    children?: React.ReactNode
  ) => React.ReactNode;
  renderCollapseButton: any;
  renderAddNodeButtons: any;
}) {
  const descendants = useMemo(() => {
    const root = hierarchy(layout[rootId], (d) => d.childrenIds?.map((id: any) => layout[id]));
    root.sum(() => 1); // this has the effect of counting descendants for every node (including the node also)
    let descCount: Record<string, [number, number]> = {};
    root.each((node) => (descCount[node.data.id] = [node.children?.length ?? 0, node.value! - 1]));
    return descCount;
  }, [layout]);
  const layoutUtil = treeUtils.TreeLayoutHelper.from(orientation, NodeWidth, nodeHeight, SeparationLayers);
  const convert = treeUtils.LayoutProps[orientation].convert;

  const the_tree = useMemo(() => {
    let root = hierarchy(layout[rootId], (d) =>
      !d.childrenIds || d.collapsed ? null : d.childrenIds.map((id: any) => layout[id])
    );
    const nodeSize = layoutUtil.getNodeSizeForD3tree();
    let treeLayoutMaker = tree<any>()
      .nodeSize(nodeSize)
      .separation(() => SeparationSiblings);
    return treeLayoutMaker(root);
  }, [layout, orientation, nodeHeight]);

  const edges: React.ReactNode[] = [];
  const nodes: React.ReactNode[] = [];
  const collapseButtons: React.ReactNode[] = [];

  // Rendering nodes (if root isn't collapsed)
  //
  // TODO: some of this stuff was written to emulate OrgChartCanvasElement component, but then that
  // changed, so this code is a relic of that time. It should be cleaned up.
  if (!the_tree.data.collapsed) {
    the_tree.eachAfter((node) => {
      if (!node.parent) return null; // root is drawn differently
      const myId = node.data.id; //utils.makeNodeId(rootid, node.data.id);
      let [x, y] = convert(node.x, node.y);
      let partOfDraggedSubtree = dragState?.descendants.includes(node.data.id),
        draggedNode = dragState?.id == node.data.id,
        isDragged = partOfDraggedSubtree || draggedNode,
        offsetX = isDragged ? dragState?.offsetX : 0,
        offsetY = isDragged ? dragState?.offsetY : 0;
      if (dragState) {
        if (partOfDraggedSubtree) {
          x += dragState.offsetX;
          y += dragState.offsetY;
        }
      }
      const [direct, total] = descendants[node.data.id];
      if (direct > 0 && renderCollapseButton) {
        const collapsed = !node.children;
        collapseButtons.push(renderCollapseButton(node.data.id, collapsed, x, y, direct, total));
      }

      const [parentX, parentY] = convert(node.parent.x, node.parent.y);
      const selfOfs = layoutUtil.getNodeSocket("toRoot", 1);
      const parentOfs = layoutUtil.getNodeSocket("toLeaves", 1);
      if (!draggedNode) {
        edges.push(
          <GhostConnector
            key={node.data.id}
            id={node.data.id}
            p1={{
              x: parentX + parentOfs.x + offsetX,
              y: parentY + parentOfs.y + offsetY,
              rotation: parentOfs.rotation as Degrees,
            }}
            p2={{ x: x + selfOfs.x, y: y + selfOfs.y, rotation: selfOfs.rotation as Degrees }}
            lineType={"curve"}
            element={getEdgeDrawProps(node.data.id)}
            curveStrength={50}
          />
        );
      }
      nodes.push(renderNode(myId, x, y, nodeHeight, data[node.data.id], false));
    });
  }

  if (descendants[rootId][0] > 0 && renderCollapseButton) {
    const collapsed = the_tree.data.collapsed;
    const [direct, total] = descendants[rootId];
    const center = convert(the_tree.x, the_tree.y);
    collapseButtons.push(renderCollapseButton(rootId, collapsed, center[0], center[1], direct, total));
  }
  const rootNode = renderNode(rootId, the_tree.x, the_tree.y, nodeHeight, data[the_tree.data.id], true);

  return (
    <>
      <Group x={x} y={y}>
        {edges}
        {nodes}
        {collapseButtons}
        {renderAddNodeButtons && renderAddNodeButtons()}
      </Group>
      {rootNode}
    </>
  );
}

export function orgChartTraits(element: OrgChartElement, nodeId: string): ITraits {
  let id = utils.parseNodeId(nodeId);
  if (!id) return {};
  const draw = getNodeDrawProps(element, id.nodeId);
  const color = draw.color;
  const itemView = element.viewType ?? OrgChartViewType.Default;
  let imageField = {
    id: PHOTO_ID,
    title: "Photo",
    type: "image",
    selected: itemView == OrgChartViewType.Default,
  };
  return {
    orgchartOrientation: element.orientation,

    // I'm using mindmap field picker, which expects 'selected' instead of 'hidden' :-(
    orgChartFields: [imageField].concat(
      Object.values(element.extraFields).map((field, index) => ({
        id: field.id,
        title: field.name || `Field ${index + 1}`,
        type: field.type,
        selected: !field.hidden,
      }))
    ),

    orgChartNodeColor: color,
    orgChartNodeStroke: draw,
    orgChartItemViewType: itemView,
  };
}

export function orgChartValidateTraits(element: OrgChartElement, trait: Trait, newValue: any) {
  if (trait == "orgChartFields") {
    // new value is an array of ids of the visible fields
    // we also have photo field there, which is held separately in the element.
    let { viewType = OrgChartViewType.Default, extraFields } = element;

    const withPhoto = newValue.indexOf(PHOTO_ID) != -1;
    if (withPhoto != (viewType == OrgChartViewType.Default)) {
      viewType = withPhoto ? OrgChartViewType.Default : OrgChartViewType.NoProfilePicture;
    }

    // the rest, we'll convert it to the format of element.fields, where 'hidden' reflects visibility
    // create a copy of element.extraField, reset all fields to hidden, and then
    // turn on just those given to us in newValue
    extraFields = structuredClone(extraFields);
    for (const key in extraFields) {
      extraFields[key].hidden = true;
    }
    for (const key of newValue) {
      if (key in extraFields) extraFields[key].hidden = false;
    }
    return { extraFields, viewType };
  } else if (trait == "orgChartNodeColor") {
    const { color } = parseColor(newValue); // this is to get just color value without opacity
    return color;
  } else {
    return newValue;
  }
}

export function orgChartNodeTraits(element: any, specificId: string): ITraits {
  const nodeId = utils.parseNodeId(specificId)!.nodeId;
  const draw = getNodeDrawProps(element, nodeId);
  const color = draw.color;

  return {
    orgChartNodeColor: color,
    orgChartNodeStroke: draw,
  };
}

export function orgChartNodeValidateTraits(element: any, trait: Trait, newValue: any) {
  if (trait == "orgChartNodeColor") {
    const { color } = parseColor(newValue); // this is to get just color value without opacity
    return color;
  }
  return newValue;
}

const MemoizedOrgChartCanvasElement = React.memo(OrgChartCanvasElement);
export default MemoizedOrgChartCanvasElement;
