import React, { useMemo, useRef, useState } from "react";
import { SyncService } from "frontend/services/syncService";
import Konva from "konva";
import { KonvaEventObject } from "konva/types/Node";
import { Circle, Group, Shape } from "react-konva";
import { Portal } from "react-konva-utils";
import consts from "shared/consts";
import { Connector, defaultShapeDimensions, LockType, Point } from "shared/datamodel/schemas";
import { getTransformParams } from "frontend/utils/node-utils";
import { arePointsEqual, IRect, isPointInRect, PointInCanvas, viewportToStage } from "frontend/utils/math-utils";
import * as utils from "frontend/utils/connector-utils";
import { ITraits, Trait } from "../elements-toolbar/elements-toolbar-types";
import { useAtomValue } from "jotai";
import { isExportingAtom, isThumbnailExportModeAtom, syncServiceAtom } from "state-atoms";
import { RW } from "shared/datamodel/replicache-wrapper/mutators";
import { getElementTypeForId } from "./canvas-elements-utils";
import { ConnectorTextLabel, getFontSize } from "../text-element";
import { useKeyPress } from "react-use";
import { useCanvasElementById } from "frontend/subscriptions";
import { ITransformHooks } from "frontend/hooks/use-transform-hooks";
import { ShapeSocketColor } from "../frontend-consts";
import { nanoid } from "nanoid";
import * as PointUtils from "frontend/utils/point-utils";
import type { Degrees } from "frontend/utils/transform";
import Transform, { konvaTransformForElement, PointAndDirection } from "frontend/utils/transform";
import { initArray, minIndexBy, noop } from "frontend/utils/fn-utils";
import { enablePatches, produce } from "immer";
import * as attachUtils from "frontend/utils/connector-attach-utils";
import pointAt from "frontend/geometry/pointAt";
import normalAt from "frontend/geometry/normalAt";
import {
  ConnectorEndpoint,
  fixMode,
  computeConnectorDrawingData,
} from "frontend/canvas-designer-new/elements/connectors/connector-component-utils";
import ConnectorLine from "frontend/canvas-designer-new/elements/connectors/connector-line";

enablePatches();

type OnChangeElementFn = (props: any, undoConfig: { shouldAdd: boolean; previousProps?: any }) => void;

type AnchorDragState = {
  x: number;
  y: number;
  index: number;
  snapToPoint?: PointAndDirection;
  snapToShape?: string;
};

type AnchorFrameData = null | {
  frameId: string;
  pos: Point;
};

enum AnchorIndex {
  Start,
  End,
}

const DATA_NOT_LOADED = "not loaded";
const HANDLE_COLOR = "#00A1FF";
const HANDLE_COLOR_SEMI_TRANSPARENT = "#00A1FF66";

type Point2 = readonly [number, number];

export const ConnectorCanvasElement = React.memo(
  ({
    uniqueId,
    element,
    mutation,
    isSelected,
    isEditing,
    layer,
    onElementsMutationEnded,
    changeElement,
    changeAnyElement,
    isSelectable,
    allElements,
  }: {
    uniqueId: string;
    element: Connector;
    mutation: ITransformHooks;
    isSelected: boolean;
    isEditing: boolean;
    layer?: Konva.Layer;
    onElementsMutationEnded: any;
    changeElement: OnChangeElementFn;
    changeAnyElement: (id: string, props: any, previousProps?: any) => void;
    isSelectable: boolean;
    allElements?: any;
  }) => {
    // Atoms
    const syncService = useAtomValue(syncServiceAtom) as SyncService<RW>;
    const isExportingCanvas = useAtomValue(isExportingAtom);

    // Hooks
    const anchorsFrameIds = useRef<[AnchorFrameData, AnchorFrameData]>([null, null]);
    const [dragState, setDragState] = useState<any>(null);
    const undoState = useRef<any>();

    // Subscriptions to replicache
    const connectedShapes = element.connectedShapes;
    const firstShape = allElements
      ? allElements[connectedShapes[0]?.id as any]
      : useCanvasElementById(syncService.getReplicache()!, connectedShapes[0]?.id ?? "", DATA_NOT_LOADED, [
          connectedShapes[0]?.id,
        ]);
    const secondShape = allElements
      ? allElements[connectedShapes[1]?.id as any]
      : useCanvasElementById(syncService.getReplicache()!, connectedShapes[1]?.id ?? "", DATA_NOT_LOADED, [
          connectedShapes[1]?.id,
        ]);
    const getShape = (index: number) => (index == 0 ? firstShape : secondShape);

    const locked = (element.lock ?? 0) != LockType.None;
    const drawHandles = isSelected && !locked && !isExportingCanvas;

    // Functions

    const toStage = layer ? viewportToStage(layer.getAbsoluteTransform()) : noop;

    function viewportToElement(pos: Point) {
      let result = toStage(pos);
      const tr = getTransformParams("connector", element);
      return Transform.InvPoint(tr, result, result);
    }

    function elementPointToCanvasPoint(p: Point, out?: Point) {
      const tr = getTransformParams("connector", element);
      return Transform.Point(tr, p, out);
    }

    function canvasPointToElementPoint(p: Point, out?: Point) {
      const tr = getTransformParams("connector", element);
      return Transform.InvPoint(tr, p, out);
    }

    function dragAndSnap(index: number, point: Point, e: KonvaEventObject<MouseEvent>, cache?: WeakMap<any, any>) {
      let newDragState: AnchorDragState, snappedShape: any;
      const otherIndex = 1 - index;

      let snap = attachUtils.checkSnapping(e.evt, e.target.getPosition(), e.target.getStage()!, layer!, cache); // TODO: can this return data in format of 'candidate' already?

      let candidate: any = null;
      if (snap) {
        if (snap instanceof attachUtils.SnapToAnchor) {
          const otherAnchor = elementPointToCanvasPoint((otherIndex == 0 ? p1 : p2) as ConnectorEndpoint);
          const { node, rect, distance } = snap;
          const insideShape = isPointInRect(otherAnchor, rect) && isPointInRect(e.target.getPosition(), rect);

          if (insideShape) {
            if (distance < utils.DistanceToSnapToAnchorFromInside_px) {
              candidate = {
                node,
                side: snap.side,
                position: snap.position,
                anchors: snap.anchors,
                rotation: snap.rotation,
              };
            }
          } else {
            const smallShape =
              rect.width * rect.height < defaultShapeDimensions.width * defaultShapeDimensions.height * 3;
            if (smallShape) {
              candidate = {
                node,
                side: snap.side,
                position: snap.position,
                anchors: snap.anchors,
                rotation: snap.rotation,
              };
            } else if (distance < utils.DistanceToSnapToAnchorFromOutside_px) {
              candidate = {
                node,
                side: snap.side,
                position: snap.position,
                anchors: snap.anchors,
                rotation: snap.rotation,
              };
            }
          }
        } else if (snap instanceof attachUtils.SnapToOutline) {
          candidate = {
            node: snap.node,
            side: "outline",
            position: snap.xy,
            rotation: snap.rotation || 0,
            t: snap.t,
            anchors: null,
          };
        } else if (snap instanceof attachUtils.SnapJustShowAnchors) {
          candidate = { anchors: snap.anchors };
        } else {
          console.warn("unknown ret value", snap);
        }
      }

      // Internal drag state
      newDragState = { index, x: point.x, y: point.y } as AnchorDragState;

      snappedShape = { id: null, element: null };

      // don't allow connection to same place as other endpoint)
      if (candidate?.node) {
        if (
          candidate.node.id() == element.connectedShapes[otherIndex]?.id &&
          candidate.side != "outline" &&
          candidate.side == fixMode(element.anchorsMode?.[otherIndex])
        ) {
          candidate = null;
        }
      }

      if (candidate?.node) {
        // The attached shape update
        const attachedConnectors = structuredClone(candidate.node.attrs.element.attachedConnectors ?? {});
        attachedConnectors[uniqueId] = { lineId: uniqueId };
        snappedShape.id = candidate.node.id();
        snappedShape.element = { attachedConnectors };

        // update the drag state
        let pointInElementSpace = canvasPointToElementPoint({ ...candidate.position }) as any;
        newDragState.snapToPoint = { ...pointInElementSpace, rotation: candidate.rotation };
        newDragState.snapToShape = candidate.node.id();
      }
      // element is readonly, we have to create copies
      // TODO: get patch and return that instead of the whole element
      let { points, connectedShapes, anchorsMode, point1_t, point2_t } = produce(element, (draft) => {
        draft.points[index] = point;
        draft.connectedShapes[index] = candidate?.node ? { id: candidate.node.id(), type: "" } : { id: "", type: "" };
        draft.anchorsMode ??= ["n/a", "n/a"];
        draft.anchorsMode[index] = candidate?.side ?? "n/a";
        if (candidate?.side == "outline") {
          index == 0 ? (draft.point1_t = candidate.t) : (draft.point2_t = candidate.t);
        }
      });
      let newConnectorState = structuredClone({ points, connectedShapes, anchorsMode, point1_t, point2_t });

      return {
        newDragState,
        newAnchorPoints: candidate?.anchors,
        newConnectorState,
        snappedShape,
        highlightedSocket: candidate?.position,
      } as const;
    }

    const onDragStart = (index: number, e: KonvaEventObject<MouseEvent>) => {
      const shape = getShape(index);
      const prevId = element.connectedShapes[index]?.id;

      if (Boolean(prevId) != Boolean(shape)) {
        // We're never supposed to get here. If we drew the connector then we surely
        // got the data about the shape, so we should have both of them.
        console.warn("Connector didn't get from replicache its connected shape");
      }

      // duplicate the shape we're connected to state for undo information
      let attachedConnectors = prevId && shape && { ...shape.attachedConnectors };

      let p = e.target.getPosition(); // position on screen of control point
      const { x, y } = viewportToElement(p); // position relative to element
      setDragState({
        anchorDragState: {
          index,
          x,
          y,
        },
      });
      anchorsFrameIds.current[index] = null; // mark us as detachd from frame
      undoState.current = {
        connector: structuredClone(element),
        shape: {
          id: prevId,
          element: attachedConnectors ? { attachedConnectors } : undefined,
        },
      };
      // TODO: this 2 calls to replicache can be unified into 1 call
      // detach from shape
      const newConnectorData = structuredClone(element);
      newConnectorData.connectedShapes[index] = { id: "", type: "" };
      changeElement(newConnectorData, { shouldAdd: false });
      // detach shape from us
      if (attachedConnectors && prevId) {
        delete attachedConnectors[uniqueId];
        changeAnyElement(prevId, { attachedConnectors });
      }
    };

    const onDragEnd = (anchorIndex: number, mousePosition: Point, e: KonvaEventObject<MouseEvent>) => {
      // This function changes the connector element in reflect, and registers an undo action.
      // There's a case we don't want to do that: if e.evt==null, because that means
      // the drag operation was cancelled because space was pressed.

      // if !e.evt - dragging was cancelled because space was pressed
      // if we should disable snapping, we don't do anything since the connector was moved already in dragmove
      if (e.evt) {
        const point = canvasPointToElementPoint(mousePosition);
        let cache = e.target.attrs.cache;
        let { newConnectorState, snappedShape } = dragAndSnap(anchorIndex, point, e, cache);

        // TODO: don't apply the change if there's no difference between the new-state and undo-state
        // TODO: keep the whole element in undoState, and use immer to do the change.
        // then just check if patch.length!=0
        let prev = undoState.current;
        let cur = {
          connector: newConnectorState,
          shape: snappedShape,
        };
        let prevState = new Map();
        let newState = new Map();
        prevState.set(uniqueId, prev.connector);
        newState.set(uniqueId, cur.connector);
        if (prev.shape.id) {
          prevState.set(prev.shape.id, prev.shape.element);
          const node = layer!.findOne("." + prev.shape.id);
          if (node?.attrs.element) newState.set(prev.shape.id, node.attrs.element);
        }
        // if user doesn't want to snap, we continue to register the undo action,
        // just without snapping
        const disableSnapping = utils.shouldDisableConnectorSnapping(e.evt);
        if (!disableSnapping && cur.shape.id) {
          newState.set(cur.shape.id, cur.shape.element);
        }
        onElementsMutationEnded(newState, prevState);
      }
      delete e.target.attrs.cache;
      setDragState(null);
      undoState.current = null;
    };

    const onDragMove = (index: number, mousePosition: Point, e: KonvaEventObject<MouseEvent>) => {
      if (!e.evt) {
        // dragging was cancelled because space was pressed
        return;
      }

      // TODO: according to the profiler this function punches above its weight !
      // I can cache the transform for the connector, it almost certainly doesn't change when moving the endpoints
      let point = canvasPointToElementPoint(mousePosition);

      // support snapping to 45 degrees on shift
      if (e.evt.shiftKey) {
        let otherAnchor = index == 1 ? (p1 as any) : (p2 as any);
        if (element.innerPoints?.length) {
          otherAnchor = element.innerPoints[index == 1 ? element.innerPoints.length - 1 : 0];
        }
        point = utils.snapConnectorRotation(point, otherAnchor);
        // set the position of the actual anchor element
        e.target.setPosition(elementPointToCanvasPoint(point));
      }

      if (utils.shouldDisableConnectorSnapping(e.evt)) {
        setDragState({ anchorDragState: { index, x: point.x, y: point.y } as AnchorDragState });
        return;
      }
      let cache = (e.target.attrs.cache ??= new WeakMap<any, any>());
      let { newDragState, newConnectorState, newAnchorPoints, highlightedSocket } = dragAndSnap(index, point, e, cache);
      if (newDragState.snapToPoint) {
        const elementPos = newDragState.snapToPoint;
        const p = elementPointToCanvasPoint(elementPos);
        e.target.setAttrs(p);
      }
      let anchorDragState = newDragState;
      if (newConnectorState) {
        changeElement(newConnectorState, { shouldAdd: false });
      } else {
        console.warn("no newConnectorState");
      }
      const shapeStandardAnchors = newAnchorPoints ? Object.values(newAnchorPoints) : null;
      if (!newDragState?.snapToPoint) {
        highlightedSocket = null;
      }
      setDragState({ anchorDragState, shapeSockets: shapeStandardAnchors, highlightedSocket });
    };

    // TODO: index is too generic. change to anchorIndex, endpointIndex, "start"|"end"... something like that (start-of-line, end-of-line)
    // connector-endpoint...
    function calcAnchorPosition(index: number): ConnectorEndpoint | "dont-show" | "not-loaded" {
      if (dragState?.anchorDragState?.index == index) {
        return dragState?.anchorDragState.snapToPoint ?? dragState.anchorDragState;
      }
      if (!connectedShapes[index]?.id) {
        return element.points[index];
      }
      const shape = getShape(index);
      if (shape == null || shape.hidden || shape.containerId) {
        return "dont-show" as const;
      }
      if (shape == DATA_NOT_LOADED) {
        return "not-loaded" as const;
      }
      let mode = fixMode(element.anchorsMode?.[index]);
      if (mode != "n/a") {
        const elementType = getElementTypeForId(connectedShapes[index]!.id);
        // TODO: can delete this - since uvToXY repeats this code anyway
        let anchorPoints = utils.getAnchorPoints(elementType, shape);
        if (!anchorPoints) {
          return "dont-show";
        }
        if (elementType == consts.CANVAS_ELEMENTS.FILE) {
          const width = anchorPoints.right.x - anchorPoints.left.x;
          const height = anchorPoints.buttom.y - anchorPoints.top.y;
          if ((width == 0 || height == 0) && layer) {
            let node = layer.findOne("#" + connectedShapes[index]!.id);
            anchorPoints = utils.getAnchorPointsFromNode(node);
          }
        }
        if (!anchorPoints) {
          return "dont-show";
        }
        const t = index == 0 ? element.point1_t : element.point2_t;
        let result: any = null;
        if (mode != "outline") {
          const anchors = utils.getAnchorPoints(elementType, shape);
          if (anchors) {
            result = anchors[mode as keyof typeof anchors];
            const r = canvasPointToElementPoint(result);
            result = { ...r, rotation: result.rotation };
          }
        } else {
          const rotation = normalAt(elementType, shape)(t);
          const xy = { ...canvasPointToElementPoint(pointAt(elementType, shape)(t)), rotation: rotation as Degrees };
          result = xy;
        }
        return result;
      }
      console.warn("connector attached to shape, but without connection side", element);
      return "dont-show";
    }

    function findFrameForPosition(position: PointInCanvas, layer: Konva.Layer) {
      if (!layer) {
        return;
      }
      const stage = layer.getStage();
      const frames = layer.find(
        (i: any) =>
          i.attrs.isFrame && !i.attrs.isFrameTitle && isPointInRect(position, i.getClientRect({ relativeTo: stage }))
      );
      // select the smallest frame that contains the position
      let min = Number.MAX_SAFE_INTEGER,
        best: any = null;
      for (const frame of frames) {
        const rect = frame.getClientRect();
        const area = rect.width * rect.height;
        if (area < min) {
          min = area;
          best = frame;
        }
      }
      return best?.id() ?? "";
    }

    // Before we render we need to get the location of the shapes we're connected to.
    let p1 = calcAnchorPosition(AnchorIndex.Start);
    let p2 = calcAnchorPosition(AnchorIndex.End);

    //////////////////////////////////////////////////
    // Beyond this point - don't write hooks !!!!
    //////////////////////////////////////////////////

    // we don't show our connector if it's attached to a deleted shape
    if (p1 == "dont-show" || p2 == "dont-show") return null;

    // some old canvases didn't have lineType in some connectors :-O
    // I have no idea how it happened, but the element can't work without it!!
    if (!element.lineType) return null;

    if (p1 == "not-loaded" || p2 == "not-loaded") {
      // If we're connected to another shape we and don't have its info yet,
      // we don't render but instead we return an empty Group with our key,
      // just so react won't unmount the component.
      // note: this happens on initial load, after connecting, maybe other cases
      return (
        <Group
          key={uniqueId}
          isCanvasElement={false}
          isSelectable={isSelectable}
          isConnectable={false}
          isConnector={false}
          isFrame={false}
        />
      );
    }

    const data = computeConnectorDrawingData(p1, p2, element);

    if (element.lineType == "elbow") {
      // convert from absolute rotation to relative rotation, since I don't
      // want the elbow line to change shape when rotating
      // This is needed because elbow lines still use old drawing algorithm

      // TODO: supporting snapping to anywhere on the outline means that rotation isn't just 0,90,180,270
      // it's easy for lines and curves, but for elbow lines it's might be more complicated.
      if (p1.rotation && element.rotate) {
        p1.rotation = (p1.rotation - element.rotate) as Degrees;
      }
      if (p2.rotation && element.rotate) {
        p2.rotation = (p2.rotation - element.rotate) as Degrees;
      }
    }
    function refreshFrameId(index: AnchorIndex, worldPos: PointInCanvas) {
      const cached = anchorsFrameIds.current[index];
      if (cached == null || !arePointsEqual(cached.pos, worldPos)) {
        let id;
        if (element.connectedShapes[index]?.id) {
          id = getShape(index)?.frameId;
        } else {
          id = layer ? findFrameForPosition(worldPos, layer) : undefined;
        }
        if (id != undefined) {
          anchorsFrameIds.current[index] = { pos: worldPos, frameId: id };
        }
      }
    }

    // During rendering we recheck the frames for both anchor.
    // It might seem enough to check this on drag-end of the anchors, but also
    // the connected shapes might have moved and we need to check again.
    const startPoint = { ...elementPointToCanvasPoint(p1), rotation: p1.rotation };
    const endPoint = { ...elementPointToCanvasPoint(p2), rotation: p2.rotation };
    refreshFrameId(AnchorIndex.Start, startPoint as unknown as PointInCanvas);
    refreshFrameId(AnchorIndex.End, endPoint as unknown as PointInCanvas);

    const firstFid = anchorsFrameIds.current[AnchorIndex.Start]?.frameId ?? "";
    const secondFid = anchorsFrameIds.current[AnchorIndex.End]?.frameId ?? "";
    // a connector is in a frame, if both its endpoints are in the same frame
    if (firstFid == secondFid && firstFid != "") {
      if (element.frameId != firstFid) {
        changeElement({ frameId: firstFid }, { shouldAdd: false });
      }
    } else {
      if (!!element.frameId) {
        changeElement({ frameId: "" }, { shouldAdd: false });
      }
    }

    return (
      <>
        <Group
          id={uniqueId}
          name={uniqueId}
          key={uniqueId}
          type={consts.CANVAS_ELEMENTS.CONNECTOR}
          x={element.x}
          y={element.y}
          scaleX={element.scaleX}
          scaleY={element.scaleY}
          rotation={element.rotate}
          isSelectable={isSelectable}
          startpoint={p1}
          endpoint={p2}
          {...mutation.getCallbacks()}
          isCanvasElement={true}
          isConnectable={false}
          isConnector={true}
          isDraggable={true}
          isFrame={false}
          isTaskConvertible={false}
          element={element}
        >
          <ConnectorLineAndText
            p1={p1 as any}
            p2={p2 as any}
            element={element}
            data={data}
            isEditing={isEditing}
            onChangeElement={changeElement}
          />
        </Group>
        <Portal enabled selector=".Overlay">
          {drawHandles && (
            <>
              <AnchorPoints
                start={startPoint}
                end={endPoint}
                onDragStart={onDragStart}
                onDragMove={onDragMove}
                onDragEnd={onDragEnd}
              />
              <ConnectorShapeHandles
                data={data}
                element={element}
                onChangeElement={changeElement}
                p1={p1 as any}
                p2={p2 as any}
              />
            </>
          )}
          {dragState?.shapeSockets && <ConnectionPoints anchorPoints={dragState.shapeSockets} />}
          {dragState?.highlightedSocket && (
            <SingleConnectionPoint point={dragState.highlightedSocket} highlight={true} />
          )}
        </Portal>
      </>
    );
  }
);

/**
 * This function prepares an inverted clip area - a "hole" where nothing is drawn,
 * unlike normal clip that defines the area where drawing happens.
 * The trick is to to clip a large area, then clip an "anti" rect inside (drawn counter-clockwise)
 * and use the 'evenodd' clip rule
 * I owe this trick to https://stackoverflow.com/questions/6271419/how-to-fill-the-opposite-shape-on-canvas
 * @param context
 * @param clipRect
 * @param scaleX   - x scale for the clip rect
 * @param scaleY   - y scale for the clip rect
 * @param rotate   - optinal rotation for the clip rect (rotated around its center)
 */

function ConnectorLineAndText({
  p1,
  p2,
  element,
  data,
  isEditing,
  onChangeElement,
}: {
  p1: PointAndDirection;
  p2: PointAndDirection;
  element: Connector;
  data: utils.SimpleConnectorData;
  isEditing: boolean;
  onChangeElement?: OnChangeElementFn;
}) {
  const isThumbnailExport = useAtomValue(isThumbnailExportModeAtom);
  const [textSize, setTextSize] = useState<{ width: number; height: number }>({ width: 0, height: 48 });
  const updateTextSize = (w: number, h: number) => {
    if (w != textSize.width || h != textSize.height) setTextSize({ width: w, height: h });
  };
  const { scaleX = 1, scaleY = 1, textLocation = 0.5 } = element;
  const undoRef = useRef<any>(null);

  function computeTextPosition() {
    if (element.lineType != "elbow") {
      if (data.segments.length == 0) {
        console.warn("don't have data.segments for connector text label");
        return [0, 0];
      }
      let metrics = new utils.PathMetrics(data);
      const anchorPoint = metrics.getPointAlongPath(textLocation);
      return anchorPoint;
    }
    const segments = new utils.RecordCanvasCmds();
    let renderer = utils.getElBowForBackCompat({
      ...element,
      start: p1,
      end: p2,
    });
    const isConnected = !!element.anchorsMode && element.anchorsMode.every((x) => fixMode(x) != "n/a");
    renderer(segments, null, [p1, p2], isConnected, element.anchorsMode! as any);
    return utils.evaluatePointInCurve(segments.segments)(textLocation);
  }

  function getEvaluator(): (t: number) => Point2 {
    if (element.lineType == "elbow") {
      const segments = new utils.RecordCanvasCmds();
      let renderer = utils.getElBowForBackCompat({
        ...element,
        start: p1,
        end: p2,
      });
      const isConnected = !!element.anchorsMode && element.anchorsMode.every((x) => fixMode(x) != "n/a");
      renderer(segments, null, [p1, p2], isConnected, element.anchorsMode! as any);
      return utils.evaluatePointInCurve(segments.segments);
    } else {
      //TODO: path metrics is a wasteful way to calculate points along the path.
      let metrics = new utils.PathMetrics(data);
      return metrics.getPointAlongPath.bind(metrics);
    }
  }

  // TODO: when moving the text label we update element.textLocation and then recompute
  // textLabelPosition. This is very wasteful since we recompute points along the path!
  const textLabelPosition = useMemo(computeTextPosition, [
    element.lineType,
    element.textLocation,
    element.text,
    p1,
    p2,
    data,
  ]);

  const hasText = element.text?.length || isEditing;

  let clipRect: undefined | IRect = undefined;
  if (hasText) {
    clipRect = {
      x: textLabelPosition[0] - textSize.width / scaleX / 2,
      y: textLabelPosition[1] - textSize.height / scaleY / 2,
      width: textSize.width / scaleX,
      height: textSize.height / scaleY,
    };
  }

  const onTextDrag = (e: Konva.KonvaEventObject<DragEvent>) => {
    //TODO: when user starts pressing space she's moving the stage. abort drag of element
    //    if (isSpacePressed) {
    //      e.target.stopDrag();
    //      return;
    //    }
    const type = (e as any).type;
    if (type == "dragstart") {
      const getpoint = getEvaluator();
      const curvePoints = initArray(1001, (n) => getpoint(n / 1000));
      undoRef.current = { textLocation, curvePoints };
    } else if (type == "dragend") {
      // TODO: I can calculate the closest point on the path to the mouse position here.
      // in dragmove I go for faster solution, but here I can go for best
      onChangeElement &&
        onChangeElement(
          { textLocation: element.textLocation },
          {
            shouldAdd: true,
            previousProps: {
              textLocation: undoRef.current.textLocation,
            },
          }
        );
      undoRef.current = null;
    } else if (type == "dragmove") {
      const mousePos = e.currentTarget.position();
      const distanceToMouse = (p: Point2) => PointUtils.lenSqr({ x: p[0], y: p[1] }, mousePos);
      const curvePoints = undoRef.current.curvePoints;
      let [, indexClosestCurvePoint] = minIndexBy(distanceToMouse, curvePoints);
      const t = indexClosestCurvePoint / (curvePoints.length - 1);
      onChangeElement && onChangeElement({ textLocation: t }, { shouldAdd: false });
    }
  };

  return (
    <>
      <ConnectorLine p1={p1} p2={p2} element={element} data={data} clipRect={clipRect} />
      {!isThumbnailExport && textLabelPosition && (
        <ConnectorTextLabel
          element={element}
          updateText={(initial: string, text: string) => {
            onChangeElement && onChangeElement({ text }, { shouldAdd: true, previousProps: { text: initial } });
          }}
          position={{ x: textLabelPosition[0], y: textLabelPosition[1] }}
          textSize={textSize}
          updateTextSize={updateTextSize}
          isEditing={isEditing}
          onTextDrag={onTextDrag}
        />
      )}
    </>
  );
}

function drawScaledCircle(context: any, shape: Konva.Shape) {
  const stage = shape.getStage();
  if (!stage) return;

  // Get the current scale of the stage
  const scale = stage.scaleX();

  // Adjust the radius and strokeWidth based on the scale
  const adjustedRadius = shape.attrs.radius / scale;

  // Begin drawing
  context.beginPath();
  context.arc(0, 0, adjustedRadius, 0, Math.PI * 2, false);

  // Complete the shape
  context.fillStrokeShape(shape);
}

function AnchorPoints({
  start,
  end,
  onDragStart,
  onDragMove,
  onDragEnd,
}: {
  start: Point;
  end: Point;
  onDragStart: (index: number, evt: KonvaEventObject<MouseEvent>) => void;
  onDragMove: (index: number, mousePosition: PointInCanvas, evt: KonvaEventObject<MouseEvent>) => void;
  onDragEnd: (index: number, mousePosition: PointInCanvas, evt: KonvaEventObject<MouseEvent>) => void;
}) {
  const isSpacePressed = useKeyPress("space")[0];

  const renderAnchor = (anchor: Point, index: AnchorIndex) => {
    return (
      <Group
        x={anchor.x}
        y={anchor.y}
        name="connector-anchor"
        key={index}
        index={index}
        draggable
        onDragStart={(e) => {
          onDragStart(e.target.attrs.index, e);
        }}
        onDragEnd={(e) => {
          if (!e.evt) {
            // dragging was cancelled because space was pressed
            return;
          }
          let point = e.target.getPosition() as PointInCanvas;
          onDragEnd(e.target.attrs.index, point, e);
        }}
        onDragMove={(e) => {
          if (isSpacePressed) {
            e.target.stopDrag();
            return;
          }
          let index = e.target.attrs.index;
          let point = e.target.getPosition() as PointInCanvas;
          onDragMove(index, point, e);
        }}
      >
        <Shape
          fill="white"
          stroke={HANDLE_COLOR}
          strokeWidth={2}
          strokeScaleEnabled={false}
          radius={utils.ConnectorTransformPointRadius}
          sceneFunc={drawScaledCircle}
        />
      </Group>
    );
  };
  return (
    <>
      {renderAnchor(start, AnchorIndex.Start)}
      {renderAnchor(end, AnchorIndex.End)}
    </>
  );
}

function SingleConnectionPoint({ point, highlight }: { point: Point; highlight: boolean }) {
  return (
    <Shape
      x={point.x}
      y={point.y}
      listening={false}
      fill={highlight ? ShapeSocketColor : "white"}
      stroke={highlight ? "white" : ShapeSocketColor}
      strokeWidth={2}
      strokeScaleEnabled={false}
      radius={utils.ConnectorSnapPointRadius}
      sceneFunc={drawScaledCircle}
    />
  );
}

function ConnectionPoints({ anchorPoints }: { anchorPoints: Point[] }) {
  return (
    <>
      {anchorPoints.map((p, index) => (
        <SingleConnectionPoint point={p} highlight={false} key={"anchor connection-point" + index} />
      ))}
    </>
  );
}

function ConnectorShapeHandles({
  p1,
  p2,
  data,
  element,
  onChangeElement,
}: {
  p1: PointAndDirection;
  p2: PointAndDirection;
  data: utils.SimpleConnectorData;
  element: Connector;
  onChangeElement?: OnChangeElementFn;
}) {
  if (element.lineType == "elbow") return null;

  const isSpacePressed = useKeyPress("space")[0];
  const transform = konvaTransformForElement(element);
  const invTr = transform.copy().invert();

  const points = useMemo(() => {
    let metrics = new utils.PathMetrics(data);
    let result = new Array<Point & { id: string; real: boolean }>();
    let pathPointIndex = 0;
    for (const [x, y, real] of metrics.pathPointsAndPossibleBreakpoints()) {
      let p = transform.point({ x, y });
      result.push({ x: p.x, y: p.y, real, id: real ? element.innerPoints![pathPointIndex++].id : nanoid(10) });
    }
    return result;
  }, [p1, p2, data, transform]);

  return (
    <>
      {points.map((point) => (
        <Circle
          key={point.id}
          id={point.id}
          name="connector-shape-handle anchor"
          x={point.x}
          y={point.y}
          real={point.real}
          draggable={true}
          undoData={null}
          onDragStart={(e) => {
            e.target.setAttr("undoData", element.innerPoints ?? []);
            if (e.target.attrs.real == false) {
              let myindex = points.findIndex((p) => p.id == e.target.attrs.id);
              const pos = invTr.point(e.target.position());
              if (element.innerPoints) {
                let newInnerPoints = element.innerPoints.slice();
                let index = Math.floor(myindex / 2);
                newInnerPoints.splice(index, 0, {
                  x: pos.x,
                  y: pos.y,
                  id: e.target.attrs.id,
                });
                onChangeElement && onChangeElement({ innerPoints: newInnerPoints }, { shouldAdd: false });
              } else {
                let newInnerPoints = [{ x: pos.x, y: pos.y, id: point.id }];
                onChangeElement && onChangeElement({ innerPoints: newInnerPoints }, { shouldAdd: false });
              }
            }
          }}
          onDragEnd={(e) => {
            if (!e.evt) {
              return; // stopped dragging because of space.
            }
            onChangeElement &&
              onChangeElement(
                { innerPoints: element.innerPoints },
                {
                  shouldAdd: true,
                  previousProps: { innerPoints: e.target.attrs.undoData },
                }
              );
          }}
          onDragMove={(e) => {
            if (isSpacePressed) {
              e.target.stopDrag();
              return;
            }
            const i = element.innerPoints?.findIndex((p) => p.id == e.target.attrs.id);
            if (i == undefined || i == -1) {
              // it's possible we didn't find this point in element.innerPoints,
              // because we didn't get from replicache the update to the element.
              // I've seen this happen, it lasts for a few ms, and then the update comes in.
              return;
            }
            const pos = invTr.point(e.target.position());
            let newPoints = element.innerPoints!.slice();
            newPoints[i] = { ...newPoints[i], x: pos.x, y: pos.y };
            onChangeElement && onChangeElement({ innerPoints: newPoints }, { shouldAdd: false });
          }}
          onDblClick={(e: any) => {
            if (e.target.attrs.real) {
              let newpoints = element.innerPoints!.filter((p) => p.id != e.target.attrs.id);
              onChangeElement &&
                onChangeElement(
                  { innerPoints: newpoints },
                  { shouldAdd: true, previousProps: { innerPoints: element.innerPoints } }
                );
            }
          }}
          fill={point.real ? "white" : HANDLE_COLOR_SEMI_TRANSPARENT}
          stroke={point.real ? HANDLE_COLOR : "white"}
          radius={utils.ConnectorTransformPointRadius}
          sceneFunc={drawScaledCircle}
        />
      ))}
    </>
  );
}

export function connectorTraits(element: Connector): ITraits {
  return {
    lineColor: element.stroke,
    connectorLineWidth: element.strokeWidth,
    dash: element.dash ?? 0,

    firstEndpoint: element.pointerStyles?.[1] == "arrow",
    lineStyle: element.lineType,
    secondEndpoint: element.pointerStyles?.[0] == "arrow",

    textColor: element.textColor ?? consts.DEFAULTS.TEXT_COLOR,
    fontProps: element.fontProps ?? 0,
    fontSize: getFontSize(element),
    font: element.font ?? consts.DEFAULTS.FONT,
  };
}

export function connectorValidateTrait(element: Connector, trait: Trait, value: any) {
  if (trait == Trait.firstEndpoint) {
    let pointerStyles = [...element.pointerStyles];
    pointerStyles[1] = value ? "arrow" : "none";
    return { pointerStyles };
  }
  if (trait == Trait.secondEndpoint) {
    let pointerStyles = [...element.pointerStyles];
    pointerStyles[0] = value ? "arrow" : "none";
    return { pointerStyles };
  }
  return value;
}
