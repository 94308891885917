import { FeatureFlagsKeys, DefaultFeatureFlagsValues, FeatureFlagValue } from "frontend/hooks/use-feature-flag/flags";
import {
  useFeatureFlagPayload as usePosthogFFPayload,
  useFeatureFlagVariantKey,
  useFeatureFlagEnabled,
} from "posthog-js/react";
import posthog from "posthog-js";

export const useFeatureFlag = <K extends FeatureFlagsKeys>(flag: K): FeatureFlagValue<K> => {
  const value = useFeatureFlagEnabled(flag) ?? DefaultFeatureFlagsValues[flag];
  return value as FeatureFlagValue<K>;
};

export const useFeatureFlagPayload = <K extends FeatureFlagsKeys>(flag: K): FeatureFlagValue<K> => {
  const value = usePosthogFFPayload(flag) ?? DefaultFeatureFlagsValues[flag];
  return value as FeatureFlagValue<K>;
};

export const useFeatureFlagVariant = (flag: FeatureFlagsKeys) => {
  return useFeatureFlagVariantKey(flag);
};

export const getFeatureFlag = <K extends FeatureFlagsKeys>(flag: K): FeatureFlagValue<K> => {
  const value = posthog.isFeatureEnabled(flag) ?? DefaultFeatureFlagsValues[flag];
  return value as FeatureFlagValue<K>;
};
