import Konva from "konva";
import type { Degrees } from "./transform";
import { DistanceToShowAnchors_px, DistanceToSnapToAnchorFromOutside_px, getAnchorPointsFromNode, StandardAnchorPoints, } from "./connector-utils";
import { canvasPointToNormalizedPoint, isNormalizedPointInsideElement } from "./node-utils";
import { minIndexBy, minItemBy } from "frontend/utils/fn-utils";
import { parseStrokeWidth } from "shared/util/utils";
import { defaultShapeStrokeWidth } from "shared/datamodel/schemas";
import { getElementTypeForId } from "frontend/canvas-designer-new/elements/canvas-elements-utils";
import closestT from "frontend/geometry/closestT";
import { IRect, Point } from "./math-utils";
import consts from "shared/consts";
import { getOpacity } from "./color-utils";

/*
TODO:

a lot of the calculations about connectors snapping to shapes can be optimized.
- we can cache a lot of things
- we calculate a lot of thing we won't need. I think we can find the closest shape and then calculate only the things we need for that shape.
- calculating distance to shape's outline can be done with SDF (signed distance functions). much faster !!
- all other calculations should be lazy and memoized
*/


function getZIndex(node: Konva.Node) {
  return node.attrs.element.zIndexLastChangeTime ?? node.attrs.element.lastModifiedTimestamp;
}

function lineWidth(node: any) {
  // if I move to clipped shaped, remember to change this
  return node.attrs.element.strokeWidth ? parseStrokeWidth(node.attrs.element.strokeWidth) : defaultShapeStrokeWidth;
}

function getSize(rect: IRect) {
  return rect.width * rect.height;
}

function isFilled(node: Konva.Node) {
  let color = node.attrs.type == consts.CANVAS_ELEMENTS.DRAWING ? node.attrs.element.stroke : node.attrs.element.fill;
  const transparency = color ? getOpacity(color) : 1;
  return transparency > 0.001; // 0.001 is arbitrary; I just need a small number
}

export function isInside(canvasPoint: Point, node: Konva.Node) {
  const type = getElementTypeForId(node.attrs.id);
  let npoint = canvasPointToNormalizedPoint(
    canvasPoint,
    type,
    node.attrs.element
  );
  return isNormalizedPointInsideElement(
    npoint,
    type,
    node.attrs.element
  );
}

export class AttachmentData {
  readonly zindex: number;
  readonly strokeWidth: number;
  private _anchors: undefined | { top: { x: number; y: number; } & { rotation: Degrees; } & { onBoundingBox: boolean; }; right: { x: number; y: number; } & { rotation: Degrees; } & { onBoundingBox: boolean; }; buttom: { x: number; y: number; } & { rotation: Degrees; } & { onBoundingBox: boolean; }; left: { x: number; y: number; } & { rotation: Degrees; } & { onBoundingBox: boolean; }; };
  private _fn: ReturnType<typeof closestT> | undefined;
  private _rect: undefined | { x: number; y: number; width: number; height: number; };

  constructor(public readonly node: Konva.Node) {
    this.zindex = getZIndex(node);
    this.strokeWidth = lineWidth(node);
  }

  get anchors() {
    return this._anchors ??= getAnchorPointsFromNode(this.node);
  }

  get rect() {
    return this._rect ??= this.node.getClientRect({ skipShadow: true, relativeTo: this.node.getStage() as any });
  }

  public closestAnchor = memoize1((point: Point) => {
    const anchors = this.anchors;
    const [distSqr, side] = minItemBy((anchor) => (anchor.x - point.x) ** 2 + (anchor.y - point.y) ** 2, anchors);
    return { side, position: anchors[side as keyof typeof anchors], dist: Math.sqrt(distSqr) };
  });

  // TODO: attachmentdata calculates the closest outline point to the mouse, but I can calculate distance to outline
  // which is potentially quicker (no need to calculate the closest point) and only calculate the closest point for the
  // shape I'm closest to.
  public closestOutlinePoint = memoize1((point: Point) => {
    this._fn ??= closestT(this.node.attrs.type, this.node.attrs.element)
    return this._fn(point);
  });
}

// TODO: move to fn-utils
function memoize1<T, U>(fn: (arg: T) => U) {
  let lastArg: T, lastVal: U;
  return (arg: T) => {
    if (lastArg == arg) return lastVal;
    return lastArg = arg, lastVal = fn(arg);
  }
}

//-----------------------------------------------------------------------------

export abstract class SnapTarget { }

export class SnapToAnchor extends SnapTarget {
  constructor(
    readonly node: Konva.Node,
    readonly rect: IRect,
    readonly distance: number,
    readonly side: keyof StandardAnchorPoints,
    readonly position: Point,
    readonly rotation: number,
    readonly anchors: StandardAnchorPoints) {
    super();
  }
}

export class SnapToOutline extends SnapTarget {
  constructor(
    readonly node: Konva.Node,
    readonly distance: number,
    readonly rotation: number,
    readonly t: number,
    readonly xy: Point
  ) {
    super();
  }
}

export class SnapJustShowAnchors extends SnapTarget {
  constructor(
    readonly node: Konva.Node,
    readonly anchors: StandardAnchorPoints
  ) {
    super();
  }
};

/**
 *
 * @param mousePosInCanvasElement mouse pos in the canvas html element
 * @param mousePosStage mouse pos in stage
 * @param stage the konva stage object
 * @param elementsLayer the layer where connectable elements are
 * @returns
 */
export function checkSnapping(mousePosInCanvasElement: Point, mousePosStage: Point, stage: Konva.Stage, elementsLayer: Konva.Layer,  cache?: WeakMap<any, any>): SnapTarget | null {
  const margin = DistanceToShowAnchors_px / stage.scaleX(); // 20 canvas units (converted to viewport units)
  const triggerArea = {
    x: mousePosInCanvasElement.x - margin,
    y: mousePosInCanvasElement.y - margin,
    width: margin * 2,
    height: margin * 2,
  };
  // find all shapes close to the mouse
  // Note: this can probably be cached. The visible shapes can change if viewport changes (which we don't support while dragging now)
  // or another user changes the canvas. I would need some global cache that is invalidated on viewport change or canvas change.
  let candidates = elementsLayer.find(
    (node: any) => node.attrs.isConnectable && Konva.Util.haveIntersection(node.getClientRect(), triggerArea)
  );
  if (candidates.length == 0) {
    return null;
  }
  return analyzeCandidates(mousePosStage, candidates, stage.scaleX(), cache);
}

// Our connector snapping logic:
// if we're over a shape, highlight the top,bottom,left,right connection points.
// if we're close to them, snap to them
// if we're not close enough, but we are close to the outline of the shape, snap to it.
// if we're over several shapes, find the most suitable one:
// - take highest non-hollow shape (highest z-index)
// - override that if we have hollow shape, higher z-index, and it's inside the other shape.
// - if we have only hollow shapes, take the smallest one containing the mouse pointer (ignore z-index completely)
function analyzeCandidates(mousePosition: Point, candidates: Konva.Collection<Konva.Node>, stageScale: number, cache?: WeakMap<any, any>): SnapTarget | null {

  const N = candidates.length;
  let data = new Array<AttachmentData>(N);
  for (let i = 0; i < N; i++) {
    if (cache?.has(candidates[i])) {
      data[i] = cache.get(candidates[i]);
    }
    else {
      data[i] = new AttachmentData(candidates[i]);
      cache?.set(candidates[i], data[i])
    }
  }

  // find the closest shape socket
  let [closestNode] = minIndexBy((x) => x.closestAnchor(mousePosition).dist, data);

  // TODO: is closestNode.closestAnchor.dist measured in screen pixels or canvas pixels ?
  let closestAnchor = closestNode.closestAnchor(mousePosition);
  if (closestAnchor.dist < DistanceToShowAnchors_px) {
    return new SnapToAnchor(closestNode.node, closestNode.rect, closestAnchor.dist,
      closestAnchor.side as keyof StandardAnchorPoints, closestAnchor.position,
      closestAnchor.position.rotation,
      closestNode.anchors);
  }

  let [closestOutlinePoint, indexClosestOutlinePoint] = minIndexBy(
    (x) => x.closestOutlinePoint(mousePosition).distance,
    data
  );

  if (
    closestOutlinePoint?.closestOutlinePoint(mousePosition).distance <
    data[indexClosestOutlinePoint].strokeWidth * 2 + 1
  ) {
    const node = data[indexClosestOutlinePoint].node;
    const p = closestOutlinePoint.closestOutlinePoint(mousePosition)
    return new SnapToOutline(node, p.distance, p.normalDirectionDegrees, p.t, p.xy);
  }

  // among all the shapes I'm inside, find the non-hollow with highest z-index, and if no such shape exists,
  // find the hollow one with largest insideDistance (remember it's negative, meaning I'm looking for the closest outline)
  // TODO ofirc: finish this part
  let bestInside = null;
  let bestOutside = null;
  for (const shape of data) {
    const isMouseInside = isInside(mousePosition, shape.node);
    if (isMouseInside) {
      if (bestInside == null) {
        bestInside = shape;
      } else {
        if (isFilled(shape.node)) {
          if (shape.zindex > bestInside.zindex) {
            bestInside = shape;
          }
        } else {
          if (shape.zindex > bestInside.zindex && getSize(shape.rect) < getSize(bestInside.rect)) {
            bestInside = shape;
          }
        }
      }
    } else {
      const closeAnchor = shape.closestAnchor(mousePosition)
      if (closeAnchor.dist < DistanceToSnapToAnchorFromOutside_px / stageScale) {
        if (bestOutside == null)
          bestOutside = shape;
        else if (closeAnchor.dist < bestOutside.closestAnchor(mousePosition).dist)
          bestOutside = shape;
      }
    }
  }
  if (bestInside) {
    return new SnapJustShowAnchors(bestInside.node, bestInside.anchors);
  } else if (bestOutside) {
    return new SnapJustShowAnchors(bestOutside.node, bestOutside.anchors);
  }
  return null;
}