import React from "react";
import { Html } from "react-konva-utils";
import { Floater } from "frontend/ui-components/floaters/floater";
import BoundingBox from "frontend/geometry/bounding-box";
import { ColorPicker } from "frontend/canvas-designer-new/elements-toolbar/widgets/colorPicker";
import type { IGanttSplitCellController } from "elements/gantt/controllers/split-cell-controller";
import styles from "./split-cell-toolbar.module.css";
import { TrashIcon } from "frontend/icons/trash-icon";
import { TasksColors } from "elements/gantt/constants";
import cn from "classnames";
import { GanttAddGroupIcon } from "elements/gantt/icons/gantt-add-group-icon";
import { GanttAddSubGroupIcon } from "elements/gantt/icons/gantt-add-sub-group-icon";
import { Tooltip } from "frontend/ui-components/floaters/floaters";

type SplitCellToolbarProps = {
  cellBbox: BoundingBox;
  controller?: IGanttSplitCellController;
};

const Seperator = () => <div className={styles.seperator} />;

export const SplitCellToolbar = ({ cellBbox, controller }: SplitCellToolbarProps) => {
  return (
    <Html transform={false}>
      <Floater relativeTo={cellBbox} arrowSize={0} margin={10} boundary="no-controls-area" className={styles.wrapper}>
        <div className={styles.toolbarActions}>
          <Tooltip label={"Add Row"} side={"top"} tooltipClassName={styles.tooltip}>
            <div className={cn(styles.remove, styles.action)} onClick={() => controller?.addRow()}>
              <GanttAddGroupIcon />
            </div>
          </Tooltip>

          <Seperator />

          <Tooltip label={"Add Column"} side={"top"} tooltipClassName={styles.tooltip}>
            <div className={cn(styles.remove, styles.action)} onClick={() => controller?.addColumn()}>
              <GanttAddSubGroupIcon />
            </div>
          </Tooltip>

          <Seperator />

          <ColorPicker
            colorPalette={TasksColors}
            value={controller?.getColor()}
            onChange={(color) => controller?.changeColor(color.slice(0, 7))}
            isSplitCell={true}
          />

          {controller?.canDelete() && (
            <>
              <Seperator />
              <div className={cn(styles.remove, styles.action)} onClick={() => controller.deleteRow()}>
                <TrashIcon size={20} color="#FEFEFE" />
              </div>
            </>
          )}
        </div>
      </Floater>
    </Html>
  );
};
