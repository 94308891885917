import React, { useState } from "react";
import { DatePickerRange } from "frontend/ui-components/date-picker-range/date-picker-range";
import cn from "classnames";
import { format } from "date-fns";
import { CloseIcon } from "frontend/icons/close";
import { Tooltip } from "frontend/ui-components/floaters/floaters";
import styles from "./dates-filter.module.css";

const DATE_FORMAT_PATTERN = "MMM dd, uuuu";

const CustomDateRow = ({
  title,
  name,
  value,
  onClick,
  onReset,
  isSelected,
  theme = "light",
}: {
  name: string;
  value?: Date;
  title: string;
  isSelected: boolean;
  onClick?: (name: string) => void;
  onReset?: () => void;
  theme?: "light" | "dark";
}) => {
  return (
    <div className={styles.customDateRow} data-theme={theme}>
      {title}{" "}
      <div
        className={cn(styles.customDateValue, {
          [styles.customDateValueSelected]: isSelected,
        })}
        onClick={(e) => {
          onClick?.(name);
          e.stopPropagation();
        }}
      >
        {(value && format(value, DATE_FORMAT_PATTERN)) ?? "Pick a date"}
        {value && (
          <Tooltip label={"Clear Value"} side={"bottom"} tooltipClassName={styles.tooltip}>
            <button
              className={styles.clearIcon}
              onClick={() => {
                onReset?.();
              }}
            >
              <CloseIcon color={isSelected ? "#848199" : "#C2C3C9"} />
            </button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export const DatePickerRangeInput = ({
  fromDate,
  toDate,
  onSelected,
  showActions,
  theme,
}: {
  onSelected: (start?: Date, end?: Date) => void;
  fromDate?: Date;
  toDate?: Date;

  theme?: "light" | "dark";
  showActions?: "clear" | "apply" | "both" | "none";
}) => {
  const [start, setStart] = useState<Date | undefined>(fromDate);
  const [end, setEnd] = useState<Date | undefined>(toDate);
  const [selectedDate, setSelectedDate] = useState("start");

  return (
    <div className={styles.customDateWrapper}>
      <CustomDateRow
        isSelected={selectedDate === "start"}
        name="start"
        title="Start"
        value={start}
        onClick={(e) => {
          setSelectedDate(e);
        }}
        onReset={() => {
          setStart(undefined);
        }}
        theme={theme}
      />
      <CustomDateRow
        isSelected={selectedDate === "end"}
        name="end"
        title="End"
        value={end}
        onClick={(e) => {
          setSelectedDate(e);
        }}
        onReset={() => {
          setEnd(undefined);
        }}
        theme={theme}
      />
      <DatePickerRange
        fromDate={start}
        toDate={end}
        enableRangeSelection
        onSelected={(from, to) => {
          setStart(from);
          if (from && selectedDate === "start") {
            setSelectedDate("end");
          }
          if (from && selectedDate === "start" && end) {
            return;
          }
          setEnd(to);
        }}
        showActions="both"
        onApply={(from, to) => {
          onSelected(from, to);
        }}
        theme={theme}
      />
    </div>
  );
};
