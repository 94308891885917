import React from "react";
import { Html } from "react-konva-utils";
import { Floater } from "frontend/ui-components/floaters/floater";
import BoundingBox from "frontend/geometry/bounding-box";
import { ColorPicker } from "frontend/canvas-designer-new/elements-toolbar/widgets/colorPicker";
import type { IGanttSplitCellController } from "elements/gantt/controllers/split-cell-controller";
import styles from "./gantt-connector-toolbar.module.css";
import { TrashIcon } from "frontend/icons/trash-icon";
import constants, { TasksColors } from "elements/gantt/constants";

type SplitCellToolbarProps = {
  cellBbox: BoundingBox;
  removeConnector: () => void;
};

export const GanttConnectorToolbar = ({ cellBbox, removeConnector }: SplitCellToolbarProps) => {
  return (
    <Html transform={false}>
      <Floater relativeTo={cellBbox} arrowSize={0} margin={10} boundary="no-controls-area" className={styles.wrapper}>
        <div className={styles.toolbarActions}>
          <button className={styles.remove} onClick={() => removeConnector()}>
            <TrashIcon size={20} color="#FEFEFE" />
          </button>
        </div>
      </Floater>
    </Html>
  );
};
