import consts, { TypeCanvasElement } from "shared/consts";

const elementsValues = Object.values(consts.CANVAS_ELEMENTS).reduce((acc: Record<string, false>, element: string) => {
  acc[`canvas-element-${element}`] = false;
  return acc;
}, {}) as Record<`canvas-element-${TypeCanvasElement}`, false>;

export const DefaultFeatureFlagsValues = {
  "test-flag": false,
  "test-variant": { temp: "temp" },
  "search-board-feature": false,
  ...elementsValues,
  "save-as-template-feature": false,
  "allow-table-creation": false,
  "per-seat-license": null,
};

export type FeatureFlagsKeys = keyof typeof DefaultFeatureFlagsValues | `canvas-element-${TypeCanvasElement}`;

export type FeatureFlagValue<K extends FeatureFlagsKeys> = typeof DefaultFeatureFlagsValues[K];
