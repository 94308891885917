import { ReadonlyJSONObject, ReadTransaction, WriteTransaction } from "@workcanvas/reflect";
import { canvasElementPrefix, getUnixTimestampUTC, validateSchema, authenticatedPut, authenticatedDelete, createElementId } from "../util/utils";
import { Point } from "./schemas/canvas-element";
import { TaskCard, taskCardSchema } from "./schemas/task-card";
import consts from "shared/consts";

export const taskCardPrefix = `${canvasElementPrefix}taskCard-`;

export async function getTaskCard(tx: ReadTransaction, id: string): Promise<TaskCard | null> {
  const jv = await tx.get(key(id));
  if (!jv) {
    console.log(`Specified shape ${id} not found.`);
    return null;
  }
  return validateSchema(taskCardSchema, jv);
}

export function putTaskCard(tx: WriteTransaction, { id, card }: { id: string; card: TaskCard }): Promise<void> {
  const next = { ...card as ReadonlyJSONObject, lastModifiedTimestamp: getUnixTimestampUTC() };
  return authenticatedPut(tx, key(id), next);
}

export async function deleteTaskCard(tx: WriteTransaction, id: string): Promise<void> {
  await authenticatedDelete(tx, key(id));
}

function key(id: string): string {
  return `${taskCardPrefix}${id}`;
}

export function placeTaskCard(point: Point) {
  return {
    id: createElementId(),
    card: {
      // ---- general canvas element props ----
      type: consts.CANVAS_ELEMENTS.TASK_CARD,
      x: point.x,
      y: point.y,
      scaleX: 1,
      scaleY: 1,
      hidden: false,
      attachedConnectors: {},
      zIndexLastChangeTime: getUnixTimestampUTC(),
      // ---- task card props ----
      title: "",
      description: "",
      creationTime: Date.now(),
      color: consts.COLOR_PALETTE[4],
      dueDate: 0,
      // externalId
      // assignees
      // tags
    } as TaskCard,
  };
}

export function getTaskFieldValue(card: TaskCard, fieldId: string): any | undefined {
  return card.fieldValues?.[fieldId];
}