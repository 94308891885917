import { StickyNoteMarginX, StickyNoteMarginY } from "frontend/canvas-designer-new/elements/sticky-note-element";
import Konva from "konva";
import consts, { TypeCanvasElement, TypeShape } from "shared/consts";
import { Connector } from "shared/datamodel/schemas/connector";
import { Drawing } from "shared/datamodel/schemas/drawing";
import { TextBlock } from "shared/datamodel/schemas/textBlock";
import { IRect, Point, stageToViewport } from "./math-utils";
import BoundingBox from "../geometry/bounding-box";
import { File as FileSchema } from "shared/datamodel/schemas/file";
import * as TextUtils from "../canvas-designer-new/text-element/text-utils";
import { LineCap } from "konva/types/Shape";
import Transform, { CanvasElementBox, Degrees, toRadians } from "./transform";
import ShapesData from "frontend/data/shapes/shapes-visuals";
import { TypeTableElement } from "shared/datamodel/schemas/table";
import { getElementGraphicsProvider } from "elements/index";

type GetClientRectOptions = Parameters<Konva.Node["getClientRect"]>[0];

function measureNodeInternal(node: Konva.Node, options: GetClientRectOptions): IRect {
  const type = node.attrs.element?.type ?? node.id().split("-", 1)[0];
  const children = node.getChildren();
  if (type == consts.CANVAS_ELEMENTS.STICKY_NOTE && children.length >= 2) {
    return children[1].getClientRect(options);
  } else if (type == consts.CANVAS_ELEMENTS.TEXT_BLOCK && children.length >= 1) {
    let rect = children[0].getClientRect(options);
    // text-elements can be in edit mode, and in that case they return width=0, height=0
    if (rect.width == 0) {
      rect.width = (node.attrs.element as any).width;
    }
    if (rect.height == 0) {
      rect.height = (node.attrs.element as any).height;
    }
    return rect;
  }

  // connector endpoints are only known after render, if it's connected to something.
  // the connector saves its points in local coordinates, and we need to transform them to stage coordinates.
  if (type == consts.CANVAS_ELEMENTS.CONNECTOR) {
    let p1 = node.attrs.startpoint,
      p2 = node.attrs.endpoint;
    // assuming by default the caller wants relativeTo: layer
    const tr = node.getTransform();
    (p1 = tr.point(p1)), (p2 = tr.point(p2));
    // but if he doesn't we assume relativeTo: viewport.
    // we don't support here relative to another node
    if (!options?.relativeTo || options?.relativeTo != (node.getLayer() as any)) {
      const pos = node.getStage()!.getPosition();
      let tr = { ...pos, scale: node.getStage()!.scaleX() };
      p1 = stageToViewport(tr, p1);
      p2 = stageToViewport(tr, p2);
    }
    let rect = {
      x: Math.min(p1.x, p2.x),
      y: Math.min(p1.y, p2.y),
      width: Math.abs(p1.x - p2.x),
      height: Math.abs(p1.y - p2.y),
    };
    return rect;
    // I could have just done node.getClientRect(options) and it would have worked,
    // except when copy-pasting a connected connector.
  }

  return node.getClientRect(options);
}

export function isRectLike(shape: TypeShape) {
  return shape == consts.SHAPES.RECT || shape == consts.SHAPES.RECT_ROUNDED;
}

export function getTransformParams(elementType: TypeCanvasElement, element: any): CanvasElementBox {
  const provider = getElementGraphicsProvider(elementType);
  if (provider) {
    return provider.getTransformParams(element);
  }
  switch (elementType) {
    case consts.CANVAS_ELEMENTS.SHAPE: {
      if (element.type == consts.CANVAS_ELEMENTS.SHAPE && element.subtype) {
        const { x, y, scaleX = 1, scaleY = 1, rotate = 0 } = element;
        let subtype = element.subtype;
        let data = subtype ? (ShapesData as any)[subtype] : undefined;
        const viewbox = data?.viewbox;
        const [, , width, height] = viewbox;
        return { x, y, width: width * scaleX, height: height * scaleY, rotate: rotate as Degrees };
      }

      let w, h;
      if (isRectLike(element.type)) {
        (w = element.width!), (h = element.height!);
      } else {
        w = h = element.radius!;
      }
      const { x, y, scaleX = 1, scaleY = 1, rotate = 0 } = element;
      return { x, y, width: w * scaleX, height: h * scaleY, rotate: rotate as Degrees };
    }

    case consts.CANVAS_ELEMENTS.STICKY_NOTE: {
      const { x, y, scaleX = 1, scaleY = 1, width, height } = element;
      const sx = (width + StickyNoteMarginX) * scaleX;
      const sy = (height + StickyNoteMarginY) * scaleY;
      return { x, y, width: sx, height: sy, rotate: 0 as Degrees };
    }

    case consts.CANVAS_ELEMENTS.DRAWING: {
      let { x, y, scaleX: width = 1, scaleY: height = 1, rotate = 0 as Degrees } = element;
      return { x, y, width, height, rotate };
    }

    case consts.CANVAS_ELEMENTS.CONNECTOR:
      return {
        x: element.x,
        y: element.y,
        width: element.scaleX ?? 1,
        height: element.scaleY ?? 1,
        rotate: element.rotate as Degrees,
      };

    case consts.CANVAS_ELEMENTS.TEXT_BLOCK: {
      const text = element as TextBlock;
      let { scaleX = 1, scaleY = 1, width, height } = text;

      const corrected = TextUtils.correctWidthForAspectRatio(width, scaleX, scaleY);
      const fontSizePixels = TextUtils.getFontSize(element);
      width = Math.max(corrected.width, fontSizePixels);
      if (typeof height == "undefined") {
        const config = TextUtils.calcKonvaTextConfig(element, 1);
        const t = new Konva.Text(config);
        height = t.height();
      }
      width *= scaleY;
      height *= scaleY;
      return { x: element.x, y: element.y, width, height, rotate: element.rotate as Degrees };
    }

    case consts.CANVAS_ELEMENTS.CARD_STACK:
    case consts.CANVAS_ELEMENTS.LIVE_INTEGRATION:
    case consts.CANVAS_ELEMENTS.FRAME:
      let { x, y, width, height, scaleX = 1, scaleY = 1 } = element;
      return { x, y, width: width * scaleX, height: height * scaleY, rotate: 0 as Degrees };

    case consts.CANVAS_ELEMENTS.COMMENT:
      return { x: element.x, y: element.y, width: 0, height: 0, rotate: 0 as Degrees };

    case consts.CANVAS_ELEMENTS.FILE: {
      const file = element as FileSchema;
      if (file.type == "image") {
        let width = file.width * file.scaleX!;
        let height = file.height * file.scaleY!;
        if (isNaN(width)) width = 0;
        if (isNaN(height)) height = 0;
        return { x: file.x, y: file.y, width, height, rotate: file.rotate as Degrees };
      } else {
        console.error("don't know how to calc size of video element");
        return { x: file.x, y: file.y, width: 0, height: 0, rotate: 0 as Degrees };
      }
    }

    case consts.CANVAS_ELEMENTS.TASK_CARD:
      return {
        x: element.x,
        y: element.y,
        width: consts.DEFAULTS.CARD_WIDTH,
        height: element.height ?? 158,
        rotate: 0 as Degrees,
      };

    case consts.CANVAS_ELEMENTS.MINDMAP:
      if (!element.absolutePosition) {
        return { x: element.x, y: element.y, width: 0, height: 0, rotate: 0 as Degrees };
      }
      return {
        x: element.absolutePosition.x,
        y: element.absolutePosition.y,
        width: element.width,
        height: element.height,
      };
    case consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART:
      if (!element.absolutePosition) {
        return { x: element.x, y: element.y, width: 0, height: 0, rotate: 0 as Degrees };
      }
      return {
        x: element.absolutePosition.x,
        y: element.absolutePosition.y,
        width: element.width,
        height: element.height,
      };
    case consts.CANVAS_ELEMENTS.INTEGRATION:
      return {
        x: element.x,
        y: element.y,
        width: consts.DEFAULTS.MONDAY_CARD_WIDTH * (element.scaleX ?? 1),
        height: consts.DEFAULTS.MONDAY_CARD_HEIGHT * (element.scaleY ?? 1),
        rotate: 0 as Degrees,
      };
    case consts.CANVAS_ELEMENTS.ORG_CHART:
      //ofirc TODO
      return { x: element.x, y: element.y, width: 0, height: 0, rotate: 0 as Degrees };
    case consts.CANVAS_ELEMENTS.ORG_CHART_NODE:
      //ofirc TODO
      return { x: element.x, y: element.y, width: 0, height: 0, rotate: 0 as Degrees };
    case consts.CANVAS_ELEMENTS.TIMELINE:
      return { x: element.x, y: element.y, width: 0, height: 0, rotate: 0 as Degrees };

    case consts.CANVAS_ELEMENTS.TABLE: {
      const { x, y, scaleX = 1, scaleY = 1, cols, rows } = element as TypeTableElement;
      const width = cols.reduce((acc, col) => acc + col.size, 0) * scaleX;
      const height = rows.reduce((acc, row) => acc + row.size, 0) * scaleY;
      return { x, y, width, height, rotate: 0 as Degrees };
    }
    case consts.CANVAS_ELEMENTS.TABLE_CELL: {
      return { x: 0, y: 0, width: 0, height: 0 }; //todo: ofirc check if this is correct
    }
    default:
      // if got here it's a bug
      console.warn("unknown element type", elementType);
      return { x: element.x, y: element.y, width: 0, height: 0, rotate: 0 as Degrees };
  }
}

export function getVerticesNormalized(elementType: TypeCanvasElement, element: any) {
  const provider = getElementGraphicsProvider(elementType);
  if (provider) {
    return provider.getVerticesNormalized(element);
  }
  switch (elementType) {
    case consts.CANVAS_ELEMENTS.SHAPE: {
      if (element.type == consts.CANVAS_ELEMENTS.SHAPE && element.subtype) {
        return [-0.5, -0.5, 0.5, -0.5, 0.5, 0.5, -0.5, 0.5];
      }
      switch (element.type) {
        case consts.SHAPES.RECT:
        case consts.SHAPES.RECT_ROUNDED:
          return [0, 0, 1, 0, 1, 1, 0, 1];
        case consts.SHAPES.DIAMOND:
          return [0, 1, 0, -1, 1, 0, -1, 0];
        case consts.SHAPES.CIRCLE:
          return [0, 1, 0, -1, 1, 0, -1, 0];
        case consts.SHAPES.TRIANGLE:
          return [0, -1, 0.866, 0.5, -0.866, 0.5];
        case consts.SHAPES.HEXAGON:
          return [0, 1, 0, -1, 0.866, 0.5, 0.866, -0.5, -0.866, 0.5, -0.866, -0.5];
      }
    }
    case consts.CANVAS_ELEMENTS.CONNECTOR: {
      const connector = element as Connector;
      if (!connector.points || connector.points.length < 2) {
        return [0, 0, 1, 0, 1, 1, 0, 1];
      }
      //TODO: take inner points into account, and curvature
      // also, this is wrong in case the connector is attached to other elements
      return [connector.points[0].x, connector.points[0].y, connector.points[1].x, connector.points[1].y];
    }
    case consts.CANVAS_ELEMENTS.DRAWING: {
      const drawing = element as Drawing;
      return drawing.points;
    }
    case consts.CANVAS_ELEMENTS.TEXT_BLOCK:
    case consts.CANVAS_ELEMENTS.STICKY_NOTE:
    case consts.CANVAS_ELEMENTS.FILE:
    case consts.CANVAS_ELEMENTS.TASK_CARD:
    case consts.CANVAS_ELEMENTS.MINDMAP:
    case consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART:
    case consts.CANVAS_ELEMENTS.FRAME:
    case consts.CANVAS_ELEMENTS.COMMENT:
    case consts.CANVAS_ELEMENTS.INTEGRATION:
    case consts.CANVAS_ELEMENTS.ORG_CHART:
    case consts.CANVAS_ELEMENTS.ORG_CHART_NODE:
    case consts.CANVAS_ELEMENTS.CARD_STACK:
    case consts.CANVAS_ELEMENTS.LIVE_INTEGRATION:
    case consts.CANVAS_ELEMENTS.TIMELINE:
    case consts.CANVAS_ELEMENTS.TABLE:
    case consts.CANVAS_ELEMENTS.TABLE_CELL:
      return [0, 0, 1, 0, 1, 1, 0, 1];
    default:
      // if got here it's a bug
      console.warn("unknown element type", elementType);
      return [0, 0, 1, 0, 1, 1, 0, 1];
  }
}

export function normalizedPointToCanvasPoint(p: Point, elementType: TypeCanvasElement, element: any) {
  const tr = getTransformParams(elementType, element);
  return Transform.Point(tr, p);
}

export function canvasPointToNormalizedPoint(p: Point, elementType: TypeCanvasElement, element: any) {
  const tr = getTransformParams(elementType, element);
  return Transform.InvPoint(tr, p);
}

function isNormalizedPointInsidePolygon(p: Point, shapeType: TypeShape) {
  switch (shapeType) {
    case consts.SHAPES.RECT:
    case consts.SHAPES.RECT_ROUNDED:
      return p.x >= 0 && p.x <= 1 && p.y >= 0 && p.y <= 1;
    case consts.SHAPES.CIRCLE:
      return p.x * p.x + p.y * p.y <= 1;
    case consts.SHAPES.TRIANGLE:
      return p.y >= 1.732 * Math.abs(p.x) - 1;
    case consts.SHAPES.DIAMOND:
      return Math.abs(p.x) + Math.abs(p.y) <= 1;
    case consts.SHAPES.HEXAGON: {
      const x = Math.abs(p.x),
        y = Math.abs(p.y);
      if (x > 0.866 || y > 1) return false;
      return 0.866 - 0.5 * x - 0.866 * y >= 0;
    }
    default:
      return p.x >= 0 && p.x <= 1 && p.y >= 0 && p.y <= 1;
      break;
  }
}

export function isNormalizedPointInsideElement(p: Point, elementType: TypeCanvasElement, element: any) {
  const provider = getElementGraphicsProvider(elementType);
  if (provider) {
    return provider.isNormalizedPointInsideElement(p);
  }
  switch (elementType) {
    case consts.CANVAS_ELEMENTS.SHAPE:
      return isNormalizedPointInsidePolygon(p, element.type);
    case consts.CANVAS_ELEMENTS.TASK_CARD:
    case consts.CANVAS_ELEMENTS.MINDMAP:
    case consts.CANVAS_ELEMENTS.MINDMAP_ORG_CHART:
    case consts.CANVAS_ELEMENTS.FILE:
    case consts.CANVAS_ELEMENTS.FRAME:
    case consts.CANVAS_ELEMENTS.TEXT_BLOCK:
    case consts.CANVAS_ELEMENTS.STICKY_NOTE:
    case consts.CANVAS_ELEMENTS.INTEGRATION:
    case consts.CANVAS_ELEMENTS.CARD_STACK:
    case consts.CANVAS_ELEMENTS.LIVE_INTEGRATION:
    case consts.CANVAS_ELEMENTS.TIMELINE:
    case consts.CANVAS_ELEMENTS.TABLE:
      return p.x >= 0 && p.x <= 1 && p.y >= 0 && p.y <= 1;
    case consts.CANVAS_ELEMENTS.COMMENT:
      return p.x == 0 && p.y == 0;
    case consts.CANVAS_ELEMENTS.DRAWING:
      return true;
    case consts.CANVAS_ELEMENTS.CONNECTOR:
      return false; //TODO:
    case consts.CANVAS_ELEMENTS.ORG_CHART_NODE:
      return false; //ofirc TODO
    case consts.CANVAS_ELEMENTS.TABLE_CELL:
      return false;
    case consts.CANVAS_ELEMENTS.ORG_CHART:
      return false;
    default:
      // if got here it's a bug
      console.warn("unknown element type", elementType);
      return false;
  }
}

export function getBoundingBox(elementType: string, element: any) {
  // ellipse is a special case among the shapes.
  if (elementType == consts.CANVAS_ELEMENTS.SHAPE && element.type == consts.SHAPES.CIRCLE) {
    const tr = getTransformParams(elementType as TypeCanvasElement, element);
    const angle = toRadians((tr.rotate ?? 0) as Degrees);
    const r1 = tr.width,
      r2 = tr.height;
    const ux = r1 * Math.cos(angle);
    const uy = r1 * Math.sin(angle);
    const vx = r2 * Math.cos(angle + Math.PI / 2);
    const vy = r2 * Math.sin(angle + Math.PI / 2);
    const bbox_halfwidth = Math.sqrt(ux * ux + vx * vx);
    const bbox_halfheight = Math.sqrt(uy * uy + vy * vy);
    const top = tr.y - bbox_halfheight;
    const left = tr.x - bbox_halfwidth;
    return { x: left, y: top, width: bbox_halfwidth * 2, height: bbox_halfheight * 2 };
  }
  // connectors are also a special case, since if it's connected to other elements,
  // its endpoints are calculated from those elements in runtime.
  // in this case we return either the single free-standind endpoint, or null.
  if (elementType == consts.CANVAS_ELEMENTS.CONNECTOR) {
    const connector = element as Connector;
    const tr = getTransformParams(elementType as TypeCanvasElement, element);
    const transform = new Transform(tr.x, tr.y, tr.width, tr.height, tr.rotate);

    if (!connector.points || connector.points.length < 2) {
      return null;
    }
    
    let p1 = connector.connectedShapes?.[0]?.id ? null : { ...connector.points[0] };
    let p2 = connector.connectedShapes?.[1]?.id ? null : { ...connector.points[1] };
    // now convert points to canvas coordinates from element local coordinates
    // TODO: take inner points and bezier curvature into account
    p1 && transform.transformPoint(p1, p1);
    p2 && transform.transformPoint(p2, p2);
    if (p1 && p2) {
      return {
        x: Math.min(p1.x, p2.x),
        y: Math.min(p1.y, p2.y),
        width: Math.abs(p1.x - p2.x),
        height: Math.abs(p1.y - p2.y),
      };
    } else if (p1 || p2) {
      return {
        x: (p1 ?? p2)!.x,
        y: (p1 ?? p2)!.y,
        width: 1,
        height: 1,
      };
    } else {
      return null;
    }
  }
  // otherwise we take the element's corner points and transform them to canvas coordinates
  // and find the bounding box of those.
  const tr = getTransformParams(elementType as TypeCanvasElement, element);
  const transform = new Transform(tr.x, tr.y, tr.width, tr.height, tr.rotate);
  let points = getVerticesNormalized(elementType as TypeCanvasElement, element);
  if(!points) {
    // can happen if we encountered unknown element type, for example if data in replicache is messed up
    // or we reverted production to earlier version but have new data
    return null;
  }
  let p = { x: 0, y: 0 };
  let bbox = new BoundingBox();
  for (let i = 0; i < points.length; i += 2) {
    p.x = points[i];
    p.y = points[i + 1];
    transform.transformPoint(p, p);
    bbox.expandPoint(p.x, p.y);
  }
  return bbox.asRect();
}

export function getZIndex(node?: Konva.Node) {
  if (!node) return null;
  return node.attrs.element.zIndexLastChangeTime ?? node.attrs.element.lastModifiedTimestamp;
}

// Return the bounding box of the node {x,y,width,height}, as computed by Konva
// Coordintes are in stage-space
// x,y are top left coordinates always
export function measureNode(node: Konva.Node, options?: GetClientRectOptions): IRect {
  options ??= {};
  options.relativeTo ??= node.getLayer() as any;
  options.skipShadow ??= true;
  const rect = measureNodeInternal(node, options);
  return rect;
}

export function measureNodes(nodes: Konva.Node[], options?: GetClientRectOptions): IRect[] {
  if (nodes.length == 0) return [];
  options ??= {};
  options.relativeTo ??= nodes[0].getLayer() as any;
  options.skipShadow ??= true;
  return nodes.map((node) => measureNodeInternal(node, options));
}

export function measureNodesScreenSpace(nodes: readonly Konva.Node[], options?: GetClientRectOptions) {
  if (nodes.length == 0) return [];
  if (options && options.relativeTo) {
    delete options.relativeTo;
  }
  return nodes.map((node) => measureNodeInternal(node, options));
}

export function getShapeRoot(node: Konva.Node | null): Konva.Node | null {
  while (node) {
    if (node.nodeType == "Group" && node.attrs.isCanvasElement) return node;
    node = node.getParent();
  }
  return null;
}

export function calcDashProperties(strokeWidth: number, dash?: number) {
  switch (dash) {
    case 1:
      return {
        dashEnabled: true,
        dash: [5 * strokeWidth, 5 * strokeWidth],
        lineCap: "butt" as LineCap,
      };
    case 2:
      return {
        dashEnabled: true,
        dash: [0, 2 * strokeWidth],
        lineCap: "round" as LineCap,
      };
    case 3:
      return {
        dashEnabled: true,
        dash: [10, 5, 2, 5].map((x) => x * strokeWidth),
        lineCap: "round" as LineCap,
      };
    case 0:
    default:
      return { dashEnabled: false, lineCap: "round" as LineCap };
  }
}

export function isOrgchartNode(node: Konva.Node) {
  return (
    node.attrs.type == consts.CANVAS_ELEMENTS.ORG_CHART &&
    node.attrs.name.startsWith(consts.CANVAS_ELEMENTS.ORG_CHART_NODE)
  );
}

export function isTableNode(node: Konva.Node) {
  return node.attrs.type == consts.CANVAS_ELEMENTS.TABLE;
}
