import ConnectorLine from "./connector-line";
import { computeConnectorDrawingData, ConnectorEndpoint } from "./connector-component-utils";
import React, { forwardRef, Ref } from "react";
import Konva from "konva";

const GhostConnector = forwardRef(
  (
    {
      id,
      p1,
      p2,
      lineType,
      element,
      curveStrength,
    }: {
      id: string;
      p1: ConnectorEndpoint;
      p2: ConnectorEndpoint;
      lineType: "line" | "curve" | "elbow";
      element: any;
      curveStrength?: number;
    },
    ref: Ref<Konva.Shape>
  ) => {
    const data = computeConnectorDrawingData(p1, p2, { lineType, ...element }, curveStrength);
    return <ConnectorLine id={id} p1={p1} p2={p2} element={element} data={data} ref={ref} />;
  }
);

export default GhostConnector;
