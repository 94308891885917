import { customAlphabet } from "nanoid";
import Konva from "konva";
import { GanttSplitCell } from "elements/gantt/layout-builder";
import Constants, { TaskColor, TasksColors } from "elements/gantt/constants";

export function createCellId() {
  const alphabet = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const nanoid = customAlphabet(alphabet, 10);
  return nanoid();
}

export function isTargetSplitCell(target: Konva.Node) {
  return findNodeStartsWithId(target, "cell-");
}

export function isTargetAnchor(target: Konva.Node) {
  return findNodeStartsWithId(target, "anchor-");
}

export function isTargetGridCell(target: Konva.Node) {
  return findNodeStartsWithId(target, "grid-");
}

export function isTargetTaskCell(target: Konva.Node) {
  return findNodeStartsWithId(target, "task-");
}

export function isTargetConnector(target: Konva.Node) {
  return findNodeStartsWithId(target, "gantt-connector-");
}

function findNodeStartsWithId(node: Konva.Node, id: string) {
  let parent = node;
  while (parent) {
    if (parent.attrs && parent.attrs.id === "gantt-parent-node") {
      return false;
    }
    if (parent.attrs && parent.attrs.id && parent.attrs.id.startsWith(id)) {
      return true;
    }
    parent = parent.getParent();
  }
  return false;
}

export enum CornerRadius {
  None = 0,
  TopLeft = 1 << 0,
  TopRight = 1 << 1,
  BottomRight = 1 << 2,
  BottomLeft = 1 << 3,
}

export function getCornerRadius(cornerRadius: CornerRadius, value: number): number[] {
  return [
    cornerRadius & CornerRadius.TopLeft ? value : 0,
    cornerRadius & CornerRadius.TopRight ? value : 0,
    cornerRadius & CornerRadius.BottomRight ? value : 0,
    cornerRadius & CornerRadius.BottomLeft ? value : 0,
  ];
}

export function getRelatedRows(
  data: (GanttSplitCell | { rowId: string; parentRowId?: string })[],
  rowId: string
): any[] {
  const newData = [...data];
  newData.reverse();

  const relevantIds = new Set();

  newData.forEach((item) => {
    if (item.rowId === rowId || relevantIds.has(item.parentRowId) || relevantIds.has(item.rowId)) {
      relevantIds.add(item.rowId);
      if (item.parentRowId) {
        relevantIds.add(item.parentRowId);
      }
    }
  });

  relevantIds.forEach((id) => {
    newData.forEach((item) => {
      if (item.rowId === id || item.parentRowId === id) {
        relevantIds.add(item.rowId);
      }
    });
  });

  return newData.filter((item) => relevantIds.has(item.rowId));
}

export function getRowColor(color: string): TaskColor {
  if (!color || !TasksColors.includes(color)) {
    return TasksColors[0] as TaskColor;
  }
  return color as TaskColor;
}